import React, { useState } from 'react'
import { Image } from 'src/components/ui/Image'
import { useProduct } from 'src/sdk/product/useProduct'
import { useCart } from 'src/sdk/cart'
import PlusRound from 'src/components/icons/PlusRound'
import PlusSquare from 'src/components/icons/PlusSquare'
import { Link } from 'gatsby'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import type { IOProduct } from 'src/components/sections/PlpSellerStore/types'
import { getMainImageFromProduct } from 'src/utils/OrderImagesByRegex'

import BuyTogetherTotal from '../BuyTogetherTotal/BuyTogetherTotal'
import BuyTogetherModal from '../BuyTogetherModal/BuyTogetherModal'
import './styles.scss'
import AddToCart from '../AddToCart/AddToCart'

interface Props {
  productCurrent: BrowserProductQueryQuery['product']
  productBuyTogether: IOProduct
}

const BuyTogetherItem = ({ productCurrent, productBuyTogether }: Props) => {
  const { items } = productBuyTogether
  const itemsInStock = items?.filter(
    (item) => item?.sellers?.[0]?.commertialOffer?.AvailableQuantity !== 0
  )

  const { data } = useProduct(itemsInStock?.[0]?.itemId ?? '')
  const product = data?.product
  const { id: cartId, items: itemsCart } = useCart()
  const currentHasVariant =
    (productCurrent?.isVariantOf?.hasVariant?.length ?? 0) > 1

  const buyTogetherHasVariant =
    (product?.isVariantOf?.hasVariant?.length ?? 0) > 1

  const [loadImage, setLoadImage] = useState(false)

  const products = product ? [productCurrent, product] : [productCurrent]
  const productCurrentImage = getMainImageFromProduct(productCurrent)

  const buyTogetherProductImage = getMainImageFromProduct(
    product as BrowserProductQueryQuery['product']
  )

  const imgOptions = {
    width: 950,
    height: 950,
    layout: 'constrained' as const,
    backgroundColor: '#f0f0f0',
    loading: 'lazy' as const,
    quality: 100,
    onLoad: () => {
      setLoadImage(true)
    },
  }

  const pricesProductCurrent = {
    lowPrice: productCurrent.offers?.lowPrice ?? 0,
    listPrice: productCurrent.offers?.offers?.[0]?.listPrice ?? 0,
  }

  const pricesProductBuyTogether = {
    lowPrice: product?.offers?.lowPrice ?? 0,
    listPrice: product?.offers?.offers?.[0]?.listPrice ?? 0,
  }

  const total = {
    lowPrice:
      Number(pricesProductCurrent.lowPrice) +
      Number(pricesProductBuyTogether.lowPrice),
    listPrice:
      Number(pricesProductCurrent.listPrice) +
      Number(pricesProductBuyTogether.listPrice),
  }

  const minValue = Math.min(total.listPrice, total.lowPrice)

  function generateModifiedSlug(slug: string) {
    if (slug === '' || !slug) {
      return ''
    }

    const idSKU = slug.split('-').pop()
    const slugModified = slug.replace(`-${idSKU}`, '')

    return `/${slugModified}/p`
  }

  return (
    <div className="buyTogether-item">
      <div className="buyTogether-item_images">
        <div className={loadImage ? 'backgroundColor' : ''}>
          <Link
            to={generateModifiedSlug(productCurrent?.slug)}
            className="backgroundColor-opacity"
          >
            <Image
              baseUrl={productCurrentImage ?? ''}
              alt={productCurrent?.image?.[0]?.alternateName ?? ''}
              {...imgOptions}
            />
          </Link>
        </div>
        <PlusSquare />

        <div className={loadImage ? 'backgroundColor second' : 'second'}>
          <Link
            to={generateModifiedSlug(product?.slug ?? '')}
            className="backgroundColor-opacity"
          >
            <Image
              baseUrl={buyTogetherProductImage ?? ''}
              alt={product?.image?.[0]?.alternateName ?? ''}
              {...imgOptions}
            />
          </Link>
        </div>
      </div>

      <h2 className="buyTogether-item_images-name">
        <span className="buyTogether-item_images-name-span firstProduct-title">
          {productCurrent?.name}
        </span>
        <PlusRound />
        <span className="buyTogether-item_images-name-span secondProduct-title">
          {product?.name}
        </span>
      </h2>

      <BuyTogetherTotal lowPrice={total.lowPrice} listPrice={total.listPrice} />

      {!currentHasVariant && !buyTogetherHasVariant ? (
        <AddToCart
          products={products}
          itemsCart={itemsCart}
          cartId={cartId}
          totalPrice={minValue}
        />
      ) : (
        <BuyTogetherModal
          products={products}
          buyTogetherHasVariant={buyTogetherHasVariant}
        />
      )}
    </div>
  )
}

export default BuyTogetherItem
