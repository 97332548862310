import React from 'react'

import { NotificationModal } from '../NotificationModal/NotificationModal'
import { fowardNavigation } from './AssemblePixelPopUpExit'

interface ExitModalProps {
  showExitModal: boolean
  setShowExitModal: React.Dispatch<React.SetStateAction<boolean>>
  getOutOfWindow: { href: string; innerText?: string }
  setGetOutOfWindow: React.Dispatch<
    React.SetStateAction<{
      href: string
      innerText?: string
    }>
  >
}

export const ExitModal = ({
  showExitModal,
  setShowExitModal,
  getOutOfWindow,
  setGetOutOfWindow,
}: ExitModalProps) => {
  return (
    <NotificationModal
      message="Atenção, você está indo para outra área do site. Se desejar mesmo sair, sua montagem será perdida."
      isOpen={showExitModal}
      primaryButtonText="Quero continuar a montar"
      primaryButtonCallback={() => setShowExitModal(false)}
      secondaryButtonText="Sim, desejo sair"
      secondaryButtonCallback={() =>
        fowardNavigation({
          getOutOfWindow,
          setIsModal: setShowExitModal,
        })
      }
      closeModal={() => {
        setShowExitModal(false)
        setGetOutOfWindow({ href: '' })
      }}
    />
  )
}
