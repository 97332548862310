import type { ReactNode } from 'react'
import React, { useMemo, useState, createContext, useContext } from 'react'

export interface IContext {
  detailsSpecifications: Specifications[]
  setDetailsSpecifications: React.Dispatch<any>
}

export interface Specifications {
  values?: Array<string | null> | null
  alt_image?: string | null
  alt_image_life?: string | null
  description?: string | null
  description_life?: string | null
  name?: string | null
  type?: string | null
  url_image?: string | null
  url_image_life?: string | null
  id?: string | null
}

const DetailsSpecificationsContext = createContext<IContext>({} as IContext)

export const DetailsSpecificationsProvider: React.FC<{
  children: ReactNode
}> = ({ children }) => {
  const [detailsSpecifications, setDetailsSpecifications] = useState(
    [] as Specifications[]
  )

  const value = useMemo(
    () => ({
      detailsSpecifications,
      setDetailsSpecifications,
    }),
    [detailsSpecifications]
  )

  return (
    <DetailsSpecificationsContext.Provider value={value}>
      {children}
    </DetailsSpecificationsContext.Provider>
  )
}

export const useDetailsSpecificationsContext = () => {
  return useContext(DetailsSpecificationsContext)
}
