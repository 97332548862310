import React, { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { Button } from '@faststore/ui'
import { Image } from 'src/components/ui/Image'
import useReleaseDate from 'src/hooks/useReleaseDate'
import CheckCircleOrangeSmall from 'src/images/svg/check-circle-orange-small'
import type { ProductSummary_ProductFragment } from '@generated/graphql'
import {
  RenderCollectionTags,
  TagsCollection,
  validateVivaraYou,
} from 'src/components/product/ProductCard/tagsCollection'

import { HandleMouseOver, getValidImages } from './ProductCardAdd'
import type { ClusterHighlights } from './ProductCardAssemble'
import { imgOptions, HandlePrice } from './ProductCardAssemble'
import type { SelectedProductsAssemble } from '../Assembly/types'
import './product-unavailable-card.scss'

interface ProductCardAssembleAddProps {
  product: ProductSummary_ProductFragment & ClusterHighlights
  shelfClass: string
  aspectRatio: number
  shownIcon: boolean
  addButton: boolean
  selectedOffer: number
  onClickAddCardProduct: (product: SelectedProductsAssemble) => Promise<number>
  setShowNotificationModal: Dispatch<SetStateAction<boolean>>
  setIsSelected: Dispatch<SetStateAction<boolean>>
  setCanvaIndex: Dispatch<SetStateAction<number>>
  setIsAddButton: Dispatch<SetStateAction<boolean>>
  isChain: boolean
  onClickAddChain: (product: SelectedProductsAssemble) => Promise<void>
  hasChainSelected: boolean
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
}

export const ProductCardAssembleAdd = ({
  product,
  shelfClass,
  aspectRatio,
  shownIcon,
  addButton,
  selectedOffer,
  setCanvaIndex,
  setIsSelected,
  setIsAddButton,
  onClickAddCardProduct,
  setShowNotificationModal,
  isChain,
  onClickAddChain,
  hasChainSelected,
  selectedProductsAssembleBraceletChain,
}: ProductCardAssembleAddProps) => {
  const [collectionId, setCollectionId] = useState<{
    id: string | null
    name: string | null
  }>()

  const [renderVivaraYou, setRenderVivaraYou] = useState(false)

  const [collections, setCollections] = useState<
    Array<{ id: string | null; name: string | null }> | null | undefined
  >()

  const firstValidImage =
    product?.image?.length > 1
      ? getValidImages(product?.image)
      : {
          url: product?.image?.[0]?.url ?? '',
          alternateName: product?.image?.[0]?.alternateName ?? '',
        }

  const {
    name,
    isVariantOf,
    releaseDate,
    image: productImages,
    offers: { lowPrice: spotPrice, offers },
  } = product

  const isNewRelease = useReleaseDate(releaseDate)

  const { hasVariant: variants } = isVariantOf

  const [img] = productImages

  const [cardImage, setCardImage] = useState({
    url: firstValidImage?.url,
    alternateName: firstValidImage?.alternateName,
  })

  const productInfo: SelectedProductsAssemble = {
    imageCanva: productImages?.filter(
      (image) => image?.url?.includes('_mount') || image?.url?.includes('-mon')
    )?.[0]?.url,
    index: null,
    product,
  }

  useEffect(() => {
    if (product) {
      setCollections(product?.clusterHighlights)
      setCardImage({
        url: firstValidImage?.url,
        alternateName: firstValidImage?.alternateName,
      })
    }
  }, [product])

  const variantsInStock = variants?.filter(
    (variantUnit: { offers: { offers: Array<{ availability: string }> } }) => {
      return (
        variantUnit?.offers?.offers?.[0]?.availability ===
        'https://schema.org/InStock'
      )
    }
  )

  const {
    listPrice,
    price,
    availability: productAvailability,
  } = offers[selectedOffer]

  TagsCollection({ collections, setCollectionId })

  useEffect(() => {
    if (collectionId?.id === '2295') {
      validateVivaraYou(
        product?.isVariantOf?.productGroupID,
        setRenderVivaraYou
      )
    }
  }, [collectionId])

  if (!variantsInStock.length) {
    return (
      <div className={`${shelfClass} unavailable`}>
        <Image
          baseUrl={cardImage?.url}
          alt={cardImage?.alternateName}
          aspectRatio={aspectRatio}
          {...imgOptions}
          imgStyle={{ objectFit: 'contain' }}
        />

        <div className={`${shelfClass}__content`}>
          <div className="unavailableTag-container">
            <p className="unavailableTag-text">Indisponível</p>
          </div>

          <h3 className={`${shelfClass}__name-product`}>{name}</h3>

          <div className={`${shelfClass}__cost`}>
            <HandlePrice
              spotPrice={spotPrice}
              listPrice={listPrice}
              price={price}
              isProductAvailable={!productAvailability}
              shelfClass={shelfClass}
            />
          </div>
        </div>
      </div>
    )
  }

  if (price === 0) {
    return <></>
  }

  return (
    <>
      {!addButton && (
        <div className={`${shelfClass}`}>
          <Button
            className="assemble-product-select"
            onClick={() => {
              setIsAddButton(true)
            }}
            onMouseLeave={() => {
              setIsAddButton(false)
            }}
          >
            <Image
              baseUrl={cardImage?.url ?? img?.url}
              alt={cardImage?.alternateName ?? img?.alternateName}
              aspectRatio={aspectRatio}
              {...imgOptions}
              imgStyle={{ objectFit: 'contain' }}
            />
          </Button>

          {shownIcon && (
            <div className="product-checked-icon">
              <CheckCircleOrangeSmall />
            </div>
          )}

          <Button
            className="assemble-product-select"
            onClick={() => {
              setIsAddButton(true)
            }}
          >
            <div className={`${shelfClass}__content`}>
              <RenderCollectionTags
                collections={collections}
                collectionId={collectionId}
                isNewRelease={isNewRelease}
              />

              <h3 className={`${shelfClass}__name-product`}>{name}</h3>

              <div className={`${shelfClass}__cost`}>
                <HandlePrice
                  spotPrice={spotPrice}
                  listPrice={listPrice}
                  price={price}
                  isProductAvailable={!productAvailability}
                  shelfClass={shelfClass}
                  collectionId={collectionId}
                  isVivaraYou={renderVivaraYou}
                />
              </div>
            </div>
          </Button>
        </div>
      )}

      {addButton && (
        <div className={`${shelfClass} bordered`}>
          <HandleMouseOver
            aspectRatio={aspectRatio}
            setIsSelected={setIsSelected}
            setIsAddButton={setIsAddButton}
            onClickAddCardProduct={onClickAddCardProduct}
            onClickAddChain={onClickAddChain}
            setShowNotificationModal={setShowNotificationModal}
            setCanvaIndex={setCanvaIndex}
            productInfo={productInfo}
            isChain={isChain}
            hasChainSelected={hasChainSelected}
            selectedProductsAssembleBraceletChain={
              selectedProductsAssembleBraceletChain
            }
          />
        </div>
      )}
    </>
  )
}
