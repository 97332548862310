export function sortShippingEstimate(slasData: ShippingSimulation[]) {
  return slasData?.length > 1
    ? slasData
        .filter(
          (s) =>
            s?.name &&
            s.name !== 'Agendada' &&
            s?.deliveryChannel &&
            s.deliveryChannel !== 'pickup-in-point'
        )
        .sort((a, b) => {
          if (a?.shippingEstimate?.includes('h')) {
            return -1
          }

          if (b?.shippingEstimate?.includes('h')) {
            return 1
          }

          return (
            parseFloat(a?.shippingEstimate?.replace(/[bd]d?|d[b-d]?/, '')) -
            parseFloat(b?.shippingEstimate?.replace(/[bd]d?|d[b-d]?/, ''))
          )
        })
        .concat(
          slasData.filter((s) => s?.deliveryChannel === 'pickup-in-point')
        )
    : slasData
}
