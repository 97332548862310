import React from 'react'

export default function PhoneSVG() {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.66965 9.70143C7.44762 11.2919 8.73687 12.5753 10.3309 13.346C10.4475 13.4013 10.5765 13.4253 10.7052 13.4155C10.8339 13.4058 10.9579 13.3627 11.0648 13.2905L13.4119 11.7254C13.5157 11.6562 13.6352 11.614 13.7594 11.6026C13.8837 11.5911 14.0088 11.6109 14.1235 11.6601L18.5144 13.5419C18.6636 13.6053 18.7881 13.7154 18.8693 13.8557C18.9504 13.996 18.9838 14.1588 18.9643 14.3197C18.8255 15.4057 18.2956 16.4039 17.4739 17.1274C16.6521 17.8508 15.5948 18.2499 14.5 18.25C11.1185 18.25 7.87548 16.9067 5.48439 14.5156C3.0933 12.1245 1.75 8.88151 1.75 5.5C1.75006 4.40516 2.14918 3.34789 2.87264 2.52613C3.5961 1.70438 4.59428 1.17451 5.68028 1.03572C5.84117 1.01625 6.00403 1.04959 6.14432 1.13073C6.28461 1.21186 6.39473 1.33639 6.4581 1.48555L8.34158 5.88035C8.3903 5.99403 8.41015 6.11799 8.39936 6.24119C8.38858 6.3644 8.3475 6.48302 8.27977 6.5865L6.72011 8.9696C6.64912 9.07681 6.60716 9.20059 6.59831 9.32886C6.58947 9.45713 6.61405 9.5855 6.66965 9.70143V9.70143Z"
          stroke="#F08769"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}
