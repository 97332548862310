import React, { lazy } from 'react'
import lazyRetry from 'src/utils/lazyRetry'

const CalendarIcon = lazy(() =>
  lazyRetry(() => import('src/components/icons/Calendar'), 'CalendarIcon')
)

const DockOrder = () => {
  return (
    <div className="shipping-simulator__dockOrder">
      <CalendarIcon />
      <p>
        *O prazo de entrega será maior, pois este item será feito sob encomenda.
      </p>
    </div>
  )
}

export default DockOrder
