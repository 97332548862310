import React, { useMemo, useState } from 'react'
import MapPinIcon from 'src/components/icons/MapPin'

import type { InventoryFormModalStep } from './InventoryFormModal'

type InventoryResultProps = {
  pickupInPoints: IPickupPointsInventory[]
  setStep: React.Dispatch<React.SetStateAction<InventoryFormModalStep>>
  postalCode: string
  getStoreDetails: (storeId: string) => void
  setSelectedPickupPoint: React.Dispatch<
    React.SetStateAction<IPickupPointsInventory | undefined>
  >
}

const INITIAL_ITEMS_TO_SHOW = 3

export const InventoryResult = ({
  pickupInPoints,
  setStep,
  postalCode,
  getStoreDetails,
  setSelectedPickupPoint,
}: InventoryResultProps) => {
  const [quantityItemsToShow, setQuantityItemsToShow] = useState(
    INITIAL_ITEMS_TO_SHOW
  )

  const pickupInPointsToShow = useMemo(() => {
    const sortedPoints = pickupInPoints
      .slice()
      .sort(
        (pickupPointA, pickupPointB) =>
          pickupPointA.pickupDistance - pickupPointB.pickupDistance
      )

    return sortedPoints.slice(0, quantityItemsToShow)
  }, [pickupInPoints, quantityItemsToShow])

  const isExpanded = useMemo(
    () =>
      pickupInPointsToShow.length === pickupInPoints.length &&
      pickupInPoints.length > INITIAL_ITEMS_TO_SHOW,
    [pickupInPoints, pickupInPointsToShow]
  )

  return (
    <div className="inventory-modal__result-step">
      <div className="result-step__header">
        <MapPinIcon color="#F08769" />
        <span className="result-step__title">{`Próximo à ${postalCode}`}</span>
        <button
          className="result-step__back-button"
          onClick={() => setStep('form')}
        >
          alterar
        </button>
      </div>
      <p className="result-step__text">Melhores Resultados</p>
      <ul
        className={`result-step__pickuppoints ${isExpanded ? 'expanded' : ''}`}
      >
        {pickupInPointsToShow.map((pickupPoint) => (
          <li className="inventory-modal__card" key={pickupPoint.friendlyName}>
            <div className="inventory-modal__card-distance">
              <MapPinIcon color="#F08769" />
              <span>{`${pickupPoint?.pickupDistance?.toFixed(1)} km`}</span>
            </div>
            <div className="inventory-modal__card-details">
              <span className="inventory-modal__card-name">
                {pickupPoint.friendlyName}
              </span>
              <span className="inventory-modal__card-address">
                {`${pickupPoint.address.street}, 
                ${pickupPoint.address.number}, 
                ${pickupPoint.address.neighborhood} - 
                ${pickupPoint.address.city} - 
                ${pickupPoint.address.state}`}
              </span>
              <button
                className="inventory-modal__card-button"
                onClick={() => {
                  getStoreDetails(pickupPoint.address.addressId)
                  setSelectedPickupPoint(pickupPoint)
                }}
              >
                VER MAIS DETALHES DA LOJA
              </button>
            </div>
          </li>
        ))}
      </ul>
      {pickupInPoints.length > INITIAL_ITEMS_TO_SHOW &&
        pickupInPointsToShow.length !== pickupInPoints.length && (
          <button
            className="inventory-modal__button"
            onClick={() => setQuantityItemsToShow(pickupInPoints.length)}
          >
            Ver todas as lojas
          </button>
        )}
    </div>
  )
}
