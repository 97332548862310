import type { StoreListItem } from '@generated/graphql'

export const verifyCategoryProduct = (itemListElement: StoreListItem[]) => {
  const listShowCategory = ['aneis-solitarios', 'aliancas']
  const arrayListElements = itemListElement?.[0]?.item?.split('/')

  let hasCategory

  if (arrayListElements?.[arrayListElements?.length - 2] === 'vivara') {
    itemListElement?.forEach((item) => {
      const listItems = item?.item?.split('/')
      const category = listItems?.[listItems?.length - 2]

      if (category && listShowCategory?.includes(category)) {
        hasCategory = category
      }
    })
  }

  return hasCategory
}
