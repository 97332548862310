import React, { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { IStoreSelectedFacet } from '@faststore/api'
import type { SearchSort } from '@faststore/sdk/dist/types'
import LayoutGridList from 'src/components/sections/ProductGallery/LayoutGridList'
import type {
  Filter_FacetsFragment,
  ProductSummary_ProductFragment,
} from '@generated/graphql'
import { Skeleton } from '@acctglobal/skeleton'

import { ProductGalleryPageAssemble } from './ProductGalleryPageAssemble'
import type { ClusterHighlights } from '../ProductCardCustomBracelet/ProductCardAssemble'
import type { SelectedProductsAssemble } from '../Assembly/types'

import './ProductGalleryAssemble.scss'

interface ProductGalleryAssembleProps {
  selectedFacets: IStoreSelectedFacet[]
  onClickAddCardProduct: (product: SelectedProductsAssemble) => Promise<number>
  onClickAddChain: (product: SelectedProductsAssemble) => Promise<void>
  onClickRemoveProduct: (index: number) => void
  setFilteredFacets: Dispatch<SetStateAction<Filter_FacetsFragment[]>>
  checkItemIsSelected: (
    product: ProductSummary_ProductFragment & ClusterHighlights
  ) => boolean
  sort: SearchSort
  hasChoiceChain: boolean
  hasChainSelected: boolean
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
}

export const ProductGalleryAssemble = ({
  selectedFacets,
  onClickAddCardProduct,
  onClickRemoveProduct,
  setFilteredFacets,
  checkItemIsSelected,
  sort,
  onClickAddChain,
  hasChoiceChain,
  hasChainSelected,
  selectedProductsAssembleBraceletChain,
}: ProductGalleryAssembleProps) => {
  const [pages, setPages] = useState<number[]>([0])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number | undefined>()
  const [layout, setLayout] = useState('grid')
  const isLayoutList = layout === 'list'
  const itemsPerPage = 8

  const handleChangeLayout = (value: string) => {
    setLayout(value)
  }

  const loadMore = () => {
    if (totalCount && pages.length < Math.ceil(totalCount / itemsPerPage)) {
      setPages((prev) => [...prev, pages.length])
    }
  }

  useEffect(() => {
    setPages([0])
  }, [selectedFacets])

  return (
    <>
      <div
        id="assembly-gallery"
        className="product-listing / grid-content-full"
      >
        <div className="product-listing__content-grid / grid-content">
          <div className="product-gallery-assemble__shape-selector">
            <LayoutGridList
              layout={layout}
              handleChangeLayout={handleChangeLayout}
            />
            {totalCount ? (
              <p className="product-gallery-assemble__count">{`${totalCount} produtos`}</p>
            ) : (
              <Skeleton width={90} height={16} backgroundColor="#F4F4F4" />
            )}
          </div>
          {pages.map((page, index) => (
            <ProductGalleryPageAssemble
              key={`product-gallery-page-assemble-${index}`}
              page={page}
              totalPages={pages.length}
              setTotalCount={setTotalCount}
              isLayoutList={isLayoutList}
              onClickAddCardProduct={onClickAddCardProduct}
              onClickRemoveProduct={onClickRemoveProduct}
              selectedFacets={selectedFacets}
              setFilteredFacets={setFilteredFacets}
              setLoadingPages={setIsLoading}
              checkItemIsSelected={checkItemIsSelected}
              sort={sort}
              onClickAddChain={onClickAddChain}
              hasChoiceChain={hasChoiceChain}
              hasChainSelected={hasChainSelected}
              selectedProductsAssembleBraceletChain={
                selectedProductsAssembleBraceletChain
              }
            />
          ))}
        </div>
        {isLoading && (
          <button
            className="product-gallery-assemble__pagination-button"
            onClick={loadMore}
          >
            carregar mais itens
          </button>
        )}
      </div>
    </>
  )
}
