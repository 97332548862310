export const clearUrlProductIdentifer = (urlParams: URLSearchParams) => {
  if (urlParams?.has('skuId')) {
    urlParams?.delete('skuId')
    window?.history?.replaceState(
      {},
      '',
      `${window?.location?.origin}${
        window?.location?.pathname
      }?${urlParams?.toString()}`
    )
  }
}
