import React from 'react'

interface Props extends React.HTMLProps<HTMLDivElement> {
  onClick: () => void
  width?: string
  height?: string
  stroke?: string
  viewBox?: string
  pathD?: string
  border?: string
}

const IconArrowRightAssembly = ({
  width = '13',
  height = '22',
  stroke = 'black',
  viewBox = '0 0 13 22',
  pathD = 'M1 1L11 11L1 20.9954',
  onClick,
}: Props) => {
  return (
    <button className="shelf-arrow-right-container" onClick={onClick}>
      <svg
        width={width}
        height={height}
        viewBox={viewBox}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="shelf-arrow-right"
      >
        <path d={pathD} stroke={stroke} strokeWidth="2" strokeMiterlimit="10" />
      </svg>
    </button>
  )
}

export default IconArrowRightAssembly
