import type { FC } from 'react'
import React, { useEffect, useState } from 'react'
import { useBuyButton } from 'src/sdk/cart/useBuyButton'

import getAttachment from './utils/useGetAttachment'
import type { Attachment, ProductCustomProps } from './typings/productData'
import ProductCustomModal from './ProductCustomModal'

import './styles.scss'

const stripEmojis = (str: string) =>
  str
    .replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ''
    )
    .replace(/\s+/g, ' ')
    .trim()

const handleNewCustomization = (
  e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  productCustom: {
    customText: string[]
    attachment: Attachment | null
    attachmentConfigMaxLength: number
  }
) => {
  e?.preventDefault()
  const updatedText = productCustom?.customText
  const key = parseInt(e?.target?.dataset?.key ?? '0', 10)

  let value = stripEmojis(e?.target?.value)

  value = value?.substring(0, productCustom?.attachmentConfigMaxLength)

  updatedText[key] = value

  const customizationValue =
    updatedText?.map((input: string, index: number) => {
      return `"${productCustom?.attachment?.Domains?.[index]?.FieldName}":"${input}"`
    }) || []

  const newCustomization = {
    propertyID: '',
    name: productCustom?.attachment?.Name ?? '',
    valueReference: 'ATTACHMENT',
    value: JSON.parse(`{${customizationValue?.toString()}}`),
  }

  return { newCustomization, updatedText }
}

const ProductCustom: FC<ProductCustomProps> = ({
  skuUpdated,
  productData,
  addToCart: { BuyButtonComponent },
  custom: {
    buttonCustomText,
    ButtonCustomIcon,
    modalFormText,
    modalFormInputPlaceholder,
    modalButtonClearText,
    modalButtonAddText,
    buttonCustomStyle,
    modalCustomStyle,
    modalCustomContainerStyle,
    modalTitleStyle,
    modalDescriptionStyle,
    modalImageStyle,
    modalCustomTextStyle,
    modalFormInputStyle,
    modalButtonClearStyle,
  },
}) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [haveAttachment, setHaveAttachment] = useState(false)
  const [buyDisabled, setBuyDisabled] = useState(
    productData?.offers?.offers?.[0]?.availability !==
      'https://schema.org/InStock'
  )

  const defaultValueCustomization = {
    propertyID: '',
    name: '',
    value: '',
    valueReference: '',
  }

  const [customText, setCustomText] = useState<string[]>([])
  const [customization, setCustomization] = useState<ProductCustomization>(
    defaultValueCustomization
  )

  const [attachment, setAttachment] = useState<Attachment | null>(null)
  const [attachmentConfig, setAttachmentConfig] = useState<TextAreaConfig>({
    maxRows: 0,
    maxLength: 0,
  })

  useEffect(() => {
    const fetchData = async () => {
      setHaveAttachment(false)

      const result = await getAttachment({
        sku: skuUpdated,
      })

      if (result?.length > 0) {
        result?.map((attachmentMap) => {
          if (attachmentMap?.Name?.indexOf('Perso') >= 0) {
            const attachmentName: string = attachmentMap?.Name
            const attachmentObj = attachmentMap

            if (attachmentName) {
              setAttachment(attachmentObj)

              const maxRows = attachmentObj?.Domains?.length
              const maxLength = parseInt(
                attachmentObj?.Domains?.[0]?.MaxCaracters,
                10
              )

              setCustomText(Array(maxRows).fill(''))

              setAttachmentConfig({
                maxRows,
                maxLength,
              })

              setHaveAttachment(true)
            }
          }

          return null
        })
      }
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skuUpdated])

  const buyProps: BuyPropsTypes = useBuyButton({
    id: productData?.id,
    price: productData?.offers?.offers?.[0]?.price,
    listPrice: productData?.offers?.offers?.[0]?.listPrice,
    seller: productData?.offers?.offers?.[0]?.seller,
    quantity: 1,
    itemOffered: {
      sku: skuUpdated,
      name: productData?.name,
      gtin: productData?.gtin,
      image: productData?.image,
      brand: productData?.brand,
      isVariantOf: productData?.isVariantOf,
      additionalProperty: [customization],
    },
  })

  const clearCustomization = () => {
    setCustomText(Array(attachmentConfig?.maxRows).fill(''))
    setCustomization(defaultValueCustomization)
  }

  const handleCloseModal = () => {
    setCustomText(Array(attachmentConfig?.maxRows).fill(''))
    setCustomization(defaultValueCustomization)
    setModalVisible(!modalVisible)
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const customProduct = {
      customText,
      attachment,
      attachmentConfigMaxLength: attachmentConfig?.maxLength,
    }

    const { newCustomization, updatedText } = handleNewCustomization(
      e,
      customProduct
    )

    setCustomization(newCustomization)
    setCustomText(updatedText)
    setBuyDisabled(false)
  }

  if (!haveAttachment) {
    return null
  }

  return (
    <>
      <div className="buttonCustom">
        <button
          style={buttonCustomStyle}
          className="buttonCustom"
          onClick={() => setModalVisible(!modalVisible)}
        >
          <span className="buttonCustom__text">{buttonCustomText}</span>
          <div className="buttonCustom__icon">{ButtonCustomIcon}</div>
        </button>
      </div>
      <ProductCustomModal
        buyProps={buyProps}
        modalCustomStyle={modalCustomStyle}
        modalVisible={modalVisible}
        modalCustomContainerStyle={modalCustomContainerStyle}
        handleCloseModal={handleCloseModal}
        modalTitleStyle={modalTitleStyle}
        buttonCustomText={buttonCustomText}
        modalDescriptionStyle={modalDescriptionStyle}
        attachmentConfig={attachmentConfig}
        modalImageStyle={modalImageStyle}
        productData={productData}
        modalCustomTextStyle={modalCustomTextStyle}
        customText={customText}
        modalFormText={modalFormText}
        modalFormInputStyle={modalFormInputStyle}
        modalFormInputPlaceholder={modalFormInputPlaceholder}
        modalButtonClearStyle={modalButtonClearStyle}
        clearCustomization={clearCustomization}
        modalButtonClearText={modalButtonClearText}
        customization={customization}
        BuyButtonComponent={BuyButtonComponent}
        buyDisabled={buyDisabled}
        modalButtonAddText={modalButtonAddText}
        handleChange={handleChange}
        attachment={attachment}
      />
    </>
  )
}

export default ProductCustom
