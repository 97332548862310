import React from 'react'
import { AlertError } from 'src/components/icons/AlertError'

interface IAlertAvaibleOnChangeCEP {
  setAlertProductByRegion: React.Dispatch<React.SetStateAction<boolean>>
}

export const AlertAvaibleOnChangeCEP = ({
  setAlertProductByRegion,
}: IAlertAvaibleOnChangeCEP) => {
  return (
    <div className="product-details__alert">
      <button
        className="product-details__alert--btn-close"
        onClick={() => {
          setAlertProductByRegion?.(false)
        }}
      >
        x
      </button>
      <div className="product-details__alert--icon">
        <AlertError />
      </div>
      <p className="product-details__alert--text">
        Ops! Infelizmente, o tamanho selecionado não está mais disponível. Por
        favor, revise o tamanho escolhido.
      </p>
    </div>
  )
}
