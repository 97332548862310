import React from 'react'

const PinkArrowUp = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    aria-label="Pink Arrow Up Icon"
  >
    <path
      d="M10.667 18.1333L16.0015 12.7987L21.3337 18.1333"
      stroke="#F08769"
      strokeWidth="2"
      strokeMiterlimit="10"
    />
  </svg>
)

export default PinkArrowUp
