interface IcategoryTree {
  item: string
  name: string
  position: number
}

export const isCategoryFromListCategories = (
  categoryTree: IcategoryTree[],
  categorySearchList: string[]
) =>
  categoryTree?.some(({ item }) =>
    categorySearchList?.some((categorySearch) => item?.includes(categorySearch))
  )
