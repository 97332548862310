import React from 'react'
import type { FC } from 'react'

const PlusRound: FC = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Plus Round Icon"
  >
    <rect width="24" height="24" fill="white" />
    <rect width="24" height="24" fill="white" />
    <circle cx="12" cy="12" r="8" fill="black" />
    <path
      d="M12 8V16"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8 12H16"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default PlusRound
