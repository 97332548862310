import React, { useEffect, useState } from 'react'
import './styles.scss'
import { Input } from '@faststore/ui'
import type { GivexProps } from 'src/components/sections/ProductDetails/types/HandleAvailableProductsType'

import getAttachment from '../ProductCustom/utils/useGetAttachment'

interface Props {
  sku: string
  setGivexAttachment: React.Dispatch<GivexProps>
}

interface AttachmentProps {
  Name: string
}

const ModalGivex = ({ sku, setGivexAttachment }: Props) => {
  const [modalName, setModalName] = useState('')
  const [modalMessage, setModalMessage] = useState('')
  const [hasModal, setHasModal] = useState(false)
  const [isSavedMessage, setIsSavedMessage] = useState(false)

  const getGivex = async () => {
    const response = await getAttachment({
      sku,
    })

    const attachment = response?.filter(
      (attachmentItem: AttachmentProps) =>
        attachmentItem.Name === 'valePresente'
    )

    setHasModal(attachment.length > 0)
  }

  useEffect(() => {
    if (sku) {
      getGivex()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sku])

  const handleSubmit = async (event: React.MouseEvent) => {
    event.preventDefault()

    setGivexAttachment({
      propertyID: '',
      name: 'valePresente',
      valueReference: 'ATTACHMENT',
      value: {
        nome: modalName ?? '',
        mensagem: modalMessage ?? '',
      },
    })

    setIsSavedMessage(true)
  }

  return hasModal ? (
    <div className="modal-givex__container">
      <p className="modal-givex__title">
        Para quem você deseja enviar este Cartão Presente?
      </p>
      <form>
        <span className="label-input">Nome:</span>
        <Input
          className="name-input"
          maxLength={50}
          type="text"
          name="name"
          placeholder="Nome"
          onChange={(e) => {
            setIsSavedMessage(false)
            setModalName(e.target.value)
          }}
        />
        <span className="label-input">Mensagem:</span>
        <textarea
          className="message-input"
          maxLength={255}
          name="message"
          cols={30}
          rows={10}
          placeholder="Digite aqui a sua mensagem"
          onChange={(e) => {
            setIsSavedMessage(false)
            setModalMessage(e.target.value)
          }}
        />
        <button
          onClick={(e) => handleSubmit(e)}
          className={isSavedMessage ? 'saved__message' : 'not-saved__message'}
        >
          {isSavedMessage ? 'Mensagem Salva' : 'Salvar mensagem'}
          <span className="icon-button" />
        </button>
      </form>
    </div>
  ) : null
}

export default ModalGivex
