import React from 'react'

const CheckCircleOrangeSmall = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clipPath="url(#clip0_2307_26868)">
      <path
        d="M14.6666 7.38668V8.00001C14.6658 9.43763 14.2003 10.8365 13.3395 11.9879C12.4787 13.1393 11.2688 13.9817 9.89016 14.3893C8.51154 14.7969 7.03809 14.7479 5.68957 14.2497C4.34104 13.7515 3.18969 12.8307 2.40723 11.6247C1.62476 10.4187 1.25311 8.99205 1.3477 7.55755C1.44229 6.12305 1.99806 4.75756 2.93211 3.66473C3.86615 2.57189 5.12844 1.81027 6.53071 1.49344C7.93298 1.17662 9.4001 1.32157 10.7133 1.90668"
        stroke="#F08769"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 2.66669L8 9.34002L6 7.34002"
        stroke="#F08769"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2307_26868">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default CheckCircleOrangeSmall
