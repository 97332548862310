import React from 'react'
import { Installment } from 'src/components/ui/Installment'
import Price from 'src/components/ui/Price/Price'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'

interface Props {
  lowPrice: number
  listPrice: number
}

const BuyTogetherTotal = ({ lowPrice, listPrice }: Props) => {
  return (
    <div className="totalPrice_values">
      {lowPrice < listPrice ? (
        <>
          <Price
            value={listPrice}
            formatter={useFormattedPrice}
            data-value={listPrice}
            SRText="Original price:"
            className="totalPrice_values_listPrice"
          />
          <Price
            value={lowPrice}
            formatter={useFormattedPrice}
            data-value={lowPrice}
            SRText="Sale Price:"
            className="totalPrice_values_price"
          />
          <Installment formatter={useFormattedPrice} value={lowPrice} />
        </>
      ) : (
        <>
          <Price
            value={listPrice}
            formatter={useFormattedPrice}
            data-value={listPrice}
            className="totalPrice_values_price"
            SRText="Original price:"
          />
          <Installment formatter={useFormattedPrice} value={listPrice} />
        </>
      )}
    </div>
  )
}

export default BuyTogetherTotal
