import React from 'react'

const IconVideoPlay = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      d="M19.375 2.5C9.71094 2.5 1.875 10.3359 1.875 20C1.875 29.6641 9.71094 37.5 19.375 37.5C29.0391 37.5 36.875 29.6641 36.875 20C36.875 10.3359 29.0391 2.5 19.375 2.5ZM25.0039 20.2695L16.4727 26.4766C16.4259 26.5102 16.3708 26.5302 16.3133 26.5346C16.2559 26.5389 16.1984 26.5273 16.1471 26.501C16.0958 26.4748 16.0528 26.4349 16.0227 26.3858C15.9926 26.3367 15.9767 26.2802 15.9766 26.2227V13.8164C15.9764 13.7587 15.9922 13.7021 16.0222 13.6528C16.0522 13.6035 16.0953 13.5635 16.1467 13.5372C16.1981 13.5109 16.2557 13.4994 16.3132 13.5038C16.3708 13.5083 16.426 13.5286 16.4727 13.5625L25.0039 19.7656C25.0442 19.7941 25.0771 19.8319 25.0998 19.8757C25.1225 19.9196 25.1343 19.9682 25.1343 20.0176C25.1343 20.0669 25.1225 20.1156 25.0998 20.1594C25.0771 20.2033 25.0442 20.241 25.0039 20.2695V20.2695Z"
      fill="#C4C4C4"
    />
  </svg>
)

export default IconVideoPlay
