import React from 'react'
import { Link } from 'gatsby'
import { Image } from 'src/components/ui/Image'

import './select-similar.scss'
import type { SimilarsItem } from '../SimilarProducts'

export interface Props {
  similar: SimilarsItem
}

const SelectImage = ({ similar }: Props) => {
  const { type, link, selected, imgLink, name, hasStock } = similar
  const value = similar?.value?.replaceAll('_', ' ')
  const title = value?.charAt(0)?.toUpperCase() + value?.slice(1) || ''

  return (
    <Link
      className={`select-image__link ${type} ${hasStock ? '' : 'noStock'}`}
      to={link}
      target="_self"
      rel="noopener noreferrer"
      title={title}
      data-link-sku
    >
      {imgLink && (
        <Image
          className={`select-image__link-img ${selected ? 'selected' : ''}`}
          baseUrl={imgLink?.replace('http:', 'https:')}
          alt={name}
          aspectRatio={1}
          layout="constrained"
          loading="lazy"
          options={{
            fitIn: true,
          }}
        />
      )}
    </Link>
  )
}

export default SelectImage
