import type { BrowserProductQueryQuery } from '@generated/graphql'
import React, { useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import BaseModal from 'src/components/modal/BaseModal'
import Button from 'src/components/ui/Button'
import type { InferredType } from 'src/components/sections/ProductDetails/useOffer'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import { AssembleResume } from './AssembleResume'
import ProductRender from '../Canvas/ProductRender'
import { AssembleButtonAddToCart } from './AssembleButtonAddToCart'
import type { SelectedProductsAssemble } from '../Assembly/types'

import './assemble-resume.scss'

interface ReviewBraceletProps {
  isOpen: boolean
  modalClose: () => void
  modalOpen: () => void
  selectedProductsAssembleBracelet: Array<SelectedProductsAssemble | null>
  productMain: BrowserProductQueryQuery['product']
  hasChoiceChain: boolean
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
  selectedSkuBracelet: InferredType<
    BrowserProductQueryQuery['product']['isVariantOf']['hasVariant']
  >
  isFirstClick: boolean
  setIsFirstClick: Dispatch<SetStateAction<boolean>>
  applyChainFilter: () => void
}

export const ReviewBracelet = ({
  isOpen,
  modalClose,
  modalOpen,
  selectedProductsAssembleBracelet,
  productMain,
  hasChoiceChain,
  selectedProductsAssembleBraceletChain,
  selectedSkuBracelet,
  isFirstClick,
  setIsFirstClick,
  applyChainFilter,
}: ReviewBraceletProps) => {
  const [hasDiscount, setHasDiscount] = useState<boolean>(false)
  const { isMobile, width } = useWindowDimensions()

  return (
    <>
      <BaseModal
        isOpen={isOpen}
        onCloseButtonClick={modalClose}
        className="assemble-resume-modal"
      >
        <div
          className={`assemble-resume-modal__content ${
            hasDiscount ? 'has-discount' : ''
          }`}
        >
          <div className="assemble-resume-actions">
            <Button className="assemble-resume-back" onClick={modalClose}>
              VOLTAR
            </Button>
          </div>
          <ProductRender
            selectedProductsAssembleBracelet={selectedProductsAssembleBracelet}
            isMobileDimension={isMobile}
            widthDimension={width}
          />
          <AssembleResume
            productMain={productMain}
            selectedProductsAssembleBracelet={selectedProductsAssembleBracelet}
            selectedSkuBracelet={selectedSkuBracelet}
            setHasDiscount={setHasDiscount}
            isFirstClick={isFirstClick}
            setIsFirstClick={setIsFirstClick}
            hasChoiceChain={hasChoiceChain}
            selectedProductsAssembleBraceletChain={
              selectedProductsAssembleBraceletChain
            }
            applyChainFilter={applyChainFilter}
          />
        </div>
      </BaseModal>

      <div className="assemble-buttons">
        <div>
          <Button className="open-resume-button" onClick={modalOpen}>
            Revisar Pulseira
          </Button>
          <AssembleButtonAddToCart
            productMain={productMain}
            selectedProductsAssembleBracelet={selectedProductsAssembleBracelet}
            selectedSkuBracelet={selectedSkuBracelet}
            selectedProductsAssembleBraceletChain={
              selectedProductsAssembleBraceletChain
            }
            hasChoiceChain={hasChoiceChain}
            isFirstClick={isFirstClick}
            setIsFirstClick={setIsFirstClick}
            applyChainFilter={applyChainFilter}
          />
        </div>
      </div>
    </>
  )
}
