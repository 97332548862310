import React from 'react'
import { Button } from '@faststore/ui'
import { useCart } from 'src/sdk/cart'
import { useUI } from 'src/sdk/ui/Provider'
import { useSession } from 'src/sdk/session'
import { handleAddToCart } from 'src/components/AssembleYourBracelet/AssembleResume/HandleMinicart'

interface AddToCartProps {
  productRender: number
  setProductRender: React.Dispatch<React.SetStateAction<number>>
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  addCartData: any[]
  products: any
  buyTogetherHasVariant: boolean
}

const AddToCart = ({
  productRender,
  setProductRender,
  setIsOpen,
  addCartData,
  products,
  buyTogetherHasVariant,
}: AddToCartProps) => {
  const { id: cartId, items: itemsCart } = useCart()
  const { openCart } = useUI()
  const {
    currency: { code },
  } = useSession()

  const handleClick = () => {
    if (buyTogetherHasVariant && productRender === 0) {
      setProductRender(1)
    } else {
      const addCartProductData: any = []

      products.forEach((product: any, index: number) => {
        addCartProductData.push({
          id: addCartData?.[index]?.sku ?? product.sku,
          itemOffered: {
            additionalProperty:
              addCartData?.[index]?.additionalProperty ??
              product.additionalProperty,
            sku: addCartData?.[index]?.sku ?? product.sku,
            name: addCartData?.[index]?.name ?? product.name,
            gtin: product.gtin,
            image: product.image,
            brand: {
              name: product.brand.name,
            },
            isVariantOf: {
              additionalProperty: product.isVariantOf.additionalProperty,
              name: product.isVariantOf.name,
              productGroupID: product.isVariantOf.productGroupID,
            },
          },
          listPrice:
            addCartData?.[index]?.offers?.offers?.[0]?.listPrice ??
            product.offers.offers[0].listPrice,
          price:
            addCartData?.[index]?.offers?.offers?.[0]?.price ??
            product.offers.offers[0].price,
          quantity: 1,
          seller: {
            identifier:
              addCartData?.[index]?.offers?.offers?.[0]?.seller?.identifier ??
              product.offers.offers[0].seller.identifier,
          },
        })
      })

      const totalPrice =
        Number(addCartProductData[0].price) +
        Number(addCartProductData[1].price)

      handleAddToCart({
        products: addCartProductData,
        code,
        totalPrice,
        cartId,
        itemsCart,
      }).then(() => {
        setIsOpen(false)
        openCart()
      })
    }
  }

  return (
    <Button
      onClick={() => {
        handleClick()
      }}
      className="buyTogetherModal__button"
    >
      {productRender === 0 && buyTogetherHasVariant
        ? 'Prosseguir'
        : 'Adicionar os dois à sacola'}
    </Button>
  )
}

export default AddToCart
