import React from 'react'
import Expandable from 'src/components/ui/Expandable/Expandable'
import { useDetailsSpecificationsContext } from 'src/contexts/details-specification-context'
import DetailsOfProductGivex from 'src/components/product/DetailsOfProductGivex'
import type { BrowserProductQueryQuery } from '@generated/graphql'

import ProductDetailsExpandableContainer from './ProductDetailsExpandableContainer'

export interface Result {
  accountId: string
  accountName: string
  auto_filter: string | null
  createdBy: string
  createdIn: string
  dataEntityId: string
  description: string
  description_life: string | null
  followers: []
  id: string
  lastInteractionBy: string
  lastInteractionIn: string
  name: string
  tags: []
  updatedBy: string
  updatedIn: string
  url_image: string | null
  url_image_life: string | null
  alt_image: string | null
  alt_image_life: string | null
  type: string
}

interface Props {
  product: BrowserProductQueryQuery['product']
  isPDPLife: boolean
}

const ProductSpecifications = ({ product, isPDPLife }: Props) => {
  const { detailsSpecifications } = useDetailsSpecificationsContext()
  const productSpecification = detailsSpecifications.filter(
    (el) =>
      el.type === 'Material' ||
      el.type === 'Pedra' ||
      el.type === 'Malha' ||
      el.type === 'Mecanismos' ||
      el.type === 'Resistência' ||
      el.type === 'Material do vidro' ||
      el.type === 'Material da pulseira'
  )

  const isGift = product?.productClusters?.some((cluster) => {
    return cluster?.name === 'Cartão Presente'
  })

  const DetailsComponentGivex = <DetailsOfProductGivex />

  return (
    <>
      {isGift ? (
        <Expandable title="Detalhes">{DetailsComponentGivex}</Expandable>
      ) : (
        <>
          {productSpecification.length > 0 && (
            <Expandable title="Detalhes">
              <ProductDetailsExpandableContainer
                productSpecifications={productSpecification}
                isPDPLife={isPDPLife}
              />
            </Expandable>
          )}
        </>
      )}
    </>
  )
}

export default ProductSpecifications
