import React from 'react'
import { JsonLd } from 'gatsby-plugin-next-seo'
import type { ServerProductPageQueryQuery } from '@generated/graphql'

type ProductSchemaProps = {
  product: ServerProductPageQueryQuery['product']
}

export const ProductSchema = ({ product }: ProductSchemaProps) => {
  return (
    <JsonLd
      json={{
        '@context': 'https://schema.org',
        '@type': 'Product',
        name: product?.name ?? '',
        image: product?.image?.map((image) => image.url) ?? [],
        url: `${window.location.origin}/${product?.slug}/p`,
        sku: product?.sku ?? '',
        description: product?.description,
        brand: {
          '@type': 'Brand',
          name: product?.brand?.name ?? '',
        },
        offers: product?.offers?.offers.map((offer) => ({
          '@type': 'Offer',
          priceCurrency: 'BRL',
          price: offer?.price ?? 0,
          priceValidUntil: offer?.priceValidUntil,
          availability:
            offer?.availability === 'https://schema.org/InStock'
              ? offer?.availability
              : 'https://schema.org/OutOfStock',
          hasMerchantReturnPolicy: {
            '@type': 'MerchantReturnPolicy',
            applicableCountry: 'BR',
            returnPolicyCategory:
              'https://schema.org/MerchantReturnFiniteReturnWindow',
            merchantReturnDays: 30,
            returnMethod: 'https://schema.org/ReturnByMail',
            returnFees: 'https://schema.org/FreeReturn',
          },
        })),
      }}
    />
  )
}
