type SkuRingSize = {
  [key: string]: {
    size: number
  }
}

const skuSizeRing: SkuRingSize = {
  10: {
    size: 5.0,
  },
  11: {
    size: 5.1,
  },
  12: {
    size: 5.2,
  },
  13: {
    size: 5.3,
  },
  14: {
    size: 5.4,
  },
  15: {
    size: 5.5,
  },
  16: {
    size: 5.6,
  },
  17: {
    size: 5.7,
  },
  18: {
    size: 5.8,
  },
  19: {
    size: 5.9,
  },
  20: {
    size: 6.0,
  },
  21: {
    size: 6.1,
  },
  22: {
    size: 6.2,
  },
  23: {
    size: 6.3,
  },
  24: {
    size: 6.4,
  },
  25: {
    size: 6.5,
  },
  26: {
    size: 6.6,
  },
  27: {
    size: 6.7,
  },
  28: {
    size: 6.8,
  },
  29: {
    size: 6.9,
  },
}

export default skuSizeRing
