import React, { useEffect, useState, Fragment } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { InferredType } from 'src/components/sections/ProductDetails/useOffer'
import type { DataSimilars } from 'src/components/product/SimilarProducts/SimilarProducts'
import type { CurrencyCode, ViewItemEvent } from '@faststore/sdk'
import type { AnalyticsItem } from 'src/sdk/analytics/types'
import type {
  BrowserProductQueryQuery,
  SkuFragmentFragment,
} from '@generated/graphql'
import BuyButton from 'src/components/ui/BuyButton'
import QuantitySelector from 'src/components/ui/QuantitySelector'
import { useBuyButton } from 'src/sdk/cart/useBuyButton'
import './product-details.scss'
import ShippingSimulator from 'src/components/sections/ShippingSimulator'
import Button from 'src/components/ui/Button'
import ProductCustom from 'src/components/product/ProductCustom/ProductCustom'
import CustomizeIcon from 'src/components/icons/Customize'
import { useCart } from 'src/sdk/cart'
import { useWishlistContext } from 'src/contexts/WishlistContext/wishlist-context'
import { useProduct } from 'src/sdk/product/useProduct'
import { useSession } from 'src/sdk/session'
import { useAvailableItems } from 'src/hooks/useAvailableItems'
import { useViewItem } from 'src/sdk/analytics/hooks/useViewItemEvent'
import { sendAnalyticsEvent } from '@faststore/sdk'
import WishlistHeart from 'src/components/ui/WishlistHeart'
import ValidateUTMPrice from 'src/components/product/ValidateUTMPrice'
import AssemblyButton from 'src/components/AssembleYourBracelet/AssemblyButton/AssemblyButton'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { sortSkuVariations } from 'src/utils/product/sortSkuVariations'
import { SkuSelectorProduct } from 'src/components/modal/SkuSelectorModal/SkuSelectorProduct'
import BuyTogether from 'src/components/product/BuyTogether/BuyTogether'
import MeasureGuide from 'src/components/MeasureGuide'
import PromotionBarContainer from 'src/components/product/ProductKitLook/PromotionBarContainer'
import ComparedProduct from 'src/components/product/ComparedProduct'
import ModalGivex from 'src/components/product/ModalGivex/ModalGivex'
import WishlistNotification from 'src/components/wishlist/WishlistNotification'
import { clearUrlProductIdentifer } from 'src/utils/product/clearUrlProductIdentifier'
import findMissingProductIdsOnInsiderWishlistObject from 'src/contexts/WishlistContext/utils/findMissingProductIdsOnInsiderWishlistObject'
import WishListInsiderUpdater from 'src/components/wishlist/WishListInsiderUpdater'

import type { SendAnalytics } from './types/SendAnalytics'
import type { IOProduct } from '../PlpSellerStore/types'
import type { ProductDetailsType } from './types/ProductDetailsType'
import type {
  GivexProps,
  HandleAvailableProductsType,
} from './types/HandleAvailableProductsType'
import { AlertAvaibleOnChangeCEP } from './AlertAvaibleOnChangeCEP'
import ProductBenefits from '../ProductBenefits'
import ProductTitleSection from './ProductTitleSection'
import Description from '../ProductDescription/Description'
import { ProductDetailsHandler } from './ProductDetailsHandler'
import { DetailsSideBarAbove } from './DetailsSideBarAbove'
import OneClickBuy from './components/OneClickBuy'
import SustantabilityMessage from '../ProductDescription/SustantabilityMessage'

interface HandlePriceProps {
  lowPrice: number
  listPrice: number
  productId: string
  clusterHighlights: Array<{
    id: string | null
    name: string | null
  }> | null
  isKitLook?: boolean
  shouldShowinstallment?: boolean
}

export const HandlePrice = ({
  lowPrice,
  listPrice,
  productId,
  clusterHighlights,
  isKitLook,
  shouldShowinstallment,
}: HandlePriceProps) => {
  return (
    <div className="product-details__prices">
      <ValidateUTMPrice
        lowPrice={lowPrice}
        listPrice={listPrice}
        productId={productId}
        isKitLook={isKitLook}
        shouldShowinstallment={shouldShowinstallment}
      />
      {clusterHighlights?.map((item) => {
        if (item.id === '1950' || item.id === '1951') {
          return (
            <p className="tagProduct" key={item.id}>
              {item.name}
            </p>
          )
        }

        return <Fragment key={item.id} />
      })}
    </div>
  )
}

const ValidateProductOnChangeCEP = (
  skuVariants: SkuFragmentFragment[],
  setAlertProductByRegion: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const urlParams = new URLSearchParams(window?.location?.search)
  const skuId = urlParams?.get('skuId')

  if (skuId) {
    const index = skuVariants?.findIndex((variant) => {
      return (
        variant?.sku === skuId &&
        variant?.offers?.offers?.[0]?.availability ===
          'https://schema.org/InStock'
      )
    })

    if (index !== -1) {
      const produto = skuVariants?.[index]

      skuVariants?.splice(index, 1)
      skuVariants?.unshift(produto)
    } else {
      setAlertProductByRegion?.(true)
      clearUrlProductIdentifer(urlParams)
    }
  }

  return skuVariants
}

const ProductDetails = ({
  setAlertProductByRegion,
  hasVariantValue,
  sku,
  breadcrumbs,
  hasVariant,
  product,
}: ProductDetailsType) => {
  const [selectedSku, setSelectedSku] = useState<string | null>(null)

  useEffect(() => {
    const variants = sortSkuVariations(product.isVariantOf.hasVariant)

    const orderedVariants = ValidateProductOnChangeCEP(
      variants,
      setAlertProductByRegion
    )

    for (const item of orderedVariants) {
      if (
        item?.offers?.offers[0]?.availability ===
          'https://schema.org/InStock' &&
        item.offers?.lowPrice > 0
      ) {
        setSelectedSku(item.sku)

        break
      }

      setSelectedSku(sku)
    }
  }, [product, sku])

  return (
    <div className="product-details__items">
      <div
        className={
          hasVariantValue
            ? 'product-details__sku'
            : 'product-details__sku--disable'
        }
      >
        {hasVariantValue && selectedSku && (
          <SkuSelectorProduct
            defaultSku={selectedSku}
            categoryTree={breadcrumbs.itemListElement}
            variants={sortSkuVariations(hasVariant)}
            product={product}
            setAlertProductByRegion={setAlertProductByRegion}
          />
        )}
      </div>
      <MeasureGuide categoryTree={breadcrumbs.itemListElement} />
    </div>
  )
}

export const HandleAvailableProducts = ({
  hasVariantValue,
  sku,
  breadcrumbs,
  hasVariant,
  product,
  setAddQuantity,
  availableItemsValue,
  orderFormId,
  items,
  wasList,
  setWasList,
  isProductAvailable,
  buyProps,
  removeProductFromWishlist,
  setIsSaveProductModalOpen,
  setIsInventoryModalOpen,
  addQuantity,
  seller,
  setIsAssemblePersonlize,
  showButtonAssembleYourBracelet,
  selectedSKU,
  setSelectedSkuBracelet,
  setGivexAttachment,
}: HandleAvailableProductsType) => {
  const {
    isVariantOf: { additionalProperty },
  } = product

  const pdpBuyButtonId = 'pdp_buy-button'

  const getPixel = additionalProperty?.filter(
    (property: { name: string }) => property?.name === 'Pixel'
  )

  const getPixelMobile = additionalProperty?.filter(
    (property: { name: string }) => property?.name === 'Pixel Mobile'
  )

  const valueSizeDesktop = getPixel[0]?.value
    ? `${getPixel[0]?.value}px`
    : '18px'

  const valueSizeMobile = getPixelMobile[0]?.value
    ? `${getPixelMobile[0]?.value}px`
    : '18px'

  const { isMobile } = useWindowDimensions()
  const { lists } = useWishlistContext()

  const size = isMobile ? valueSizeMobile : valueSizeDesktop

  const productToWishlist = selectedSKU ? { ...selectedSKU } : product

  const [alertProductByRegion, setAlertProductByRegion] =
    useState<boolean>(false)

  const [productIdsForInsiderUpdate, setProductIdsForInsiderUpdate] = useState<
    string[]
  >([])

  const isGiftCard = breadcrumbs?.itemListElement?.some(
    (breadcrumb) =>
      breadcrumb?.name?.toLowerCase() === 'cartão presente - vivara' ||
      breadcrumb?.name?.toLowerCase() === 'cartão presente - life'
  )

  useEffect(() => {
    if (!window.insider_object.wishlist) {
      window.insider_object.wishlist = {
        id: '',
        currency: 'BRL',
        total: window.insider_object?.wishlist?.total ?? 0,
        line_items: window.insider_object?.wishlist?.line_items ?? [],
      }
    }

    const missingProductIds =
      findMissingProductIdsOnInsiderWishlistObject(lists)

    if (missingProductIds.length === 0) {
      return
    }

    setProductIdsForInsiderUpdate(missingProductIds)
  }, [lists])

  return (
    <>
      <section className="product-details__settings">
        {productIdsForInsiderUpdate.map((productId) => (
          <WishListInsiderUpdater productId={productId} key={productId} />
        ))}
        <ProductDetails
          hasVariantValue={hasVariantValue}
          sku={sku}
          breadcrumbs={breadcrumbs}
          hasVariant={sortSkuVariations(hasVariant)}
          product={product}
          setAlertProductByRegion={setAlertProductByRegion}
        />
        <div className="product-details__content-quantity">
          <QuantitySelector
            initial={1}
            min={1}
            max={30}
            setQuantity={setAddQuantity}
            quantity={addQuantity}
            limitQuantity={availableItemsValue}
          />

          {availableItemsValue && availableItemsValue < 5 ? (
            <span>
              {availableItemsValue === 1
                ? 'Apenas uma unidade disponível'
                : `(${availableItemsValue}) disponíveis`}
            </span>
          ) : (
            <></>
          )}
        </div>
        <ProductCustom
          skuUpdated={sku}
          orderForm={{
            orderFormId,
            items,
          }}
          routes={{
            getAttachmentRoute: '',
            setAttachmentRoute: '/api/productCustomization/setAttachment',
            addProductRoute: '/api/productCustomization/addProduct',
          }}
          productData={product}
          addToCart={{
            BuyButtonComponent: BuyButton,
            useBuyButtonHook: useBuyButton,
          }}
          custom={{
            buttonCustomText: 'PERSONALIZAR',
            ButtonCustomIcon: <CustomizeIcon />,
            buttonCustomStyle: { letterSpacing: '0.1em' },
            modalTitleStyle: {
              fontFamily: 'Cormorant Garamond',
              fontSize: '24px',
              fontWeight: '600',
              letterSpacing: '0.15em',
            },
            modalCustomTextStyle: {
              color: '#96712F',
              fontSize: size,
            },
            modalButtonAddText: 'ADICIONAR À SACOLA',
            modalButtonClearText: 'LIMPAR TUDO',
            modalButtonSaveText: 'SALVAR PERSONALIZAÇÃO',
            modalDescriptionStyle: {
              color: '#737373',
              fontSize: '14px',
            },
            modalFormInputPlaceholder: 'Escreva aqui',
            modalFormText: 'Texto Personalizado',
          }}
        />
        {wasList && (
          <WishlistNotification
            typeNotification={wasList}
            setWasList={setWasList}
          />
        )}
        {showButtonAssembleYourBracelet && (
          <AssemblyButton
            setIsAssemblePersonlize={setIsAssemblePersonlize}
            selectedSKU={selectedSKU}
            setSelectedSkuBracelet={setSelectedSkuBracelet}
          />
        )}
        <ModalGivex sku={sku} setGivexAttachment={setGivexAttachment} />
        <OneClickBuy quantity={addQuantity} products={selectedSKU} />
        <div className="product-details__buttons">
          <BuyButton
            id={pdpBuyButtonId}
            disabled={!isProductAvailable}
            {...buyProps}
          >
            ADICIONAR À SACOLA
          </BuyButton>
          <WishlistHeart
            products={[productToWishlist]}
            hasBackgroundColor
            isOnShelf={false}
            onFilledHeartClick={async () => {
              await removeProductFromWishlist([{ ...productToWishlist }])
              setWasList('deleteProduct')
            }}
            onEmptyHeartClick={() => setIsSaveProductModalOpen(true)}
            pdpPath={window.location.pathname}
          />
        </div>

        <Button
          className="inventory-check-button"
          onClick={() => setIsInventoryModalOpen(true)}
        >
          ENCONTRE NA LOJA MAIS PRÓXIMA
        </Button>
      </section>
      <PromotionBarContainer product={product} componentType="pdp" />
      {!isGiftCard && (
        <ShippingSimulator
          items={[
            {
              id: sku,
              quantity: addQuantity.toString(),
              seller: seller?.identifier,
            },
          ]}
        />
      )}
      {alertProductByRegion && (
        <AlertAvaibleOnChangeCEP
          setAlertProductByRegion={setAlertProductByRegion}
        />
      )}
    </>
  )
}

function sendAnalytics({
  currency,
  price,
  isVariantOf,
  brand,
  name,
  gtin,
  breadcrumbs,
}: SendAnalytics) {
  const refIdProduct = isVariantOf?.additionalProperty?.filter(
    (propertie) => propertie?.name === 'Cód'
  )[0]?.value

  sendAnalyticsEvent<ViewItemEvent<AnalyticsItem>>({
    name: 'view_item',
    params: {
      currency: currency.code as CurrencyCode,
      value: price,
      items: [
        {
          item_id: isVariantOf.productGroupID,
          item_name: isVariantOf.name,
          item_brand: brand.name,
          item_category: `${breadcrumbs?.itemListElement[1]?.name}/${breadcrumbs?.itemListElement[2]?.name}`,
          price,
          item_variant_name: name,
          product_reference_id: String(refIdProduct),
          dimension1: gtin !== String(refIdProduct) ? gtin : '',
        },
      ],
    },
  })
}

function verifyExistVariantInStock(
  variantsList: Array<{
    sku: string
    name: string
    description: string
    offers: {
      lowPrice: number
      offers: Array<{
        availability: string
        price: number
        listPrice: number
        seller: { identifier: string }
      }>
    }
    additionalProperty: Array<{
      propertyID: string
      name: string
      value: number
      valueReference: string
    }>
  }>
) {
  for (const item of variantsList) {
    const offersItem = item.offers?.offers?.[0]

    if (offersItem?.availability === 'https://schema.org/InStock') {
      return true
    }
  }

  return false
}

interface DetailsSideBarProps {
  setIsAssemblePersonlize: Dispatch<SetStateAction<boolean>>
  product: BrowserProductQueryQuery['product']
  priceData: PriceData
  selectedSKU: BrowserProductQueryQuery['product']
  showButtonAssembleYourBracelet: boolean
  setSelectedSkuBracelet: Dispatch<
    SetStateAction<
      | InferredType<
          BrowserProductQueryQuery['product']['isVariantOf']['hasVariant']
        >
      | undefined
    >
  >
  similars: DataSimilars
  setShowProductBar: React.Dispatch<React.SetStateAction<boolean>>
  buyTogetherProducts: IOProduct[]
}

function DetailsSideBar({
  setIsAssemblePersonlize,
  product,
  priceData,
  selectedSKU,
  showButtonAssembleYourBracelet,
  setSelectedSkuBracelet,
  similars,
  setShowProductBar,
  buyTogetherProducts,
}: Readonly<DetailsSideBarProps>) {
  const { items, id: orderFormId } = useCart()
  const { currency } = useSession()
  const { removeProductFromWishlist, createWishlist } = useWishlistContext()
  const [wasList, setWasList] = useState<string>()
  const [isSaveProductModalOpen, setIsSaveProductModalOpen] =
    useState<boolean>(false)

  const [isCreateWishlistModalOpen, setIsCreateWishlistModalOpen] =
    useState<boolean>(false)

  const [addQuantity, setAddQuantity] = useState<number>(1)
  const [isInventoryModalOpen, setIsInventoryModalOpen] =
    useState<boolean>(false)

  const [givexAttachment, setGivexAttachment] = useState<GivexProps>()
  const { isWindowBiggerThanTablet } = useWindowDimensions()

  const {
    id,
    name,
    gtin,
    image: productImages,
    brand,
    isVariantOf,
    isVariantOf: { hasVariant },
    breadcrumbList: breadcrumbs,
    clusterHighlights,
    additionalProperty,
  } = product

  const isGiftCard = breadcrumbs?.itemListElement?.some(
    (breadcrumb) =>
      breadcrumb?.name?.toLowerCase() === 'cartão presente - vivara' ||
      breadcrumb?.name?.toLowerCase() === 'cartão presente - life'
  )

  const { sku } = selectedSKU
  const { availableItemsValue } = useAvailableItems({ sku, product })
  const { availability, listPrice, lowPrice, price, seller } = priceData
  const priceFinal = price ?? 0
  const { isValidating } = useProduct(product.id)

  let isProductAvailable = availability === 'https://schema.org/InStock'
  const hasVariantValue =
    hasVariant?.length > 1 ||
    additionalProperty?.some(
      (property) => property.valueReference === 'SPECIFICATION'
    )

  const buyProps = useBuyButton({
    id,
    price: priceFinal,
    listPrice,
    seller,
    quantity: addQuantity,
    itemOffered: {
      sku,
      name,
      gtin,
      image: productImages,
      brand,
      isVariantOf,
      additionalProperty: givexAttachment ? [givexAttachment] : [],
    },
  })

  if (hasVariantValue) {
    isProductAvailable = verifyExistVariantInStock(
      sortSkuVariations(hasVariant)
    )
  }

  const colecao = [...isVariantOf.additionalProperty].find(
    (itemColecao) => itemColecao.name === 'Coleção'
  )

  const matchType = product.name.match(/^\p{L}+/u)

  const matchQuilates = product.name.match(/(\d+)\s*k/i)

  const itemSize = product.additionalProperty.length
    ? `${product.additionalProperty[0].name} ${product.additionalProperty[0].value}`
    : product.isVariantOf.additionalProperty.find(
        (item) => item.name === 'Tamanho da Caixa'
      )?.value ||
      product.isVariantOf.additionalProperty.find(
        (item) => item.name === 'Altura aprox'
      )?.value ||
      product.isVariantOf.additionalProperty.find(
        (item) => item.name === 'Largura aprox'
      )?.value

  const { sendUseViewItemEvent } = useViewItem({
    item_id: product.id,
    item_name: product.name,
    item_category: product.breadcrumbList.itemListElement[1].name,
    item_subcategory: product.breadcrumbList.itemListElement[2].name,
    item_collection:
      product.isVariantOf.additionalProperty.find(
        (item) => item.name === 'Coleção'
      )?.value ?? null,
    item_type: matchType ? matchType[0] : null,
    item_reference: product.gtin,
    item_material:
      product.isVariantOf.additionalProperty.find(
        (item) => item.name === 'Material'
      )?.value ??
      product.isVariantOf.additionalProperty.find(
        (item) => item.name === 'Material da Caixa'
      )?.value,
    item_brand: product.brand.name,
    price: product.offers.offers[0].listPrice,
    currency: 'BRL',
    item_size: itemSize,
    item_stone:
      product.isVariantOf.additionalProperty.find(
        (item) => item.name === 'Pedras'
      )?.value ?? null,
    item_weight: matchQuilates ? (matchQuilates[0] as string) : null,
    item_audience:
      product.isVariantOf.additionalProperty.find(
        (item) => item.name === 'Sugestão'
      )?.value ?? null,
    value: product.offers.offers[0].price,
  })

  useEffect(() => {
    sendAnalytics({
      currency,
      price: priceFinal,
      isVariantOf,
      brand,
      name,
      gtin,
      breadcrumbs,
    })
    sendUseViewItemEvent()
  }, [isVariantOf?.productGroupID])

  const [productSpecification, setProductSpecification] =
    useState<Array<{ Value: string[]; Id: number; Name: string }>>()

  if (productSpecification) {
    productSpecification.forEach((item) => {
      return item.Id
    })
  }

  const objectDetailsSideBarAbove = {
    isInventoryModalOpen,
    isSaveProductModalOpen,
    isCreateWishlistModalOpen,
    product,
    selectedSKU,
    setIsInventoryModalOpen,
    setIsSaveProductModalOpen,
    setWasList,
    setIsCreateWishlistModalOpen,
    createWishlist,
  }

  const objectProductDetailsHandler = {
    isValidating,
    priceData,
    isProductAvailable,
    sku,
    clusterHighlights,
    similars,
    hasVariantValue,
    breadcrumbs,
    hasVariant,
    product,
    setAddQuantity,
    availableItemsValue,
    orderFormId,
    items,
    wasList,
    setWasList,
    buyProps,
    removeProductFromWishlist,
    setIsSaveProductModalOpen,
    setIsInventoryModalOpen,
    addQuantity,
    setIsAssemblePersonlize,
    showButtonAssembleYourBracelet,
    selectedSKU,
    setSelectedSkuBracelet,
    seller,
    setGivexAttachment,
  }

  return (
    <>
      <DetailsSideBarAbove {...objectDetailsSideBarAbove} />
      <section className="product-details__right-section grid-content grid-section product-details__size">
        <ProductTitleSection
          name={name}
          colecao={colecao}
          brand={brand}
          listPrice={listPrice}
          isProductAvailable={isProductAvailable}
          lowPrice={lowPrice}
          price={price}
          id={id}
          productSpecificationGroups={product?.specificationGroups}
          clusterHighlights={clusterHighlights}
        />
        <ProductDetailsHandler {...objectProductDetailsHandler} />
        {isProductAvailable && (
          <ComparedProduct
            product={product}
            setShowProductBar={setShowProductBar}
          />
        )}
        {!isWindowBiggerThanTablet && buyTogetherProducts.length > 0 && (
          <BuyTogether
            product={product}
            buyTogetherProducts={buyTogetherProducts}
          />
        )}
        {!isGiftCard && <ProductBenefits />}
        <Description
          description={product.description}
          compositions={isVariantOf.additionalProperty}
          idProduct={isVariantOf.productGroupID}
          setProductSpecification={setProductSpecification}
          categoriesIds={product?.categoriesIds}
        />
        <SustantabilityMessage breadcrumbList={product?.breadcrumbList} />
      </section>
    </>
  )
}

export default DetailsSideBar
