import React, { useCallback, useEffect, useState } from 'react'
import ProductTitle from 'src/components/ui/ProductTitle'
import { DiscountBadge } from 'src/components/ui/Badge'

import type { SpecificationGroup } from './types/SpecificationGroup'

export interface ProductTitleProps {
  name: string
  id?: string
  listPrice?: number
  isProductAvailable?: boolean
  lowPrice?: number
  price?: number
  brand: {
    name: string
  }
  colecao?: {
    propertyID: string
    name: string
    value: string
    valueReference: string
  }
  productSpecificationGroups: SpecificationGroup[] | null
  clusterHighlights?: Array<{
    id: string | null
    name: string | null
  }> | null
}

const ProductTitleSection = ({
  name,
  listPrice,
  isProductAvailable,
  lowPrice,
  price,
  brand,
  colecao,
  productSpecificationGroups,
  clusterHighlights,
}: ProductTitleProps) => {
  const ParseSlug = (slug: string) => {
    if (!slug) {
      return false
    }

    return slug
      .toLocaleLowerCase()
      .replaceAll(' ', '-')
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
  }

  const [complementName, setComplementname] = useState('')

  const getComplementName = useCallback(() => {
    if (!productSpecificationGroups) {
      return
    }

    const allSpecifications = productSpecificationGroups.find(
      (specificationGroup) => specificationGroup.name === 'allSpecifications'
    )

    if (!allSpecifications?.specifications) {
      return
    }

    const productComplementName = allSpecifications.specifications.find(
      (specification) => specification?.originalName === 'complemento_nome'
    )

    const complementNameValue = productComplementName?.values?.[0] ?? ''

    setComplementname(complementNameValue)
  }, [productSpecificationGroups])

  useEffect(() => {
    if (productSpecificationGroups) {
      getComplementName()
    }
  }, [getComplementName, productSpecificationGroups])

  return (
    <header className="product-details__title">
      <ProductTitle
        title={<h1 className="title-product">{name}</h1>}
        subtitle={<h2 className="subtitle-product">{complementName}</h2>}
        categoryInfo={
          colecao !== undefined && brand.name === 'Life by Vivara' ? (
            <a
              href={`/colecao/life-${ParseSlug(colecao?.value)}`}
              className="colecaoLink"
            >
              Coleção {colecao?.value}
            </a>
          ) : (
            colecao !== undefined && (
              <a
                href={`/colecao/${ParseSlug(colecao?.value)}`}
                className="colecaoLink"
              >
                Coleção {colecao?.value}
              </a>
            )
          )
        }
        label={
          listPrice &&
          lowPrice &&
          price && (
            <DiscountBadge
              listPrice={listPrice}
              spotPrice={isProductAvailable ? lowPrice : price}
              clusterHighlights={clusterHighlights}
            />
          )
        }
      />
    </header>
  )
}

export default ProductTitleSection
