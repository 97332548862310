import React from 'react'

import { DEFAULT_MAX_ADDITIONAL_ITENS } from '../Assembly/Assembly'
import ProductCardAssemble from '../ProductCardCustomBracelet/ProductCardAssemble'
import './ProductGalleryAssemble.scss'
import type { SelectedProductsAssemble } from '../Assembly/types'

interface Props {
  selectedProductsAssembleBracelet: Array<SelectedProductsAssemble | null>
  onClickAddCardProduct: (product: SelectedProductsAssemble) => Promise<number>
  onClickRemoveProduct: (index: number) => void
  onClickAddChain: (product: SelectedProductsAssemble) => Promise<void>
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
}

export const ProductGallerySelectedAssemble = ({
  onClickAddCardProduct,
  onClickRemoveProduct,
  onClickAddChain,
  selectedProductsAssembleBracelet,
  selectedProductsAssembleBraceletChain,
}: Props) => {
  const chainDefaultIndex = DEFAULT_MAX_ADDITIONAL_ITENS + 1

  return (
    <div className="product-listing / grid-content-full">
      <div className="product-listing__content-grid / grid-content">
        <ul className="assemble-list product-grid">
          {selectedProductsAssembleBraceletChain && (
            <li key={chainDefaultIndex}>
              <ProductCardAssemble
                product={selectedProductsAssembleBraceletChain.product}
                index={chainDefaultIndex}
                variant="assemble-remove"
                onClickAddCardProduct={onClickAddCardProduct}
                onClickRemoveProduct={onClickRemoveProduct}
                onClickAddChain={onClickAddChain}
                isChain
                selectedProductsAssembleBraceletChain={
                  selectedProductsAssembleBraceletChain
                }
              />
            </li>
          )}
          {selectedProductsAssembleBracelet.map((selectedProduct, index) => {
            const validate =
              selectedProduct?.product.breadcrumbList.itemListElement.some(
                (item: { name: string }) => {
                  return item.name.toLowerCase() === 'corrente de segurança'
                }
              )

            return (
              selectedProduct && (
                <li key={index}>
                  <ProductCardAssemble
                    product={selectedProduct.product}
                    index={selectedProduct.index ?? index}
                    variant="assemble-remove"
                    onClickAddCardProduct={onClickAddCardProduct}
                    onClickRemoveProduct={onClickRemoveProduct}
                    onClickAddChain={onClickAddChain}
                    isChain={validate}
                    selectedProductsAssembleBraceletChain={
                      selectedProductsAssembleBraceletChain
                    }
                  />
                </li>
              )
            )
          })}
        </ul>
      </div>
    </div>
  )
}
