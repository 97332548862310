import React from 'react'
import KnowMoreWidthOne from 'src/images/svg/compared-product/know-more-width-1'
import KnowMoreWidthTwo from 'src/images/svg/compared-product/know-more-width-2'
import KnowMoreThicknessOne from 'src/images/svg/compared-product/know-more-thickness-1'
import KnowMoreThicknessGoldOne from 'src/images/svg/compared-product/know-more-thickness-gold-1'
import KnowMoreRingOne from 'src/images/svg/compared-product/know-more-ring-1'
import type { FunctionComponent } from 'react'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

interface IImagesModalKnowMore {
  componentName: string
  desktopSize: {
    width: string
    height: string
  }
}

interface IComponentsImages {
  [key: string]: FunctionComponent<{ width: string; height: string }>
}

function ImagesModalKnowMore({
  componentName,
  desktopSize,
}: IImagesModalKnowMore) {
  const baseClass = 'modalComparedProduct-knowMore-images__item'
  const { isMobile } = useWindowDimensions()
  const componentsImages: IComponentsImages = {
    KnowMoreWidthOne,
    KnowMoreWidthTwo,
    KnowMoreThicknessOne,
    KnowMoreThicknessGoldOne,
    KnowMoreRingOne,
  }

  const ComponentRender = componentsImages?.[componentName]

  return (
    <>
      {ComponentRender && (
        <div className={baseClass}>
          <ComponentRender
            width={!isMobile ? desktopSize?.width : ''}
            height={!isMobile ? desktopSize?.height : ''}
          />
        </div>
      )}
    </>
  )
}

export default ImagesModalKnowMore
