import { Price } from '@faststore/ui'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import Diamond from 'src/images/svg/diamond'
import React, { useEffect } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'
import useOffer from 'src/components/sections/ProductDetails/useOffer'
import type { InferredType } from 'src/components/sections/ProductDetails/useOffer'
import { Image } from 'src/components/ui/Image'

import { AssembleButtonAddToCart } from './AssembleButtonAddToCart'
import type { SelectedProductsAssemble } from '../Assembly/types'

import './assemble-resume.scss'

interface ChainProductRender {
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
}
interface ProductRenderProps {
  productMain: BrowserProductQueryQuery['product']
  selectedProductsAssembleBracelet: Array<SelectedProductsAssemble | null>
  hasChoiceChain: boolean
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
  selectedSkuBracelet: InferredType<
    BrowserProductQueryQuery['product']['isVariantOf']['hasVariant']
  >
  setHasDiscount?: Dispatch<SetStateAction<boolean>>
  isFirstClick: boolean
  setIsFirstClick: React.Dispatch<React.SetStateAction<boolean>>
  applyChainFilter: () => void
}
interface ResumeListProps {
  selectedProductsAssembleBracelet: Array<SelectedProductsAssemble | null>
}

const removeDuplicates = (arr: Array<SelectedProductsAssemble | null>) => {
  return arr?.reduce((acc, curr) => {
    if (curr) {
      const currProduct: never = curr.product as never

      if (!acc.includes(currProduct)) {
        acc.push(currProduct)
      }
    }

    return acc
  }, [])
}

export const sumTotalPrice = (
  selectedProductsAssembleBracelet: Array<SelectedProductsAssemble | null>,
  productPdpPrice: number,
  type: 'listPrice' | 'lowPrice'
) => {
  if (selectedProductsAssembleBracelet.length) {
    const sumArray: number[] = [0]

    for (const objProduct of selectedProductsAssembleBracelet) {
      if (objProduct !== null) {
        if (type === 'lowPrice') {
          const {
            product: {
              offers: { lowPrice },
            },
          } = objProduct

          sumArray.push(lowPrice)
        }

        if (type === 'listPrice') {
          const {
            product: {
              offers: { offers },
            },
          } = objProduct

          sumArray.push(offers[0]?.listPrice)
        }
      }
    }

    return sumArray.reduce((sum, i) => sum + i) + productPdpPrice
  }

  return productPdpPrice
}

const ChainResume = ({
  selectedProductsAssembleBraceletChain,
}: ChainProductRender) => {
  const {
    name,
    image: productImages,
    offers: { lowPrice, offers },
  } = selectedProductsAssembleBraceletChain?.product

  return (
    <li className="product-resume">
      <div className="product-resume-container">
        <div>
          <Image
            baseUrl={productImages[0].url}
            alt={name}
            aspectRatio={0.9}
            width={50}
            height={45}
            loading="lazy"
            objectFit="initial"
            layout="constrained"
            options={{
              fitIn: true,
            }}
          />
        </div>
        <div className="product-resume-info">
          <p className="product-resume-name">{name}</p>
          <p className="product-resume-qty">Qnt 1</p>
        </div>
        <div className="product-resume-price">
          {offers[0]?.listPrice > lowPrice && (
            <p>
              <Price
                value={offers[0]?.listPrice}
                formatter={useFormattedPrice}
                testId="list-price"
                data-value={offers[0]?.listPrice}
              />
            </p>
          )}
          <p>
            <Price
              value={offers[0]?.listPrice}
              formatter={useFormattedPrice}
              testId="low-price"
              data-value={offers[0]?.listPrice}
            />
          </p>
        </div>
      </div>
    </li>
  )
}

const ResumeList = ({ selectedProductsAssembleBracelet }: ResumeListProps) => {
  if (selectedProductsAssembleBracelet.length) {
    const noDuplicatedAssemble = removeDuplicates(
      selectedProductsAssembleBracelet
    )

    return (
      <>
        {noDuplicatedAssemble.map(
          (product: BrowserProductQueryQuery['product'], index: number) => {
            if (!product) {
              return <></>
            }

            const {
              name,
              image: productImages,
              offers: { lowPrice, offers },
              sku,
            } = product

            const quantity = selectedProductsAssembleBracelet.filter(
              (SelectedProductsAssemble: SelectedProductsAssemble | null) => {
                return SelectedProductsAssemble?.product.sku === sku
              }
            )

            return (
              <li className="product-resume" key={index}>
                <div className="product-resume-container">
                  <div>
                    <Image
                      baseUrl={productImages[0].url}
                      alt=""
                      aspectRatio={0.9}
                      width={50}
                      height={45}
                      loading="lazy"
                      objectFit="initial"
                      layout="constrained"
                      options={{
                        fitIn: true,
                      }}
                    />
                  </div>
                  <div className="product-resume-info">
                    <p className="product-resume-name">{name}</p>
                    <p className="product-resume-qty">Qnt {quantity.length}</p>
                  </div>
                  <div className="product-resume-price">
                    {offers[0]?.listPrice > lowPrice && (
                      <p>
                        <Price
                          value={offers[0].listPrice}
                          formatter={useFormattedPrice}
                          testId="list-price"
                          data-value={offers[0].listPrice}
                        />
                      </p>
                    )}
                    <p>
                      <Price
                        value={lowPrice}
                        formatter={useFormattedPrice}
                        testId="low-price"
                        data-value={lowPrice}
                      />
                    </p>
                  </div>
                </div>
              </li>
            )
          }
        )}
      </>
    )
  }

  return <></>
}

export const AssembleResume = ({
  productMain,
  selectedProductsAssembleBracelet,
  hasChoiceChain,
  selectedProductsAssembleBraceletChain,
  selectedSkuBracelet,
  setHasDiscount,
  isFirstClick,
  setIsFirstClick,
  applyChainFilter,
}: ProductRenderProps) => {
  const { width } = useWindowDimensions()

  const { lowPrice, listPrice } = useOffer(productMain, selectedSkuBracelet)

  const isNotebook = width > 965

  const { name, image } = productMain

  const totalPrice = sumTotalPrice(
    selectedProductsAssembleBracelet,
    lowPrice,
    'lowPrice'
  )

  const totalListPrice = sumTotalPrice(
    selectedProductsAssembleBracelet,
    listPrice,
    'listPrice'
  )

  const selecteds = selectedProductsAssembleBracelet.filter(
    (selected) => selected !== null
  )

  const totalSelecteds =
    selecteds.length + 1 + (selectedProductsAssembleBraceletChain ? 1 : 0)

  useEffect(() => {
    if (setHasDiscount) {
      setHasDiscount(totalPrice < totalListPrice)
    }
  }, [setHasDiscount, totalListPrice, totalPrice])

  return (
    <div className="AssembleResume">
      {isNotebook && <h1> Resumo da pulseira</h1>}
      <ul className={totalPrice < totalListPrice ? 'set-size' : ''}>
        <li
          className={`product-resume ${
            selectedProductsAssembleBracelet.length ? '' : 'no-border'
          }`}
        >
          <div className="product-resume-container">
            <div>
              <Image
                baseUrl={image[0].url}
                alt={selectedSkuBracelet.name}
                aspectRatio={0.9}
                width={50}
                height={45}
                loading="lazy"
                objectFit="initial"
                layout="constrained"
                options={{
                  fitIn: true,
                }}
              />
            </div>
            <div className="product-resume-info">
              <p className="product-resume-name">{name}</p>
              <p className="product-resume-size">
                {selectedSkuBracelet.additionalProperty?.[0].name &&
                  `Tamanho ${parseInt(
                    selectedSkuBracelet.additionalProperty?.[0].value,
                    10
                  )}`}
              </p>
            </div>
            <div className="product-resume-price">
              {listPrice > lowPrice && (
                <p>
                  <Price
                    value={listPrice}
                    formatter={useFormattedPrice}
                    testId="list-price"
                    data-value={listPrice}
                  />
                </p>
              )}
              <p>
                <Price
                  value={lowPrice}
                  formatter={useFormattedPrice}
                  testId="low-price"
                  data-value={lowPrice}
                />
              </p>
            </div>
          </div>
        </li>
        {selectedProductsAssembleBraceletChain && (
          <ChainResume
            selectedProductsAssembleBraceletChain={
              selectedProductsAssembleBraceletChain
            }
          />
        )}
        {selectedProductsAssembleBracelet.length ? (
          <ResumeList
            selectedProductsAssembleBracelet={selectedProductsAssembleBracelet}
          />
        ) : (
          <></>
        )}
        {!selecteds.length && (
          <li className="product-resume-empty">
            <>
              <div className="icon">
                <Diamond />
              </div>
              <p>Escolha seus pingentes</p>
            </>
          </li>
        )}
      </ul>
      <div className={`resume-bottom ${!selecteds.length ? 'empty-list' : ''}`}>
        <div className="resume-bottom-selected">
          <div>
            <p>SELECIONADOS ({totalSelecteds})</p>
          </div>
          <div className="resume-bottom-total">
            {totalPrice < totalListPrice && (
              <>
                <div>
                  <p className="total-text">Subtotal:</p>
                  <p className="total-price">
                    <Price
                      value={totalListPrice}
                      formatter={useFormattedPrice}
                      testId="list-price"
                      data-value={totalListPrice}
                    />
                  </p>
                </div>
                <div>
                  <p className="total-text">Desconto:</p>
                  <p className="total-price">
                    -
                    <Price
                      value={totalListPrice - totalPrice}
                      formatter={useFormattedPrice}
                      testId="list-price"
                      data-value={totalListPrice - totalPrice}
                    />
                  </p>
                </div>
              </>
            )}
            <div>
              <p className="total-text total">TOTAL:</p>
              <p className="total-price bold">
                <Price
                  value={totalPrice}
                  formatter={useFormattedPrice}
                  testId="list-price"
                  data-value={totalPrice}
                />
              </p>
            </div>
          </div>
        </div>
        <AssembleButtonAddToCart
          productMain={productMain}
          selectedProductsAssembleBracelet={selectedProductsAssembleBracelet}
          selectedSkuBracelet={selectedSkuBracelet}
          selectedProductsAssembleBraceletChain={
            selectedProductsAssembleBraceletChain
          }
          hasChoiceChain={hasChoiceChain}
          isFirstClick={isFirstClick}
          setIsFirstClick={setIsFirstClick}
          applyChainFilter={applyChainFilter}
        />
      </div>
    </div>
  )
}
