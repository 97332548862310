import React from 'react'

import BraceletImgSrc from './bracelet-icon.svg'
import SecurityChainImgSrc from './security-chain-icon.svg'

export const BraceletIcon = () => (
  <img src={BraceletImgSrc} alt="bracelet-icon" />
)

export const SecurityChainIcon = () => (
  <img src={SecurityChainImgSrc} alt="bracelet-icon" />
)
