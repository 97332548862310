import React, { useState } from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import { normalize } from 'src/utils/similars'
import { variantInStock } from 'src/components/product/ProductCard/ProductCard'
import { deepObjectCopy } from 'src/utils'

import { account } from '../../../../../../../store.config'
import { getSpecificationProduct } from '../../../utils/getSpecificationProduct'
import dataImagesJson from '../../../data/dataImages.json'

import './details-item-compared-product.scss'

interface IDetailsItemComparedProduct {
  product: BrowserProductQueryQuery['product']
  name: string
  hasImage?: boolean
  knowMore?: {
    images: string[]
  }
}

function DetailsItemComparedProduct({
  product,
  name,
  hasImage = false,
}: IDetailsItemComparedProduct) {
  const [loadImage, setLoadImage] = useState<boolean>(true)
  const baseClass = `modalComparedProduct-details-container`
  const dataImages = deepObjectCopy(dataImagesJson)

  const specificationLabel =
    name === 'espessura-aprox' || name === 'largura-aprox'
      ? getSpecificationProduct(product?.specificationGroups, name)
      : getSpecificationProduct(
          product?.specificationGroups,
          name?.split('-')?.[0]
        )

  const getPriceProductOrVariants = () => {
    const offersPrices = product?.offers?.offers?.[0]

    let price =
      offersPrices?.availability === 'https://schema.org/InStock'
        ? product?.offers?.lowPrice
        : offersPrices?.price

    if (!price) {
      const newVariantsInStock = variantInStock(
        product?.isVariantOf?.hasVariant,
        false
      )?.sort((a, b) => a?.offers?.lowPrice - b?.offers?.lowPrice)

      if (!newVariantsInStock?.length) {
        const variantsOutOfStockSort = product?.isVariantOf?.hasVariant
          ?.filter((item) => item?.offers?.offers?.[0]?.listPrice !== 0)
          ?.sort(
            (a, b) =>
              a?.offers?.offers?.[0]?.listPrice -
              b?.offers?.offers?.[0]?.listPrice
          )

        return variantsOutOfStockSort?.[0]?.offers?.offers?.[0]?.listPrice ?? ''
      }

      price =
        newVariantsInStock?.[0]?.offers?.lowPrice ??
        newVariantsInStock?.[0]?.offers?.offers?.[0]?.listPrice
    }

    return price
  }

  const treatmentSpecification = () => {
    if (name === 'largura-aprox' || name === 'espessura-aprox') {
      return specificationLabel?.[0]?.replace('Aproximadamente ', '') ?? '0'
    }

    if (name === 'pedras') {
      let stonesText = ''

      specificationLabel?.forEach((stone) => {
        stonesText += `, ${stone}`
      })

      return stonesText?.replace(', ', '') || '0'
    }

    if (name === 'values') {
      const price = getPriceProductOrVariants()

      return Intl.NumberFormat('pt-br', {
        style: 'currency',
        currency: 'BRL',
      }).format(price ?? '0')
    }

    return specificationLabel?.[0] ?? '0'
  }

  const valueSpec = treatmentSpecification()

  return (
    <>
      {hasImage && specificationLabel?.[0] && (
        <div className={`${baseClass}-items__image`}>
          <img
            alt={product?.image?.[0]?.alternateName || product?.name}
            src={`https://${account}.vtexassets.com/arquivos/${
              dataImages?.[name]
            }__${normalize(specificationLabel?.[0])}.png`}
            onError={(e) => {
              const target = (e?.target as HTMLElement)
                ?.parentNode as HTMLElement

              if (target) {
                target.style.display = 'none'
                setLoadImage(false)
              }
            }}
          />
        </div>
      )}
      <div className={`${baseClass}-items__name ${name}`}>
        <p
          className={`
          ${
            !hasImage ||
            (hasImage && !loadImage) ||
            (hasImage && !specificationLabel?.[0])
              ? 'notImage'
              : ''
          }
          ${baseClass}-items__name__text
        `}
        >
          {valueSpec}
        </p>
      </div>
    </>
  )
}

export default DetailsItemComparedProduct
