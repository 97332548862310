import React from 'react'
import { JsonLd } from 'gatsby-plugin-next-seo'
import type { ServerProductPageQueryQuery } from '@generated/graphql'

interface IImagesSchemaProduct {
  productImages: ServerProductPageQueryQuery['product']['image']
}

export const ImagesSchemaProduct = ({
  productImages,
}: IImagesSchemaProduct) => {
  return (
    <JsonLd
      json={{
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: productImages?.map((image, index) => {
          return {
            '@type': 'ListItem',
            position: index + 1,
            item: {
              '@type': 'ImageObject',
              contentUrl: image?.url,
              url: image?.url,
              name: image?.alternateName,
              description: image?.alternateName,
            },
          }
        }),
      }}
    />
  )
}
