import './product-details.scss'

import axios from 'axios'
import React, { useCallback, useEffect, useState } from 'react'
import { get } from 'idb-keyval'
import { useWishlistContext } from 'src/contexts/WishlistContext/wishlist-context'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import useReleaseDate from 'src/hooks/useReleaseDate'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { useKitLookContext } from 'src/contexts/kit-look'
import type { Product as ProductKitLook } from 'src/components/product/ProductKitLook/types'
import { validateVivaraYou } from 'src/components/product/ProductCard/tagsCollection'
import dataTagsCollection from 'src/components/product/ProductCard/data/dataTagsCollection.json'

import ProductDetailsReturn from './ProductDetailsReturn'

interface Props {
  product: BrowserProductQueryQuery['product']
}

type GetAvailableItemsData = {
  url: string
  params: Record<string, unknown>
}

export type ArrayRequest = {
  items: Array<{
    videos: Array<{
      videoUrl: string
    }>
    estimatedDateArrival: null
  }>
}

type Collections = Array<{
  id: string | null
  name: string | null
}>

interface FormattedBreadcrumbProps {
  item: string
  name: string
  position: number
}

function ProductDetails({ product }: Props) {
  const [isSaveProductModalOpen, setIsSaveProductModalOpen] = useState(false)
  const [isCreateWishlistModalOpen, setIsCreateWishlistModalOpen] =
    useState(false)

  const [collectionId, setCollectionId] = useState<string>()
  const [visible, setVisible] = useState(false)
  const [productInfo, setProductInfo] = useState<ArrayRequest>()
  const [renderVivaraYou, setRenderVivaraYou] = useState(false)

  const hasPolarizedLens = product?.isVariantOf?.additionalProperty.some(
    (property) => property.name === 'polarized_lens'
  )

  const { isMobile } = useWindowDimensions()
  const width = isMobile ? 365 : 864
  const height = isMobile ? 369.39 : 520
  const [formattedBreadcrumb, setFormattedBreadcrumb] =
    useState<FormattedBreadcrumbProps[]>()

  const { createWishlist } = useWishlistContext()
  const { sku, image: productImages, releaseDate } = product

  const getAvailableItems = async ({ url, params }: GetAvailableItemsData) => {
    const { skuId, regionId } = params

    await axios
      .get(url, {
        params: {
          skuId,
          regionId,
        },
      })
      .then((response) => {
        setVisible(true)
        setProductInfo(response?.data)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const { clusterHighlights } = product

  const handleProductCollections = useCallback(() => {
    if (!clusterHighlights) {
      return
    }

    const collectionFound = clusterHighlights.find((cluster) => {
      if (cluster.id) {
        return dataTagsCollection?.tags.find((tag) => tag === cluster.id)
      }

      return undefined
    })

    setCollectionId(collectionFound?.id ?? '')
  }, [clusterHighlights])

  const filterBreadcrumb = useCallback(() => {
    if (!product?.breadcrumbList.itemListElement) {
      return
    }

    const breadcrumbList = product.breadcrumbList?.itemListElement

    let indexSlice = -1

    let currentCount = 0

    breadcrumbList.forEach((breadcrumbItem, index) => {
      const breadcrumbItemPaths = breadcrumbItem.item.split('/')

      const lastPosition = breadcrumbItemPaths.pop()

      if (breadcrumbItemPaths.length <= currentCount && lastPosition !== 'p') {
        indexSlice = index
      }

      currentCount = breadcrumbItemPaths.length
    })

    if (indexSlice !== -1) {
      setFormattedBreadcrumb(breadcrumbList.slice(indexSlice))
    } else {
      setFormattedBreadcrumb(breadcrumbList)
    }
  }, [product.breadcrumbList])

  const getAvailableItemsWithRegion = useCallback(async () => {
    const session = await get('fs::session')
    const regionId = session?.channel
      ? JSON.parse(session?.channel)?.regionId
      : ''

    await getAvailableItems({
      url: '/api/getAvailableItemsIo',
      params: { skuId: sku, regionId },
    })
  }, [sku])

  useEffect(() => {
    setRenderVivaraYou(false)
    if (sku) {
      getAvailableItemsWithRegion()
    }

    filterBreadcrumb()
    handleProductCollections()
  }, [
    getAvailableItemsWithRegion,
    sku,
    filterBreadcrumb,
    handleProductCollections,
  ])

  const handleVivaraYou = (collectionsCurrent: Collections) => {
    const collectionVivaraYou = collectionsCurrent.some(
      (item) => item?.id === '2295'
    )

    if (!collectionVivaraYou) {
      return setRenderVivaraYou(false)
    }

    return validateVivaraYou(
      product?.isVariantOf?.productGroupID,
      setRenderVivaraYou
    )
  }

  useEffect(() => {
    if (!clusterHighlights) {
      return setRenderVivaraYou(false)
    }

    handleVivaraYou(clusterHighlights)

    return () => setCollectionId(undefined)
  }, [clusterHighlights])

  const isNewRelease = useReleaseDate(releaseDate)

  const [viewBreadcrumb, setViewBreadcrumb] = useState(false)

  useEffect(() => {
    if (formattedBreadcrumb) {
      setViewBreadcrumb(true)
    }
  }, [formattedBreadcrumb])
  const { products: productsKitLook } = useKitLookContext()

  const imagesKit = [
    ...productImages,
    ...productsKitLook?.map((item: ProductKitLook) => item?.image?.[0]),
  ]

  const images = productsKitLook.length ? imagesKit : productImages

  return (
    <ProductDetailsReturn
      viewBreadcrumb={viewBreadcrumb}
      formattedBreadcrumb={formattedBreadcrumb}
      collectionId={collectionId}
      isNewRelease={isNewRelease}
      productInfo={productInfo}
      hasPolarizedLens={hasPolarizedLens}
      images={images}
      isSaveProductModalOpen={isSaveProductModalOpen}
      product={product}
      setIsSaveProductModalOpen={setIsSaveProductModalOpen}
      visible={visible}
      isCreateWishlistModalOpen={isCreateWishlistModalOpen}
      setIsCreateWishlistModalOpen={setIsCreateWishlistModalOpen}
      createWishlist={createWishlist}
      width={width}
      height={height}
      renderVivaraYou={renderVivaraYou}
      clusterHighlights={clusterHighlights}
    />
  )
}

export default ProductDetails
