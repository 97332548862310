import React from 'react'

const SustentabilityIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <g clipPath="url(#clip0_13594_40317)">
      <path
        d="M6.6875 11.875C6.6875 17.0445 10.8931 21.25 16.0625 21.25C21.232 21.25 25.4375 17.0445 25.4375 11.875C25.4375 6.70557 21.232 2.5 16.0625 2.5C10.8931 2.5 6.6875 6.70557 6.6875 11.875ZM16.0625 3.75C20.5427 3.75 24.1875 7.39478 24.1875 11.875C24.1875 16.3552 20.5427 20 16.0625 20C11.5823 20 7.9375 16.3552 7.9375 11.875C7.9375 7.39478 11.5823 3.75 16.0625 3.75Z"
        fill="#12794B"
      />
      <path
        d="M10.2744 10.7607L12.697 13.1477L12.1396 16.5025C12.1008 16.7361 12.1973 16.9715 12.3889 17.1106C12.5803 17.2498 12.834 17.2686 13.0442 17.1597L16.0625 15.5923L19.0806 17.1597C19.2891 17.2678 19.5427 17.251 19.7358 17.1106C19.9275 16.9715 20.0239 16.7361 19.9851 16.5025L19.4277 13.1477L21.8503 10.7607C22.019 10.5947 22.0794 10.3477 22.0061 10.1223C21.9329 9.89722 21.7388 9.73292 21.5046 9.69752L18.1426 9.19141L16.6213 6.14917C16.5156 5.9375 16.2991 5.80371 16.0625 5.80371C15.8257 5.80371 15.6094 5.9375 15.5034 6.14917L13.9822 9.19141L10.6201 9.69752C10.386 9.73267 10.1919 9.89722 10.1186 10.1223C10.0454 10.3477 10.1059 10.5947 10.2744 10.7607ZM14.4892 10.3792C14.6904 10.3489 14.8643 10.2227 14.9553 10.0408L16.0625 7.82617L17.1697 10.0408C17.2607 10.2227 17.4346 10.3489 17.6357 10.3792L20.0828 10.7476L18.3193 12.4846C18.1746 12.6275 18.1082 12.8318 18.1414 13.0325L18.5471 15.4741L16.3503 14.3335C16.2602 14.2866 16.1614 14.2632 16.0625 14.2632C15.9636 14.2632 15.8647 14.2866 15.7744 14.3335L13.5776 15.4741L13.9834 13.0325C14.0168 12.8318 13.9504 12.6275 13.8054 12.4846L12.0422 10.7476L14.4892 10.3792Z"
        fill="#12794B"
      />
      <path
        d="M16.6875 0.625001C16.6875 0.970217 16.4077 1.25 16.0625 1.25C15.7173 1.25 15.4375 0.970217 15.4375 0.625001C15.4375 0.279786 15.7173 0 16.0625 0C16.4077 0 16.6875 0.279786 16.6875 0.625001Z"
        fill="#12794B"
      />
      <path
        d="M13.117 0.368232C7.85938 1.71028 4.1875 6.44197 4.1875 11.8748C4.1875 15.2491 5.6062 18.4286 8.09669 20.6803L4.3313 27.3163C4.21021 27.5299 4.22534 27.7945 4.36987 27.9928C4.5144 28.1913 4.76148 28.2867 5.00195 28.2367L9.27808 27.3487L10.781 31.5839C10.8626 31.8143 11.0713 31.9757 11.315 31.9974C11.5586 32.0192 11.7922 31.8964 11.9131 31.6837L16.0625 24.3888L20.2117 31.6837C20.3235 31.8802 20.5315 31.9996 20.7547 31.9996C20.773 31.9996 20.7915 31.9989 20.8098 31.9974C21.0535 31.9757 21.2622 31.8141 21.344 31.5839L22.8467 27.3487L27.1228 28.2367C27.3631 28.2865 27.6104 28.191 27.7549 27.9928C27.8997 27.7945 27.9146 27.5299 27.7935 27.3163L24.0281 20.6803C26.5186 18.4286 27.9373 15.2491 27.9373 11.8748C27.9373 6.44197 24.2654 1.71028 19.0081 0.368232C18.6734 0.282783 18.3333 0.484687 18.2478 0.819161C18.1624 1.15363 18.3643 1.49397 18.6988 1.57942C23.4024 2.7801 26.6875 7.01375 26.6875 11.8748C26.6875 17.7911 21.8826 22.4999 16.0625 22.4999C10.2383 22.4999 5.4375 17.7872 5.4375 11.8748C5.4375 7.01375 8.72242 2.7801 13.426 1.57942C13.7605 1.49397 13.9624 1.15363 13.8772 0.819161C13.7918 0.484687 13.4517 0.282783 13.117 0.368232ZM11.5022 29.8783L10.2739 26.4171C10.1694 26.1227 9.86378 25.9505 9.55787 26.014L6.1001 26.732L9.08009 21.4801C10.8176 22.7433 12.875 23.5128 15.0144 23.7032L11.5022 29.8783ZM22.5669 26.014C22.2613 25.9505 21.9553 26.1227 21.8509 26.4171L20.6228 29.8783L17.1104 23.7032C19.25 23.5128 21.3072 22.7433 23.045 21.4801L26.0249 26.732L22.5669 26.014Z"
        fill="#12794B"
      />
    </g>
    <defs>
      <clipPath id="clip0_13594_40317">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
)

export default SustentabilityIcon
