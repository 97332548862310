import React from 'react'
import { SorterInternalComponent } from 'src/components/search/Sort/SorterInternalComponent'
import { Funnel as FilterIcon } from 'phosphor-react'
import Button from 'src/components/ui/Button'
import type { SearchSort } from '@faststore/sdk/dist/types'
import './styles.scss'

interface CustomFilterMobileButtonProps {
  isFilterOpen: boolean
  setIsFilterOpen: React.Dispatch<React.SetStateAction<boolean>>
  sort: SearchSort
  setSort: React.Dispatch<React.SetStateAction<SearchSort>>
  isGalleryEmpty?: boolean
}

export const CustomFilterMobileButton = ({
  isFilterOpen,
  setIsFilterOpen,
  sort,
  setSort,
  isGalleryEmpty = false,
}: CustomFilterMobileButtonProps) => {
  return (
    <div className="product-listing__wrapper">
      <div className="product-listing__sort">
        <Button
          variant="tertiary"
          data-testid="open-filter-button"
          icon={<FilterIcon size={24} />}
          iconPosition="left"
          aria-label="Abrir filtros"
          onClick={() => setIsFilterOpen(!isFilterOpen)}
        >
          FILTRAR
        </Button>
        {!isGalleryEmpty && (
          <SorterInternalComponent
            setSortProp={{
              __type: 'facets',
              setSort,
            }}
            sort={sort}
          />
        )}
      </div>
    </div>
  )
}
