import axios from 'axios'

export const getProductKitLook = async (productSlug: string) => {
  const response = await axios.get('/api/getProductKit', {
    params: {
      productSlug,
    },
  })

  if (response?.data?.items?.[0]?.kitItems?.length) {
    response.data.isKit = true

    return response?.data
  }

  return false
}
