import React, { memo, useEffect, useMemo, useState } from 'react'
import type { Dispatch } from 'react'
import type {
  BrowserProductQueryQuery,
  ProductSummary_ProductFragment,
} from '@generated/graphql'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'
import Price from 'src/components/ui/Price'
import { Installment } from 'src/components/ui/Installment'
import BuyButton from 'src/components/ui/BuyButton'
import { addProductOnReducer } from 'src/components/sections/CollectionCarousel/Event/AddProducts'
import { useInView } from 'react-intersection-observer'
import ReleaseTag from 'src/components/common/ReleaseTag/ReleaseTag'

import type { SelectedProductsAssemble } from '../Assembly/types'
import { ProductCardChecked } from './ProductCardAssembleChecked'
import { ProductCardAssembleConfirmRemoval } from './ProductCardAssembleConfirmRemoval'
import { ProductCardAssembleAdd } from './ProductCardAssembleAdd'
import { ProductCardAssembleRemove } from './ProductCardAssembleRemove'
import { NotificationModal } from '../NotificationModal/NotificationModal'

import './product-card.scss'

export const imgOptions = {
  sourceWidth: 1024,
  layout: 'constrained' as const,
  loading: 'lazy' as const,
  sizes: '(max-width: 768px) 25vw, 30vw',
  breakpoints: [360, 480, 720, 1024],
}

type VariantType = 'assemble' | 'assemble-remove'

export interface LinkPropProps {
  to: string
  onClick: () => void
  'data-testid': string
}

interface HandlePriceProps {
  price: number
  spotPrice: number
  listPrice: number
  shelfClass: string
  isProductAvailable: boolean
  isVivaraYou?: boolean
  collectionId?: {
    id: string | null
    name: string | null
  }
}

const renderTagVivaraYou = ({
  isVivaraYou,
  collectionId,
  shelfClass,
  isProductAvailable,
  price,
  spotPrice,
}: Omit<HandlePriceProps, 'listPrice'>) =>
  isVivaraYou && collectionId?.name ? (
    <ReleaseTag
      variant="product-card-vivara-you"
      name={`${collectionId?.name}`}
    />
  ) : (
    <span className={`${shelfClass}__installments`}>
      <Installment
        value={isProductAvailable ? spotPrice : price}
        formatter={useFormattedPrice}
      />
    </span>
  )

export const HandlePrice = ({
  spotPrice,
  listPrice,
  isProductAvailable,
  price,
  shelfClass,
  isVivaraYou,
  collectionId,
}: HandlePriceProps) => {
  return (
    <>
      {price === 0 ? (
        <BuyButton disabled data-card-buy-button="true">
          AVISE-ME
        </BuyButton>
      ) : (
        <>
          <span className={`${shelfClass}__value-before`}>
            {listPrice !== (isProductAvailable ? spotPrice : price) && (
              <Price
                value={listPrice}
                formatter={useFormattedPrice}
                testId="price"
                data-value={listPrice}
                variant="listing"
                classes="text-body"
                SRText="Sale Price:"
              />
            )}
          </span>
          <span className={`${shelfClass}__value`}>
            <Price
              value={isProductAvailable ? spotPrice : price}
              formatter={useFormattedPrice}
              testId="price"
              data-value={isProductAvailable ? spotPrice : price}
              variant="spot"
              classes="text-body"
              SRText="Sale Price:"
            />
          </span>
        </>
      )}
      {renderTagVivaraYou({
        isVivaraYou,
        collectionId,
        shelfClass,
        isProductAvailable,
        price,
        spotPrice,
      })}
    </>
  )
}

function ProductCardAssemble({
  product,
  index,
  aspectRatio = 1,
  variant,
  dispatcher,
  productsDataToEvent,
  onClickAddCardProduct,
  onClickRemoveProduct,
  shownIcon = false,
  isChain,
  onClickAddChain,
  hasChainSelected = false,
  selectedProductsAssembleBraceletChain,
}: ProductCardAssembleProps) {
  const { inView } = useInView()
  const [showNotificationModal, setShowNotificationModal] = useState(false)
  const [addButton, setIsAddButton] = useState(false)
  const [selected, setIsSelected] = useState(false)
  const [canvaIndex, setCanvaIndex] = useState<number>(-1)
  const [showConfirmRemoval, setShowConfirmRemoval] = useState(false)
  const MAX_ADDITIONAL_ITENS_MESSAGE = `Ops! Você já adicionou 11 pingentes! Para trocar, você precisará
  deletar um que já escolheu.`

  const NOTIFICATION_MODAL_PRIMATY_BUTTON_TEXT = 'Ok, entendi'

  const shelfClass = `shelf-product-card-${variant}`

  const productInfo: SelectedProductsAssemble = {
    imageCanva: '',
    index: null,
    product,
  }

  useEffect(() => {
    addProductOnReducer(productsDataToEvent, inView, product, dispatcher)
  }, [dispatcher, inView, product, productsDataToEvent])

  const {
    offers: { lowPrice: spotPrice, offers },
  } = product

  const selectedOffer = useMemo(() => {
    const lowestPriceOffer = offers.findIndex((x) => x?.price === spotPrice)

    if (lowestPriceOffer === -1) {
      console.error(
        'Could not find the lowest price product offer. Showing the first offer provided.'
      )

      return 0
    }

    return lowestPriceOffer
  }, [spotPrice, offers])

  const modalNotificationCallback = () => {
    setShowNotificationModal(false)
  }

  return (
    <>
      <NotificationModal
        isOpen={showNotificationModal}
        closeModal={modalNotificationCallback}
        message={MAX_ADDITIONAL_ITENS_MESSAGE}
        primaryButtonText={NOTIFICATION_MODAL_PRIMATY_BUTTON_TEXT}
        primaryButtonCallback={modalNotificationCallback}
      />
      {showConfirmRemoval && (
        <ProductCardAssembleConfirmRemoval
          shelfClass={shelfClass}
          aspectRatio={aspectRatio}
          product={product}
          setShowConfirmRemoval={setShowConfirmRemoval}
          onClickRemoveProduct={onClickRemoveProduct}
          index={index}
        />
      )}
      {selected && (
        <ProductCardChecked
          productInfo={productInfo}
          shelfClass={`${shelfClass} bordered`}
          setIsAddButton={setIsAddButton}
          setIsSelected={setIsSelected}
          onClickRemoveProduct={onClickRemoveProduct}
          canvaIndex={canvaIndex}
        />
      )}
      {!selected && !showConfirmRemoval && (
        <>
          {variant === 'assemble' ? (
            <ProductCardAssembleAdd
              setShowNotificationModal={setShowNotificationModal}
              product={product}
              shownIcon={shownIcon}
              addButton={addButton}
              shelfClass={shelfClass}
              aspectRatio={aspectRatio}
              selectedOffer={selectedOffer}
              setIsSelected={setIsSelected}
              setIsAddButton={setIsAddButton}
              setCanvaIndex={setCanvaIndex}
              onClickAddCardProduct={onClickAddCardProduct}
              isChain={isChain}
              onClickAddChain={onClickAddChain}
              hasChainSelected={hasChainSelected}
              selectedProductsAssembleBraceletChain={
                selectedProductsAssembleBraceletChain
              }
            />
          ) : (
            <>
              <ProductCardAssembleRemove
                product={product}
                shelfClass={shelfClass}
                aspectRatio={aspectRatio}
                setShowConfirmRemoval={setShowConfirmRemoval}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

export default memo(ProductCardAssemble)

export type ClusterHighlights = {
  clusterHighlights:
    | Array<{ id: string | null; name: string | null }>
    | null
    | undefined
}

export interface ProductCardAssembleProps {
  product: ProductSummary_ProductFragment & ClusterHighlights
  index: number
  bordered?: boolean
  outOfStock?: boolean
  aspectRatio?: number
  variant?: VariantType
  dispatcher?: Dispatch<{
    type: string
    payload:
      | ProductSummary_ProductFragment
      | BrowserProductQueryQuery['product']
  }>
  productsDataToEvent?: ProductSummary_ProductFragment[]
  onClickAddCardProduct: (product: SelectedProductsAssemble) => Promise<number>
  onClickRemoveProduct: (index: number) => void
  onClickAddChain: (product: SelectedProductsAssemble) => Promise<void>
  shownIcon?: boolean
  isChain: boolean
  hasChoiceChain?: boolean
  hasChainSelected?: boolean
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
}
