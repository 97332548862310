interface PixelPopUpExitProps {
  setGetOutOfWindow: React.Dispatch<
    React.SetStateAction<{
      href: string
      innerText?: string
    }>
  >
  setShowExitModal: React.Dispatch<React.SetStateAction<boolean>>
  isMobile: boolean | null
}

interface FowardNavigationProps {
  getOutOfWindow: {
    href: string
    innerText?: string
  }
  setIsModal: React.Dispatch<React.SetStateAction<boolean>>
}

interface AddBtnListener {
  button: HTMLButtonElement
  type: ButtonType
  setGetOutOfWindow: React.Dispatch<
    React.SetStateAction<{
      href: string
      innerText?: string
    }>
  >
  setShowExitModal: React.Dispatch<React.SetStateAction<boolean>>
}

type AnchorElement = {
  as: string
}
type ButtonType = 'search' | 'store' | 'footer' | 'menu'

const FORWARD_BUTTON_FUNCTION = 'foward-button'
const ELEMENT_HAS_LISTENER = 'has-listener'

const addBtnListener = ({
  button,
  type,
  setGetOutOfWindow,
  setShowExitModal,
}: AddBtnListener) => {
  if (button && button.slot === '') {
    button.addEventListener('click', (ev: MouseEvent) => {
      ev.preventDefault()
      const btnElement = ev?.currentTarget as HTMLButtonElement

      if (btnElement.slot !== FORWARD_BUTTON_FUNCTION) {
        setGetOutOfWindow({ href: type, innerText: button.innerText })
        setShowExitModal(true)
      }
    })
    button.slot = ELEMENT_HAS_LISTENER
  }
}

const menuMobileButton = (
  setGetOutOfWindow: React.Dispatch<
    React.SetStateAction<{
      href: string
      innerText?: string
    }>
  >,
  setShowExitModal: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const btnMobileMenu = document.querySelector(
    '#menuMobileLifeButton'
  ) as HTMLButtonElement

  addBtnListener({
    button: btnMobileMenu,
    type: 'menu',
    setGetOutOfWindow,
    setShowExitModal,
  })
}

export function pixelPopUpExit({
  setGetOutOfWindow,
  setShowExitModal,
  isMobile,
}: PixelPopUpExitProps) {
  const getOutWindowListener = (ev: MouseEvent) => {
    ev.preventDefault()
    const anchor = ev?.currentTarget as HTMLAnchorElement & AnchorElement

    if (!anchor) {
      return
    }

    anchor.as = 'style'
    anchor.rel = 'stylesheet preload prefetch'
    setGetOutOfWindow({ href: anchor?.href ?? '' })
    setShowExitModal(true)
  }

  const addAnchortListenerEvent = (anchorsList: HTMLAnchorElement[]) =>
    anchorsList.forEach((element: HTMLAnchorElement) => {
      if (element?.slot !== ELEMENT_HAS_LISTENER) {
        element.addEventListener('click', getOutWindowListener)
        element.slot = ELEMENT_HAS_LISTENER
      }
    })

  const btnSearchStore = document.querySelector(
    '#searchStoreButton'
  ) as HTMLButtonElement

  const footerMobileButton = () => {
    const btnFooterMobileList: HTMLButtonElement[] = Array.from(
      document.querySelectorAll('button.menu-container')
    )

    btnFooterMobileList.forEach((button) =>
      addBtnListener({
        button,
        type: 'footer',
        setGetOutOfWindow,
        setShowExitModal,
      })
    )
  }

  const anchors: HTMLAnchorElement[] = Array.from(
    document.querySelectorAll('a')
  )

  const desktopObserver = () => {
    const anchorMutationObserver = new MutationObserver(() => {
      const elementList = document.documentElement
        .getElementsByTagName('REACH-PORTAL')[0]
        ?.querySelectorAll('a')

      if (elementList) {
        const anchorsNav: HTMLAnchorElement[] = Array.from(elementList)

        addAnchortListenerEvent(anchorsNav)
      }
    })

    anchorMutationObserver.observe(
      document.documentElement.getElementsByTagName('body')[0],
      {
        childList: true,
      }
    )
  }

  const btnMutationObserver = new MutationObserver(() => {
    const btnFindStore = document.querySelector(
      '#findStoreButton:not(.btnDisabled)'
    ) as HTMLButtonElement

    addBtnListener({
      button: btnFindStore,
      type: 'store',
      setGetOutOfWindow,
      setShowExitModal,
    })
  })

  const buttonObserved =
    document.documentElement.querySelectorAll('#findStoreButton')?.[0]

  if (buttonObserved) {
    btnMutationObserver.observe(buttonObserved, {
      childList: false,
      attributes: true,
    })
  }

  addBtnListener({
    button: btnSearchStore,
    type: 'search',
    setGetOutOfWindow,
    setShowExitModal,
  })

  addAnchortListenerEvent(anchors)

  if (isMobile) {
    footerMobileButton()
    menuMobileButton(setGetOutOfWindow, setShowExitModal)
  } else {
    desktopObserver()
  }
}

export function fowardNavigation({
  getOutOfWindow,
  setIsModal,
}: FowardNavigationProps) {
  if (
    getOutOfWindow.href === 'menu' ||
    getOutOfWindow.href === 'footer' ||
    getOutOfWindow.href === 'store' ||
    getOutOfWindow.href === 'search'
  ) {
    fowardButton(getOutOfWindow.href as ButtonType, getOutOfWindow.innerText)
  } else {
    window.location.replace(getOutOfWindow.href)
  }

  setIsModal(false)
}

export function redirectButtonFunction(
  element: HTMLButtonElement | null,
  executeFunction: () => void
) {
  if (element?.slot === '' || element?.slot === FORWARD_BUTTON_FUNCTION) {
    executeFunction()
  }
}

function fowardButton(btnType: ButtonType, btnInnerText?: string) {
  let btn: HTMLButtonElement | null = null

  if (btnType === 'footer') {
    document
      .querySelectorAll('button.menu-container')
      .forEach((btnFooterElement: Element) => {
        const btnFooter = btnFooterElement as HTMLButtonElement

        if (btnFooter.innerText === btnInnerText) {
          btn = btnFooter
        }
      })
  } else {
    const elementSelector =
      btnType === 'store'
        ? '#findStoreButton'
        : btnType === 'search'
        ? '#searchStoreButton'
        : '#menuMobileLifeButton'

    btn = document.querySelector(elementSelector) as HTMLButtonElement
  }

  if (btn) {
    btn.slot = FORWARD_BUTTON_FUNCTION
    btn.click()
  }
}
