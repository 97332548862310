import React from 'react'
import type {
  BrowserProductQueryQuery,
  SkuFragmentFragment,
} from '@generated/graphql'
import {
  useSelectedSKU,
  useSkuContext,
} from 'src/contexts/ProductContext/useProductContext'

import SkuSelector from '.'

interface SkuSelectorProductProps {
  defaultSku: string
  variants: SkuFragmentFragment[]
  categoryTree: Array<{
    item: string
    name: string
    position: number
  }>
  product: BrowserProductQueryQuery['product']
  setAlertProductByRegion?: React.Dispatch<React.SetStateAction<boolean>>
}

export const SkuSelectorProduct = ({
  categoryTree,
  variants,
  defaultSku,
  product,
  setAlertProductByRegion,
}: SkuSelectorProductProps) => {
  const { setSelectedSKU } = useSkuContext()
  const selectedSKU = useSelectedSKU(product)

  return (
    <SkuSelector
      defaultSku={defaultSku}
      variants={variants}
      categoryTree={categoryTree}
      setSelectedSKU={setSelectedSKU}
      selectedSKU={selectedSKU}
      setAlertProductByRegion={setAlertProductByRegion}
    />
  )
}
