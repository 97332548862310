import axios from 'axios'

const getShippingPolicySpecification = async (id: string) => {
  const variables = {
    id,
  }

  try {
    const { data: response } = await axios.post(
      '/api/shippingPolicySpecification',
      variables,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )

    return response
  } catch (error) {
    console.error(error)

    return null
  }
}

export default getShippingPolicySpecification
