import React from 'react'
import RingType1 from 'src/images/compared-product/ringType1.png'
import RingType2 from 'src/images/compared-product/ringType2.png'
import RingType3 from 'src/images/compared-product/ringType3.png'
import RingType4 from 'src/images/compared-product/ringType4.png'
import RingType5 from 'src/images/compared-product/ringType5.png'
import RingType6 from 'src/images/compared-product/ringType6.png'

interface IImagesModalKnowMore {
  image: string
  title: string
}

interface IComponentsImages {
  [key: string]: string
}

function SubSectionImagesModalKnowMore({ image, title }: IImagesModalKnowMore) {
  const baseClass = 'modalComparedProduct-knowMore-subsection__images-item'
  const images: IComponentsImages = {
    RingType1,
    RingType2,
    RingType3,
    RingType4,
    RingType5,
    RingType6,
  }

  const imageToRender = images?.[image]

  return (
    <>
      {imageToRender && (
        <div className={baseClass}>
          <img src={imageToRender} alt={title} />
          <div className="modalComparedProduct-knowMore-subsection__images-item-divider" />
          <span className="modalComparedProduct-knowMore-subsection__images-item-title">
            {title}
          </span>
        </div>
      )}
    </>
  )
}

export default SubSectionImagesModalKnowMore
