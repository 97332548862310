import React from 'react'
import LineIcon from 'src/components/icons/Line'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import stepOneMeasureYourFingerImg from '../../../../images/measure-guide/stepOneMeasureYourFinger.png'
import stepTwoMeasureYourFingerImg from '../../../../images/measure-guide/stepTwoMeasureYourFinger.png'
import stepThreeMeasureYourFingerImg from '../../../../images/measure-guide/stepThreeMeasureYourFinger.png'
import ringsVivara from './ringsVivara.json'

import './measure-your-finger.scss'

const steps = [
  {
    title: 'passo 1',
    subtitle:
      'Marque com a linha a circunferência do dedo no qual você usará o  anel.',
    img: stepOneMeasureYourFingerImg,
    alt: 'Step One Measure Your Finger',
  },
  {
    title: 'passo 2',
    subtitle:
      'Certifique-se de que a linha está confortável para você e marque o encontro da linha com a sua ponta.',
    img: stepTwoMeasureYourFingerImg,
    alt: 'Step Two Measure Your Finger',
  },
  {
    title: 'passo 3',
    subtitle:
      'Desenrole a linha com a marcação e meça o comprimento com a régua.',
    img: stepThreeMeasureYourFingerImg,
    alt: 'Step Three Measure Your Finger',
  },
]

interface MeasureYourFingerProps {
  isLife: boolean
}

const formatCentimeter = (centimeter: number) => {
  if (centimeter % 1 === 0) {
    return centimeter?.toFixed(1)?.toString()?.replace('.', ',')
  }

  return centimeter?.toString()?.replace('.', ',')
}

const MeasureYourFinger = ({ isLife }: MeasureYourFingerProps) => {
  const { width } = useWindowDimensions()
  const ringsData = isLife
    ? ringsVivara?.filter((item) => item.ring % 2 === 0)
    : ringsVivara

  const marginStep = (index: number) =>
    width > 1024
      ? index !== 0
        ? { marginTop: '48px' }
        : { marginTop: '17px' }
      : { marginTop: '24px' }

  return (
    <div className="measureYourFinger">
      <div className="measureYourFinger__content">
        <p className="measureYourFinger__title">
          Encontre a circunferência, a medida da base do seu dedo, e descubra o
          aro ideal.
        </p>
        <h3>Você vai precisar de:</h3>
        <ul>
          <li>Pedaço de linha ou barbante;</li>
          <li>Caneta;</li>
          <li>Régua;</li>
        </ul>

        {steps.map((step, index) => (
          <div
            key={step.title}
            className="measureYourFinger__step"
            style={marginStep(index)}
          >
            <div className="measureYourFinger__step-content">
              <h4>{step.title}</h4>
              <p>{step.subtitle}</p>
            </div>
            <img
              className="measureYourFinger__step-image"
              src={step.img}
              alt={step.alt}
            />
          </div>
        ))}
      </div>
      <p className="measureYourFinger__info">
        Os centímetros apontados na régua indicam o seu aro de acordo com a
        tabela a seguir.
      </p>
      <div
        className={`measureYourFinger__centimeterAndRing ${
          isLife ? 'centimeterAndRingLife' : ''
        }`}
      >
        {ringsData.map((ringItem) => (
          <div
            key={ringItem.ring}
            className="measureYourFinger__centimeterAndRing-item"
          >
            <p className="measureYourFinger__centimeterAndRing-centimeter">
              {formatCentimeter(ringItem.centimeter)} cm
            </p>{' '}
            <LineIcon />
            <p className="measureYourFinger__centimeterAndRing-ring">
              {ringItem.ring}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default MeasureYourFinger
