import React, { memo } from 'react'
import { useDiscountPercent } from 'src/sdk/product/useDiscountPercent'
import { getCookie } from 'src/utils/cookie'

import Badge from './Badge'

import './badge.scss'

type Props = {
  listPrice: number
  spotPrice: number
  small?: boolean
  // Set limit percentage value to consider a low discount.
  thresholdLow?: number
  // Set limit percentage value to consider a high discount
  thresholdHigh?: number
  clusterHighlights?: Array<{
    id: string | null
    name: string | null
  }> | null
}

const DiscountBadge = ({
  listPrice,
  spotPrice,
  small = false,
  thresholdLow = 15,
  thresholdHigh = 40,
  clusterHighlights,
}: Props) => {
  const discountPercent = Math.round(
    Number(useDiscountPercent(listPrice, spotPrice))
  )

  const utmCampaign = getCookie('utm_vivara')
  const isBF = utmCampaign === 'bfvip'
  const hasBfCluster = clusterHighlights?.some((cluster) =>
    cluster.name?.toLowerCase().includes('black friday')
  )

  if (isBF && hasBfCluster) {
    return <></>
  }

  if (!discountPercent) {
    return <></>
  }

  if (discountPercent === 100) {
    return <></>
  }

  if (discountPercent < 0) {
    return <></>
  }

  const discountVariant =
    discountPercent <= thresholdLow
      ? 'low'
      : discountPercent <= thresholdHigh
      ? 'medium'
      : 'high'

  return (
    <div className="flex-space-between">
      <Badge small={small} data-fs-discount-badge-variant={discountVariant}>
        {discountPercent}% off
      </Badge>
    </div>
  )
}

export default memo(DiscountBadge)
