import React, { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { SearchSort, State } from '@faststore/sdk/dist/types'
import { useCustomGalleryQuery } from 'src/components/sections/ProductGallery/useGalleryQuery'
import type { IStoreSelectedFacet } from '@faststore/api'
import type {
  Filter_FacetsFragment,
  ProductSummary_ProductFragment,
} from '@generated/graphql'
import ProductGridSkeleton from 'src/components/skeletons/ProductGridSkeleton'
import { useTotalCount } from 'src/components/sections/ProductGallery/useTotalCount'

import type { SelectedProductsAssemble } from '../Assembly/types'
import type { ClusterHighlights } from '../ProductCardCustomBracelet/ProductCardAssemble'
import ProductCardAssemble from '../ProductCardCustomBracelet/ProductCardAssemble'

interface ProductGalleryPageAssembleProps {
  page: number
  totalPages: number
  selectedFacets: IStoreSelectedFacet[]
  isLayoutList: boolean
  onClickRemoveProduct: (index: number) => void
  setLoadingPages: Dispatch<SetStateAction<boolean>>
  setTotalCount: Dispatch<SetStateAction<number | undefined>>
  setFilteredFacets: Dispatch<SetStateAction<Filter_FacetsFragment[]>>
  onClickAddCardProduct: (product: SelectedProductsAssemble) => Promise<number>
  onClickAddChain: (product: SelectedProductsAssemble) => Promise<void>
  checkItemIsSelected: (
    product: ProductSummary_ProductFragment & ClusterHighlights
  ) => boolean
  sort: SearchSort
  hasChoiceChain: boolean
  hasChainSelected: boolean
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
}

export const ProductGalleryPageAssemble = ({
  page,
  totalPages,
  selectedFacets,
  isLayoutList,
  setTotalCount,
  onClickAddCardProduct,
  onClickRemoveProduct,
  setFilteredFacets,
  setLoadingPages,
  checkItemIsSelected,
  sort,
  onClickAddChain,
  hasChoiceChain,
  hasChainSelected,
  selectedProductsAssembleBraceletChain,
}: ProductGalleryPageAssembleProps) => {
  const [isLoading, setIsLoading] = useState(true)
  const state: State = {
    base: '',
    page,
    selectedFacets,
    term: '',
    sort,
  }

  const { data, error } = useCustomGalleryQuery({
    state,
    itemsPerPage: 16,
  })

  const totalCount = useTotalCount(data)

  useEffect(() => {
    if (data?.search?.facets) {
      setFilteredFacets(data?.search?.facets)
    }

    if (data?.search?.products?.edges || error) {
      setIsLoading(false)
    } else {
      setIsLoading(true)
      setLoadingPages(false)
    }

    if (totalCount) {
      setTotalCount(totalCount)
    }

    if (data?.search?.products?.pageInfo?.hasNextPage) {
      if (page === totalPages - 1) {
        setLoadingPages(true)
      }
    } else if (
      data?.search?.products?.pageInfo?.hasNextPage !== undefined &&
      !data?.search?.products?.pageInfo?.hasNextPage
    ) {
      setLoadingPages(false)
    }
  }, [
    data,
    error,
    totalCount,
    page,
    totalPages,
    setTotalCount,
    setFilteredFacets,
    setLoadingPages,
  ])

  return (
    <>
      <ProductGridSkeleton loading={isLoading}>
        <ul
          className={`assemble-list ${
            isLayoutList ? 'list-card' : 'product-grid'
          }`}
        >
          {data?.search?.products?.edges.map((product, index) => {
            const shownIcon = checkItemIsSelected(product.node)

            const validate = product.node.breadcrumbList.itemListElement.some(
              (item: { name: string }) => {
                return item.name.toLowerCase().includes('corrente de segurança')
              }
            )

            return (
              <li
                className="assemble-list-item"
                key={`${product.node.name}-${index}`}
              >
                <ProductCardAssemble
                  shownIcon={shownIcon}
                  product={product.node}
                  index={index}
                  variant="assemble"
                  onClickAddCardProduct={onClickAddCardProduct}
                  onClickRemoveProduct={onClickRemoveProduct}
                  isChain={validate}
                  onClickAddChain={onClickAddChain}
                  hasChoiceChain={hasChoiceChain}
                  hasChainSelected={hasChainSelected}
                  selectedProductsAssembleBraceletChain={
                    selectedProductsAssembleBraceletChain
                  }
                />
              </li>
            )
          })}
        </ul>
      </ProductGridSkeleton>
    </>
  )
}
