import React, { useCallback, useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import { get } from 'idb-keyval'
import BaseModal from 'src/components/modal/BaseModal'

import './styles.scss'

import { InventoryFormStep } from './InventoryFormStep'
import { InventoryResult } from './InventoryResultStep'
import { InventoryNotFoundStep } from './InventoryNotFoundStep'
import { InventoryStoreDetailsStep } from './InventoryStoreDetailsStep'

interface InventoryProps {
  id: string
  isInventoryModalOpen: boolean
  setIsInventoryModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

export interface Store {
  bairro: string
  estado: string
  cidade: string
  loja: string
  logradouro: string
  telefone: string
  obs: string
  whatsapp: string
}

interface ListPickupPointDockIdProps {
  [id: string]: {
    dockId: string
    pickupDistance: number
  }
}

export type InventoryFormModalStep =
  | 'form'
  | 'results'
  | 'not-found'
  | 'store-details'

const INVENTORY_MODAL_TITLES = {
  form: 'Encontre a loja mais próxima com este item',
  results: 'Resultados da Consulta',
  'not-found': 'Resultados da Consulta',
  'store-details': 'Detalhes da loja',
  loading: 'Resultados da Consulta',
}

function InventoryFormModal({
  id,
  isInventoryModalOpen,
  setIsInventoryModalOpen,
}: InventoryProps) {
  const [postalCode, setPostalCode] = useState('')
  const [pickupInPoints, setPickupInPoints] = useState<
    IPickupPointsInventory[]
  >([])

  const handleClose = () => setIsInventoryModalOpen(false)
  const [step, setStep] = useState<InventoryFormModalStep>('form')
  const [isLoading, setIsLoading] = useState(false)
  const [seletectedStore, setSeletectedStore] = useState<Store>()
  const [selectedPickupPoint, setSelectedPickupPoint] =
    useState<IPickupPointsInventory>()

  const data = useMemo(
    () => ({
      items: [
        {
          id,
          quantity: 1,
          seller: '1',
        },
      ],
      country: 'BRA',
      postalCode,
    }),
    [id, postalCode]
  )

  const getPickupPoints = useCallback(
    async (currentPostalCode?: string) => {
      setIsLoading(true)

      if (currentPostalCode) {
        data.postalCode = currentPostalCode
      }

      await axios({
        url: '/api/simulation',
        method: 'post',
        headers: {
          Accept: 'application/vnd.vtex.ds.v10+json',
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        data,
      })
        .then((response) => {
          const listPickupPointDockId: ListPickupPointDockIdProps = {}

          for (const item of response.data.logisticsInfo[0].slas) {
            if (item.pickupPointId) {
              listPickupPointDockId[item.pickupPointId] = {
                dockId: item.deliveryIds[0].dockId,
                pickupDistance: item.pickupDistance,
              }
            }
          }

          const pickupPoints = response?.data?.pickupPoints?.filter(
            (item: { id: string }) =>
              listPickupPointDockId[item.id].dockId !== 'clickcolletion'
          )

          const availablePickupPoints: IPickupPointsInventory[] =
            pickupPoints.map((item: IPickupPoints) => {
              return {
                ...item,
                pickupDistance: listPickupPointDockId[item.id].pickupDistance,
              }
            })

          setPickupInPoints(availablePickupPoints)
          if (availablePickupPoints?.length) {
            setStep('results')
          } else {
            setStep('not-found')
          }
        })
        .catch(() => {
          setStep('not-found')
          setIsLoading(false)
        })
        .finally(() => {
          setIsLoading(false)
        })
    },
    [data]
  )

  const getStoreDetails = async (storeId: string) => {
    setIsLoading(true)
    setStep('store-details')

    try {
      const response = await axios.post(
        `/api/getSearchStore`,
        {
          storeId,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        }
      )

      if (!response.data) {
        setStep('form')

        return
      }

      const [store] = response.data

      setSeletectedStore(store)
    } catch (error) {
      console.error(error)
      setStep('form')
    } finally {
      setIsLoading(false)
    }
  }

  const getRegionalization = useCallback(async () => {
    const session = await get('fs::session')
    const sessionPostalCode = session?.postalCode

    if (sessionPostalCode) {
      setPostalCode(sessionPostalCode)
      getPickupPoints(sessionPostalCode)
    }
  }, [getPickupPoints])

  useEffect(() => {
    document.body.classList.add('no-scroll')
    getRegionalization()

    return () => {
      document.body.classList.remove('no-scroll')
    }
  }, [])

  return (
    <BaseModal
      className="inventory-modal"
      isOpen={isInventoryModalOpen}
      onCloseButtonClick={handleClose}
    >
      <div className="inventory-modal__main-container">
        <span className="inventory-modal__title">
          {INVENTORY_MODAL_TITLES[step]}
        </span>
        {step === 'form' && (
          <InventoryFormStep
            postalCode={postalCode}
            setPostalCode={setPostalCode}
            getPickupPoints={getPickupPoints}
            isLoading={isLoading}
          />
        )}

        {step === 'results' && (
          <InventoryResult
            pickupInPoints={pickupInPoints}
            setStep={setStep}
            postalCode={postalCode}
            getStoreDetails={getStoreDetails}
            setSelectedPickupPoint={setSelectedPickupPoint}
          />
        )}

        {step === 'not-found' && (
          <InventoryNotFoundStep setStep={setStep} postalCode={postalCode} />
        )}

        {step === 'store-details' && (
          <InventoryStoreDetailsStep
            store={seletectedStore}
            isLoading={isLoading}
            setStep={setStep}
            selectedPickupPoint={selectedPickupPoint}
          />
        )}
      </div>
    </BaseModal>
  )
}

export default InventoryFormModal
