import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Price from 'src/components/ui/Price'
import { Skeleton } from '@acctglobal/skeleton'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'
import { Installment } from 'src/components/ui/Installment'
import { getCookie } from 'src/utils/cookie'

interface HandlePriceProps {
  lowPrice: number
  listPrice: number
  productId: string
  isKitLook?: boolean
  shouldShowinstallment?: boolean
}

type PaymentType = {
  priceFinal: number
  listPrice: number
}

const Payment = ({ priceFinal, listPrice }: PaymentType) => {
  return (
    <Installment
      formatter={useFormattedPrice}
      value={priceFinal === 0 ? listPrice : priceFinal}
    />
  )
}

function ValidateUTMPrice({
  lowPrice,
  listPrice,
  productId,
  isKitLook,
  shouldShowinstallment,
}: HandlePriceProps) {
  const [priceFinal, setPriceFinal] = useState<number | null>(null)
  const [loadingPrice, setloadingPrice] = useState<boolean | null>(false)

  const showInstallment = shouldShowinstallment ?? false
  const mainPrice = listPrice > lowPrice ? listPrice : lowPrice

  const transformUTMPrice = async (utmCampaign: string) => {
    setloadingPrice(true)
    const data = {
      items: [
        {
          id: productId,
          quantity: 1,
          seller: '1',
        },
      ],
      country: 'BRA',
      marketingData: {
        utmCampaign,
      },
    }

    try {
      await axios.post('/api/simulation', data).then((response) => {
        const sellingPrice = response?.data?.items?.[0]?.sellingPrice

        setPriceFinal(sellingPrice ? sellingPrice / 100 : lowPrice)
        setloadingPrice(false)
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    const utmCampaign = getCookie('utm_vivara')

    utmCampaign && !isKitLook
      ? transformUTMPrice(utmCampaign)
      : setPriceFinal(lowPrice)
  }, [productId, lowPrice])

  if (!priceFinal || loadingPrice) {
    return (
      <Skeleton
        width={325}
        height={isKitLook ? 20 : 50}
        backgroundColor="#F4F4F4"
      />
    )
  }

  if (mainPrice === priceFinal) {
    return (
      <>
        <Price
          value={priceFinal}
          formatter={useFormattedPrice}
          testId="price"
          data-value={listPrice}
          variant="spot"
          classes="title-display"
          SRText="Original price:"
        />
        {showInstallment ? (
          <Payment priceFinal={priceFinal} listPrice={listPrice} />
        ) : null}
      </>
    )
  }

  return (
    <>
      <Price
        value={mainPrice}
        formatter={useFormattedPrice}
        testId="list-price"
        data-value={mainPrice}
        variant="listing"
        classes="text-body-tiny"
        SRText="Original price:"
      />
      <Price
        value={priceFinal}
        formatter={useFormattedPrice}
        testId="price"
        data-value={priceFinal}
        variant="spot"
        classes="title-display"
        SRText="Sale Price:"
      />
      {showInstallment ? (
        <Payment priceFinal={priceFinal} listPrice={mainPrice} />
      ) : null}
    </>
  )
}

export default ValidateUTMPrice
