import React, { useState } from 'react'
import type { ReactNode } from 'react'
import LeftArrowBlack from 'src/images/svg/icon-arrow-left-black'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import './expandable.scss'

interface ExpandableProps {
  title: string
  children: ReactNode
  lineAfter?: boolean
}

const Expandable = ({ title, children, lineAfter }: ExpandableProps) => {
  const [expanded, setExpanded] = useState(true)
  const { isMobile } = useWindowDimensions()

  const toggleExpanded = () => {
    setExpanded(!expanded)
  }

  return (
    <div className="expandable">
      <div className="expandable-container">
        <div className="expandable-header">
          <h3 className="expandable-header_title">{title}</h3>
          <LeftArrowBlack
            width="10"
            height="15"
            onClick={toggleExpanded}
            className={expanded ? 'open' : 'closed'}
          />
        </div>
      </div>
      {expanded && (
        <>
          {(!lineAfter || isMobile) && <div className="expandable-line" />}
          {children}
        </>
      )}
      {lineAfter && !isMobile && <div className="expandable-line" />}
    </div>
  )
}

export default Expandable
