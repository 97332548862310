import React from 'react'
import './buttons.scss'
import DoubleArrowUp from 'src/components/icons/DoubleArrowUp'

const ButtonGoTop = () => {
  const handleGoTop = () => {
    const targetElement = document.querySelector('body')

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' })
    }
  }

  return (
    <div className="button-action-top__content">
      <button
        className="button-action-top"
        id="action-button-go-top"
        onClick={handleGoTop}
      >
        <DoubleArrowUp />
      </button>
      <label className="label-action-top" htmlFor="action-button-go-top">
        Voltar ao Topo
      </label>
    </div>
  )
}

export default ButtonGoTop
