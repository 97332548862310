import React, { useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import BaseModal from 'src/components/modal/BaseModal'
import Button from 'src/components/ui/VivaraButton'
import { Button as ButtonSecondary } from '@faststore/ui'
import Spinner from 'src/components/common/Spinner'
import { BraceletIcon } from 'src/images/assemble-your-bracelet'

import './AssembleModals.scss'

interface AssemblyFinishModalProps {
  isOpen: boolean
  setShowModal: Dispatch<SetStateAction<boolean>>
  sendToCart: (redirectTo: 'checkout' | 'life-bracelets') => void
}

export const AssemblyFinishModal = ({
  isOpen,
  setShowModal,
  sendToCart,
}: AssemblyFinishModalProps) => {
  const [disabledButtons, setDisabledButtons] = useState<boolean>(false)
  const [redirectTo, setRedirectTo] = useState<'checkout' | 'life-bracelets'>()

  const handleClick = (page: 'checkout' | 'life-bracelets'): void => {
    setDisabledButtons(true)
    setRedirectTo(page)

    sendToCart(page)
  }

  const closeModal = (): void => {
    if (disabledButtons) {
      return
    }

    setShowModal(false)
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onCloseButtonClick={closeModal}
      className="assemble-modal"
    >
      <div className="assemble-modal__container">
        <div className="assemble-modal__icon">
          <BraceletIcon />
        </div>
        <div className="assemble-modal__message">
          A montagem da sua pulseira personalizada foi um sucesso!
        </div>
        <Button
          className="assemble-modal__button-primary"
          onClick={disabledButtons ? undefined : () => handleClick('checkout')}
        >
          {disabledButtons && redirectTo === 'checkout' ? (
            <Spinner />
          ) : (
            'adicionar à sacola'
          )}
        </Button>
        <ButtonSecondary
          className="assemble-modal__button-secondary"
          onClick={
            disabledButtons ? undefined : () => handleClick('life-bracelets')
          }
          disabled={disabledButtons}
        >
          {disabledButtons && redirectTo === 'life-bracelets' ? (
            <Spinner />
          ) : (
            'adicionar à sacola e montar outra pulseira'
          )}
        </ButtonSecondary>
      </div>
    </BaseModal>
  )
}
