import React from 'react'

const Edit = () => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    transform="matrix(6.123233995736766e-17,1,-1,6.123233995736766e-17,0,0)"
    aria-label="Edit Icon"
  >
    <path
      d="M10.084 3.666H3.666A1.834 1.834 0 0 0 1.833 5.5v12.834a1.834 1.834 0 0 0 1.834 1.833H16.5a1.834 1.834 0 0 0 1.834 -1.833v-6.417"
      stroke="#F08769"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.222222222222222"
    />
    <path
      d="M16.958 2.292a1.945 1.945 0 1 1 2.75 2.75L11 13.75l-3.666 0.916 0.916 -3.666 8.708 -8.708Z"
      stroke="#F08769"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.222222222222222"
    />
  </svg>
)

export default Edit
