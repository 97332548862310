import React from 'react'

type NotResultSearchProps = {
  className?: string
}

const NotResultSearch = ({ className = '' }: NotResultSearchProps) => (
  <svg
    className={className}
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle opacity="0.2" cx="36" cy="36" r="36" fill="#FFA687" />
    <g clipPath="url(#clip0_11328_33642)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.5063 20.4321C41.978 20.3102 41.4508 20.639 41.3286 21.1673C41.2063 21.6957 41.5358 22.2232 42.0641 22.3455C44.5066 22.91 46.7392 24.1501 48.5199 25.9308C53.7551 31.166 53.7551 39.6851 48.5199 44.9204C43.2846 50.156 34.7655 50.156 29.5303 44.9204C24.2947 39.6851 24.2947 31.166 29.5303 25.9308C31.0334 24.4277 32.8122 23.3314 34.8175 22.6716C35.3323 22.5024 35.6126 21.9475 35.4435 21.4323C35.2739 20.917 34.7186 20.6367 34.2038 20.8063C31.9036 21.5626 29.8641 22.8196 28.1418 24.5423C22.1406 30.5435 22.1406 40.308 28.1418 46.3093C31.1422 49.3097 35.0835 50.8101 39.0253 50.8101C42.9666 50.8097 46.908 49.3097 49.9088 46.3093C55.9096 40.308 55.9096 30.5435 49.9088 24.5423C47.8673 22.5012 45.308 21.0797 42.5063 20.4321Z"
        fill="#F08769"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.0626 56.995L56.7728 46.7052C62.0492 38.431 60.8937 27.5606 53.892 20.5585C49.921 16.5875 44.6412 14.4004 39.0252 14.4004C33.4089 14.4004 28.1291 16.5875 24.1581 20.5585C20.1868 24.5294 18 29.8093 18 35.4256C18 41.0416 20.1868 46.3214 24.1581 50.2924C28.1291 54.2633 33.4085 56.4501 39.0245 56.4501C43.055 56.4501 46.9375 55.3196 50.3048 53.1732L60.5946 63.463C61.4555 64.3243 62.6041 64.7984 63.8288 64.7984C65.0531 64.7984 66.2017 64.3243 67.063 63.463C68.8456 61.6796 68.8456 58.7784 67.0626 56.995ZM65.6741 62.0745C65.1838 62.5644 64.5286 62.8343 63.8288 62.8343C63.1286 62.8343 62.4733 62.5644 61.9831 62.0745L51.125 51.2164C50.935 51.026 50.6835 50.9288 50.4305 50.9288C50.236 50.9288 50.0406 50.986 49.871 51.1037C46.6807 53.3166 42.9297 54.4863 39.0245 54.4863C33.933 54.4863 29.1465 52.5037 25.5466 48.9039C18.1146 41.4718 18.1146 29.379 25.5466 21.947C29.1469 18.3467 33.9334 16.3641 39.0252 16.3641C44.1167 16.3641 48.9032 18.3467 52.5035 21.947C59.028 28.4719 59.9536 38.7017 54.7033 46.2714C54.4326 46.6617 54.4799 47.1893 54.816 47.5254L65.6741 58.3835C66.6915 59.4009 66.6915 61.0567 65.6741 62.0745Z"
        fill="#F08769"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.9526 29.498C44.5693 29.1146 43.9479 29.1146 43.5641 29.498L39.0252 34.0369L34.486 29.498C34.1026 29.1146 33.4808 29.1146 33.0975 29.498C32.7141 29.8813 32.7141 30.5031 33.0975 30.8865L37.6364 35.4258L33.0975 39.9646C32.7141 40.348 32.7141 40.9698 33.0975 41.3532C33.289 41.545 33.5404 41.6408 33.7915 41.6408C34.043 41.6408 34.2941 41.545 34.486 41.3532L39.0249 36.8143L43.5637 41.3532C43.7556 41.545 44.0071 41.6408 44.2582 41.6408C44.5097 41.6408 44.7608 41.545 44.9526 41.3532C45.336 40.9698 45.336 40.348 44.9526 39.9646L40.4134 35.4258L44.9526 30.8865C45.336 30.5031 45.336 29.8813 44.9526 29.498Z"
        fill="#F08769"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.3743 22.0126C38.9149 22.0126 39.356 21.5715 39.356 21.0305C39.356 20.4899 38.9149 20.0488 38.3743 20.0488C37.8333 20.0488 37.3922 20.4899 37.3922 21.0305C37.3922 21.5715 37.8333 22.0126 38.3743 22.0126Z"
        fill="#F08769"
      />
    </g>
    <path d="M10.8 23.7603L0 23.7603" stroke="#F08769" strokeWidth="2" />
    <path
      d="M15.0339 15.3781L7.51147 7.10352"
      stroke="#F08769"
      strokeWidth="2"
    />
    <path d="M23.76 11.52V0" stroke="#F08769" strokeWidth="2" />
    <defs>
      <clipPath id="clip0_11328_33642">
        <rect
          width="50.4"
          height="50.4"
          fill="white"
          transform="translate(18 14.4004)"
        />
      </clipPath>
    </defs>
  </svg>
)

export default NotResultSearch
