import React from 'react'
import { Button } from '@faststore/ui'
import { useUI } from 'src/sdk/ui/Provider'
import { useSession } from 'src/sdk/session'
import type { CartItem } from 'src/sdk/cart'
import {
  createCartItem,
  handleAddToCart,
} from 'src/components/AssembleYourBracelet/AssembleResume/HandleMinicart'
import type { BrowserProductQueryQuery } from '@generated/graphql'

interface AddToCartProps {
  products: Array<BrowserProductQueryQuery['product']>
  itemsCart: CartItem[]
  cartId: string
  totalPrice: number
}

const AddToCart = ({
  products,
  itemsCart,
  cartId,
  totalPrice,
}: AddToCartProps) => {
  const { openCart } = useUI()
  const {
    currency: { code },
  } = useSession()

  const productCart: CartItem[] = products?.map((item) => createCartItem(item))

  const handleClick = () => {
    handleAddToCart({
      products: productCart,
      code,
      totalPrice,
      cartId,
      itemsCart,
    }).then(() => openCart())
  }

  return (
    <div className="AddToCart">
      <Button
        onClick={products?.length ? handleClick : undefined}
        id={products?.length ? 'btn-add-to-cart' : 'btn-add-to-cart-disabled'}
      >
        adicionar os dois à sacola
      </Button>
    </div>
  )
}

export default AddToCart
