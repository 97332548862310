import getShippingPolicySpecification from './getShippingPolicySpecification'

const TIME_LIMIT_IN_MINUTES = 960 // 16 hours in minutes

const handleTimeShippingPolicy = async (
  currentDayOfWeek: number,
  courierId: string
): Promise<number | null> => {
  try {
    const shippingPolicySpecification = (await getShippingPolicySpecification(
      courierId
    )) as ShippingPolicy

    const todaysTimeLimit =
      shippingPolicySpecification?.shippingHoursSettings?.shippingHours?.find(
        (dayConfigTime) => dayConfigTime.dayOfWeek === currentDayOfWeek
      )

    const [hour, minutes] = todaysTimeLimit?.closingTime.split(':') as string[]

    if (hour && minutes) {
      return Number(hour) * 60 + Number(minutes)
    }
  } catch (error) {
    console.error(error)
  }

  return null
}

export const getTimeLeftToDeliveryToday = async (
  courierId?: string | null
): Promise<TimeLeftForDeliveryToday | null> => {
  try {
    const currentDate = new Date()
    const currentHourInMinutes =
      currentDate.getHours() * 60 + currentDate.getMinutes()

    const currentDayOfWeek = currentDate.getDay()

    let todaysTimeLimitInMinutes = courierId
      ? await handleTimeShippingPolicy(currentDayOfWeek, courierId)
      : null

    if (!todaysTimeLimitInMinutes) {
      todaysTimeLimitInMinutes = TIME_LIMIT_IN_MINUTES
    }

    const timeLeftInMinutes = todaysTimeLimitInMinutes - currentHourInMinutes

    const hours = Math.floor(timeLeftInMinutes / 60)
    const minutes = timeLeftInMinutes % 60

    return hours <= 0 && minutes <= 0 ? null : { hours, minutes }
  } catch (error) {
    console.error(error)
  }

  return null
}
