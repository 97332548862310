import React from 'react'
import type { FC } from 'react'

const PlusSquare: FC = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Plus Square Icon"
  >
    <rect width="32" height="32" fill="black" />
    <path
      d="M22.1666 13.2895H14.7101V5.83301H13.2898V13.2895H5.83331V14.7098H13.2898V22.1663H14.7101V14.7098H22.1666V13.2895Z"
      fill="white"
    />
  </svg>
)

export default PlusSquare
