import React from 'react'

import './styles.scss'

interface Props {
  BuyButtonComponent: React.ElementType<
    React.ButtonHTMLAttributes<HTMLButtonElement>
  >
  buyDisabled: boolean
  buyProps: BuyPropsTypes
  modalButtonAddText: string
}

const ProductCustomBuyButton = ({
  buyProps,
  BuyButtonComponent,
  buyDisabled,
  modalButtonAddText,
}: Props) => {
  return (
    <BuyButtonComponent
      className="modalButtonAdd"
      disabled={buyDisabled}
      {...buyProps}
    >
      SALVAR E {modalButtonAddText}
    </BuyButtonComponent>
  )
}

export default ProductCustomBuyButton
