import React from 'react'

const SecondStepText = () => {
  return (
    <p className="default-text">
      Posicione seu anel no círculo para determinar seu tamanho. Use a barra de
      ajuste para encaixá-lo até que as setas de referência toquem na borda
      interna do anel.
    </p>
  )
}

export default SecondStepText
