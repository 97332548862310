import React from 'react'
import { Select } from '@faststore/ui'
import { CaretDown as CaretDownIcon } from 'phosphor-react'

import type { Similars } from '../SimilarProducts'

const Dropdown = ({ dataSimilars }: Similars) => {
  return (
    <>
      {dataSimilars.length > 0 && (
        <div className="similarProducts__dropdown">
          <Select
            data-testid="similarProducts__dropdown"
            onChange={(e) =>
              (window.location.pathname = e?.currentTarget?.value)
            }
          >
            {dataSimilars?.map((similar) => {
              return (
                <option
                  key={similar?.link}
                  value={similar?.link}
                  className="similarProducts__dropdown-option"
                >
                  {similar?.originalValue}
                </option>
              )
            })}
          </Select>
          <CaretDownIcon size={18} weight="bold" />
        </div>
      )}
    </>
  )
}

export default Dropdown
