import React from 'react'
import type { Dispatch, SetStateAction } from 'react'
import Button from 'src/components/ui/VivaraButton/VivaraButton'
import Diamond from 'src/images/svg/diamond'

interface ProductGalleryAssembleEmptyProps {
  showSelectedAssemble: boolean
  setShowSelectedAssemble: Dispatch<SetStateAction<boolean>>
}

export const ProductGalleryAssembleEmpty = ({
  showSelectedAssemble,
  setShowSelectedAssemble,
}: ProductGalleryAssembleEmptyProps) => {
  const closeAccordion = () => {
    setShowSelectedAssemble(!showSelectedAssemble)
  }

  return (
    <div className="product-gallery-assemble-empty">
      <Diamond />
      <p className="product-gallery-assemble-empty__info">
        Você ainda não selecionou nenhum pingente.
      </p>
      <Button variant="dark" onClick={closeAccordion}>
        OK
      </Button>
    </div>
  )
}
