import React from 'react'

const FirstStepText = () => {
  return (
    <>
      <p className="info-text">
        Descubra o aro de um anel, seguindo as instruções a seguir.
      </p>
      <div className="second-text__container">
        <p className="subtitle-text">Você vai precisar de:</p>
        <ul className="list-steps">
          <li>Um anel com o tamanho desejado;</li>
          <li>
            Cartão de crédito ou semelhante (com as mesmas dimensões 85,6 x 53,9
            mm );
          </li>
        </ul>
      </div>
      <div className="third-text__container">
        <h2 className="title-text">calibragem</h2>
        <p className="info-text">
          Posicione o seu cartão de crédito na horizontal, encostando seu topo
          na <span className="green-line">linha verde.</span>
        </p>
        <p className="info-text">
          <strong>
            Ajuste o desenho do cartão guia abaixo até que ele tenha a mesma
            altura do seu cartão.
          </strong>{' '}
          O cartão pode passar a largura da tela do seu dispositivo, desde que a
          sua <strong>altura</strong> esteja encaixada entre as guias de medida.
        </p>
      </div>
      <p className="subtitle-text">Ajuste a altura do cartão guia:</p>
    </>
  )
}

export default FirstStepText
