import React, { useEffect, useState } from 'react'

import Card from './Card'

interface CardMeasureProps {
  setPpiValue: React.Dispatch<React.SetStateAction<number>>
  setIsSecondStep: React.Dispatch<React.SetStateAction<boolean>>
}

function calculateDiagonal(firstCateto: number, secondCateto: number) {
  return Math.sqrt(firstCateto ** 2 + secondCateto ** 2)
}

const CardMeasure = ({ setPpiValue, setIsSecondStep }: CardMeasureProps) => {
  const inchesPerCentimeter = 2.54
  const cardAspectRatio = 8.56 / 5.398
  const [widthCard, setWidthtCard] = useState(300)

  function calculateDevicePPI(inputValue: number) {
    const cardInches = calculateDiagonal(8.56, 5.398) / inchesPerCentimeter

    const heightPixels = inputValue / cardAspectRatio
    const widthPixels = inputValue

    const diagonalPixels = calculateDiagonal(widthPixels, heightPixels)

    setPpiValue(diagonalPixels / cardInches)
  }

  function changeSizeCard(control: string) {
    if (control === 'increase' && widthCard < window?.innerWidth * 2) {
      setWidthtCard(widthCard + 10)
    }

    if (control === 'decrease' && widthCard > 100) {
      setWidthtCard(widthCard - 10)
    }
  }

  useEffect(() => {
    calculateDevicePPI(widthCard)
  }, [widthCard])

  return (
    <>
      <div className="control-size">
        <button
          className="decrease-size"
          onClick={() => changeSizeCard('decrease')}
        >
          -
        </button>
        <input
          className="range-control"
          type="range"
          min="100"
          max={window?.innerWidth * 2}
          step="0.001"
          value={widthCard}
          onChange={(e) => setWidthtCard(parseFloat(e.target.value))}
          style={{
            background: `linear-gradient(to right, #F08769 0%, #F08769 ${
              100 * ((widthCard - 100) / (window?.innerWidth * 2 - 100))
            }%, #D2D2D2 ${
              100 * ((widthCard - 100) / (window?.innerWidth * 2 - 100))
            }%, #D2D2D2 100%)`,
          }}
        />
        <button
          className="increase-size"
          onClick={() => {
            changeSizeCard('increase')
          }}
        >
          +
        </button>
      </div>

      <div className="measure-container">
        <div className="green-line" />
        <Card width={widthCard} />
        <div className="gray-line" />
      </div>

      <button
        id="btn-find-size"
        className="next-step"
        onClick={() => setIsSecondStep(true)}
      >
        <span className="text">
          DESCOBRIR O TAMANHO
          <span className="arrow" />
        </span>
      </button>
    </>
  )
}

export default CardMeasure
