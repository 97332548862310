import React, { useState } from 'react'
import Info from 'src/components/icons/Info'
import './styles.scss'
import AlertWithMessage from 'src/components/common/AlertWithMessage'

interface Props {
  listPrice: number
  lowPrice: number
}

const GiftBackValue = (props: Props) => {
  const { listPrice, lowPrice } = props

  const [showAlertWithMessage, setShowAlertWithMessage] = useState(false)

  return (
    <div className="giftback-info">
      {showAlertWithMessage && (
        <AlertWithMessage
          text="Valor para você usar como desconto na sua próxima compra."
          viewState={setShowAlertWithMessage}
          link="/regras/entenda-gift"
        />
      )}
      <p className="giftback-info__text">
        Ganhe{' '}
        <strong className="giftback-info__text-strong">
          R$ {((lowPrice ?? listPrice) * 0.1)?.toFixed(2)?.replace('.', ',')}
        </strong>{' '}
        em <strong className="giftback-info__text-strong">GIFTBACK</strong>
      </p>
      <button
        className="giftback-info__button"
        aria-label="Informação - Regra GiftBack"
        onClick={() => setShowAlertWithMessage(!showAlertWithMessage)}
      >
        <Info />
      </button>
    </div>
  )
}

export default GiftBackValue
