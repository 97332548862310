import React from 'react'

interface CardProps {
  width: number
}

const Card = ({ width }: CardProps) => {
  return (
    <div className="figure-container">
      <div className="card-container">
        <div className="card" style={{ width: `${width}px` }}>
          <div className="stripe" />
          <div className="stripe-white" />
        </div>
      </div>
    </div>
  )
}

export default Card
