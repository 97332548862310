export function cep(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 9
  let { value } = e.currentTarget

  value = value.replace(/\D/g, '').replace(/^(\d{5})(\d)/, '$1-$2')
  e.currentTarget.value = value

  return e
}

export function date(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 10
  let { value } = e.currentTarget

  value = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d)/, '$1')
  e.currentTarget.value = value

  return e
}

export function cpf(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 14
  let { value } = e.currentTarget

  value = value
    .replace(/\D/g, '')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')
  e.currentTarget.value = value

  return e
}

export function cnpj(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 18
  let { value } = e.currentTarget

  value = value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2')
    .replace(/(\d{4})(\d{1,2})/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1')

  e.currentTarget.value = value

  return e
}

export function money(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 15
  let { value } = e.currentTarget

  // Remove todos os caracteres não numéricos, exceto ponto e vírgula
  value = value.replace(/[^0-9.,]/g, '')

  // Substitui a vírgula por ponto, se existir
  value = value.replace(',', '.')

  // Verifica se há mais de um ponto decimal e remove os subsequentes
  const parts = value.split('.')

  if (parts.length > 2) {
    value = `${parts[0]}.${parts.slice(1).join('')}`
  }

  // Formata o valor com o separador de milhar (ponto) e o separador de decimais (vírgula)
  const [parteInteira, parteDecimal] = value.split('.')

  value =
    parteInteira.replace(/\B(?=(\d{3})+(?!\d))/g, '.') +
    (parteDecimal ? `,${parteDecimal}` : '')

  e.currentTarget.value = value

  return e
}

export function tel(e: React.FormEvent<HTMLInputElement>) {
  e.currentTarget.maxLength = 15
  let { value } = e.currentTarget

  value = value
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/g, '($1) $2')
    .replace(/(\d)(\d{4})$/, '$1-$2')
  e.currentTarget.value = value

  return e
}
