import type { IOProduct } from 'src/components/sections/PlpSellerStore/types'
import { get } from 'idb-keyval'
import axios from 'axios'

import type { DataSimilars, SimilarsItem } from '../SimilarProducts'

interface Props {
  similars: DataSimilars
  similarityKey: string
  newSelectedKey: string
}

export const getNewSimilars = ({
  similars,
  similarityKey,
  newSelectedKey,
}: Props) => {
  const keys = Object.keys(similars[similarityKey])

  if (newSelectedKey.includes('selected_')) {
    return similars
  }

  const oldSelectedKey = keys.find((keyName) => keyName.includes('selected_'))

  const newOldSelectedKey = oldSelectedKey
    ? oldSelectedKey.split('selected_')[1]
    : null

  const oldSelectedKeyValue = oldSelectedKey
    ? { ...similars[similarityKey][oldSelectedKey], selected: false }
    : null

  const newSelectedKeyValue = {
    ...similars[similarityKey][newSelectedKey],
    selected: true,
  }

  const currentSimilarityValue = keys.reduce(
    (currentSimilarityNewObject: { [key: string]: SimilarsItem }, key) => {
      const similarValue = similars[similarityKey][key]

      if (similarValue?.link === newSelectedKeyValue?.link) {
        currentSimilarityNewObject[`selected_${key}`] = newSelectedKeyValue

        return currentSimilarityNewObject
      }

      if (
        oldSelectedKey &&
        newOldSelectedKey &&
        similarValue?.link === (oldSelectedKeyValue as SimilarsItem)?.link
      ) {
        currentSimilarityNewObject[newOldSelectedKey] =
          oldSelectedKeyValue as SimilarsItem

        return currentSimilarityNewObject
      }

      currentSimilarityNewObject[key] = similarValue

      return currentSimilarityNewObject
    },
    {}
  )

  return {
    ...similars,
    [similarityKey]: currentSimilarityValue,
  }
}

export function filterSimilarsByProductsRegionId(
  similars: FinalSimilarsJSON,
  productByRegionId: IOProduct[]
) {
  const newSimilars = { ...similars }
  const productGroupIdsToKeep = productByRegionId.map((obj) =>
    obj.productId &&
    obj.items?.some((item) =>
      item.sellers?.some(
        (seller) => seller.commertialOffer.AvailableQuantity > 0
      )
    )
      ? obj.productId
      : ''
  )

  for (const key in newSimilars) {
    for (const subKey in newSimilars[key]) {
      const item = newSimilars[key][subKey]

      newSimilars[key][subKey].hasStock = productGroupIdsToKeep.includes(
        item.productGroupId
      )
    }

    if (Object.keys(newSimilars[key]).length === 0) {
      delete newSimilars[key]
    }
  }

  return newSimilars
}

export function getRequestProductIdsParameter(similarJSON: FinalSimilarsJSON) {
  const productGroupIds: string[] = []

  for (const key in similarJSON) {
    for (const subKey in similarJSON[key]) {
      const item = similarJSON[key][subKey]

      if (!productGroupIds.includes(item.productGroupId)) {
        productGroupIds.push(item.productGroupId)
      }
    }
  }

  return productGroupIds.join(';')
}

export async function updateSimilarsByRegionId(
  serverSimilars: DataSimilars,
  similars: DataSimilars,
  setSimilars: React.Dispatch<DataSimilars>
) {
  if (Object.keys(serverSimilars).length === 0) {
    if (similars !== serverSimilars) {
      setSimilars(serverSimilars)
    }

    return
  }

  const session = await get('fs::session')
  const regionId = session?.channel
    ? JSON.parse(session?.channel)?.regionId
    : ''

  const requestProductIdsParameter =
    getRequestProductIdsParameter(serverSimilars)

  const { data } = await axios.get(
    `/api/getProductDataByRegionId?requestProductIdsParameter=${encodeURIComponent(
      requestProductIdsParameter
    )}&regionId=${regionId}`,
    {
      headers: {
        'Cache-Control': 'must-revalidate, max-age=10',
      },
    }
  )

  const newSimilars = filterSimilarsByProductsRegionId(serverSimilars, data)
  const hasOtherSimilarThanItself = hasOtherSimilarsThanItself(newSimilars)

  setSimilars(hasOtherSimilarThanItself ? newSimilars : {})
}

export const extractSelectedType = (
  object: Record<string, unknown>,
  type: string
) => {
  return Object.keys(object?.[type] ?? {})
    .find((key) => key?.includes('selected_'))
    ?.split('selected_')?.[1]
}

export const hasMoreThanSelected = (
  object: Record<string, unknown>,
  type: string,
  selected?: string
) => {
  return (
    selected &&
    Object.keys(object?.[type] ?? {}).some(
      (item) => !item?.includes('selected_') && item !== selected
    )
  )
}

export function hasOtherSimilarsThanItself(similars: FinalSimilarsJSON) {
  return Object.keys(similars).some((key) => {
    const selected = extractSelected(similars[key])

    return Object.keys(similars[key]).some(
      (subKey) => !subKey.includes('selected_') && subKey !== selected
    )
  })
}

function extractSelected(object: Record<string, unknown>) {
  return Object.keys(object ?? {})
    .find((subKey) => subKey.includes('selected_'))
    ?.split('selected_')[1]
}
