import React, { useLayoutEffect } from 'react'

import type { ProductsListComparedProps } from '../../ComparedProduct'
import './menu-compared-product.scss'

interface IMenuComparedProduct {
  menuSelected: string
  setMenuSelected: React.Dispatch<React.SetStateAction<string>>
  productsListCompared: ProductsListComparedProps
}

interface IMenuList {
  [key: string]: {
    label: string
    type: string
  }
}

function MenuComparedProduct({
  menuSelected,
  setMenuSelected,
  productsListCompared,
}: IMenuComparedProduct) {
  const baseClass = 'modalComparedProduct-details-menu'
  const keysMenuShow = Object.keys(productsListCompared)

  const menuList: IMenuList = {
    byVisual: { type: 'byVisual', label: 'Por visual' },
    byPrice: { type: 'byPrice', label: 'Por Faixa de preço' },
  }

  const handlerMenu = (menu: string) => {
    setMenuSelected(menu)
  }

  useLayoutEffect(() => {
    if (productsListCompared) {
      setMenuSelected(keysMenuShow?.[0])
    }
  }, [])

  return (
    <div className={baseClass}>
      <ul className={`${baseClass}-menusList`}>
        {keysMenuShow?.map((key, index) => {
          return (
            <li
              key={index}
              data-menu={menuList?.[key]?.type}
              className={`${
                menuList[key]?.type === menuSelected ? 'selected' : ''
              } ${baseClass}-menusList__item`}
            >
              <button
                id={`compared-product-menu-${menuList?.[key]?.type}`}
                className="menu-button"
                onClick={() => handlerMenu(menuList?.[key]?.type)}
              >
                {menuList?.[key]?.label}
              </button>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default MenuComparedProduct
