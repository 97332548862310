import React from 'react'
import Spinner from 'src/components/common/Spinner'
import MapPinIcon from 'src/components/icons/MapPin'
import PhoneSVG from 'src/components/common/searchStore/PhoneSVG'
import WhatsAppSVG from 'src/components/common/searchStore/WhatsAppSVG'
import { Link } from 'gatsby'

import type { InventoryFormModalStep, Store } from './InventoryFormModal'

interface InventoryStoreDetailsStepProps {
  store?: Store
  isLoading: boolean
  setStep: React.Dispatch<React.SetStateAction<InventoryFormModalStep>>
  selectedPickupPoint?: IPickupPointsInventory
}

export const InventoryStoreDetailsStep = ({
  store,
  isLoading,
  setStep,
  selectedPickupPoint,
}: InventoryStoreDetailsStepProps) => {
  return (
    <div className="inventory-modal__store-details-step">
      {isLoading ? (
        <div className="store-details-step__spinnner">
          <Spinner />
        </div>
      ) : (
        <>
          {store && selectedPickupPoint && (
            <div className="inventory-modal__card">
              <div className="inventory-modal__card-distance">
                <MapPinIcon color="#F08769" />
                <span>{`${selectedPickupPoint?.pickupDistance?.toFixed(
                  1
                )} km`}</span>
              </div>
              <div className="inventory-modal__card-details">
                <span className="inventory-modal__card-name">
                  {store?.loja}
                </span>
                <p className="inventory-modal__card-address">
                  {`${selectedPickupPoint?.address.street}, 
                  ${selectedPickupPoint?.address.number}, 
                  ${selectedPickupPoint?.address.neighborhood} - 
                  ${selectedPickupPoint?.address.city} - 
                  ${selectedPickupPoint?.address.state}`}
                </p>
                <p className="inventory-modal__card-observations">
                  {store?.obs}
                </p>
                <Link
                  to={`/loja/lojavivara${selectedPickupPoint.address.addressId.toLowerCase()}`}
                >
                  <button className="inventory-modal__card-button">
                    ver produtos desta loja
                  </button>
                </Link>

                <div className="inventory-modal__card-contact">
                  {store.telefone && (
                    <div className="inventory-modal__card-phone">
                      <PhoneSVG />
                      <span>{store.telefone}</span>
                    </div>
                  )}
                  {store.whatsapp && (
                    <button
                      onClick={() => {
                        window.open(
                          `https://wa.me/55${store.whatsapp}`,
                          '_blank'
                        )
                      }}
                      className="whatsapp-button-anchor"
                    >
                      <div className="whatsapp">
                        <div className="whatsapp-icon">
                          <WhatsAppSVG />
                        </div>
                        <span className="whatsapp-button-text">WHATSAPP</span>
                      </div>
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
          <button
            className="inventory-modal__button"
            onClick={() => {
              setStep('results')
            }}
          >
            ver todas as lojas
          </button>
        </>
      )}
    </div>
  )
}
