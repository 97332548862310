import React, { useState, useEffect, useCallback } from 'react'
import Installment from 'src/components/ui/Installment/Installment'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import type {
  HasVariantEntity,
  Offers,
} from 'src/components/sections/PlpSellerStore/types'
import { Image } from 'src/components/ui/Image'
import Slider from 'react-slick'
import sliderSettings from 'src/configs/slider-variants-buy-together'
import { sortSkuVariations } from 'src/utils/product/sortSkuVariations'

import AddToCart from './AddToCart'

import './styles.scss'

interface BuyTogetherModalProps {
  products: Array<BrowserProductQueryQuery['product']>
  productCurrentVariants: boolean
  productBuyTogetherVariants: boolean
  productRender: number
  setProductRender: React.Dispatch<React.SetStateAction<number>>
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  buyTogetherHasVariant: boolean
}

const ContentModal = ({
  products,
  productCurrentVariants,
  productBuyTogetherVariants,
  productRender,
  setProductRender,
  setIsOpen,
  buyTogetherHasVariant,
}: BuyTogetherModalProps) => {
  const [productRendermodal, setProductRenderModal] = useState<
    BrowserProductQueryQuery['product'] | null
  >(null)

  const [productSkuSelected, setProductSkuSelected] = useState<string | null>(
    null
  )

  const [productSkuSelectedDetails, setProductSkuSelectedDetails] = useState<
    BrowserProductQueryQuery | HasVariantEntity
  >()

  const [productSkuSelectedPrice, setProductSkuSelectedPrice] =
    useState<Offers>()

  const [addCartData, setAddCartData] = useState<BrowserProductQueryQuery[]>([])

  useEffect(() => {
    if (productRender === 0 && productCurrentVariants) {
      setProductRenderModal(products?.[0])
    } else if (productRender === 1 && productBuyTogetherVariants) {
      setProductRenderModal(products?.[1])
    }
  }, [productRender])

  useEffect(() => {
    const updateCartData = [...addCartData]

    if (productRender === 0) {
      updateCartData[0] = productSkuSelectedDetails as BrowserProductQueryQuery
    } else {
      updateCartData[1] = productSkuSelectedDetails as BrowserProductQueryQuery
    }

    setAddCartData(updateCartData)
  }, [productSkuSelectedDetails])

  const productVariants = productRendermodal?.isVariantOf?.hasVariant

  const handleskuSlectedDetails = useCallback(
    (sku: string) => {
      const skuDetails: any = productVariants?.find(
        (productVariant) => productVariant.sku === sku
      )

      setProductSkuSelectedDetails(skuDetails)
      setProductSkuSelectedPrice(skuDetails?.offers as Offers)
    },
    [productVariants]
  )

  const handleskuSlected = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProductSkuSelected(e?.target?.dataset?.sku ?? '')
    handleskuSlectedDetails(e?.target?.dataset?.sku ?? '')
  }

  useEffect(() => {
    if (!productRendermodal) {
      return
    }

    const activeVariations = productRendermodal.isVariantOf?.hasVariant.filter(
      (sku) =>
        sku?.offers?.offers?.[0]?.availability === 'https://schema.org/InStock'
    )

    setProductSkuSelected(activeVariations?.[0]?.sku ?? '')
    handleskuSlectedDetails(activeVariations?.[0]?.sku ?? '')
  }, [handleskuSlectedDetails, productRendermodal])

  const price = useFormattedPrice(
    productSkuSelectedPrice?.offers?.[0]?.price ?? 0
  )

  const listPrice = useFormattedPrice(
    productSkuSelectedPrice?.offers?.[0]?.listPrice ?? 0
  )

  const variants = productVariants && sortSkuVariations(productVariants)

  return (
    <>
      <h2 className="buyTogetherModal__title">
        Antes de adicionar à sua sacola, selecione o tamanho da sua joia
      </h2>
      {productRendermodal && (
        <>
          <div className="buyTogetherModal__image">
            <Image
              baseUrl={productRendermodal.image?.[0]?.url}
              alt={productRendermodal.image?.[0]?.alternateName}
              aspectRatio={1}
              width={120}
              height={120}
              imgStyle={{ objectFit: 'contain' }}
            />
          </div>
          <h3 className="buyTogetherModal__name">{productRendermodal.name}</h3>
        </>
      )}

      <div className="buyTogetherModal__variants">
        <Slider {...sliderSettings}>
          {variants?.map((variant) => (
            <button
              key={variant?.sku}
              data-sku={variant?.sku}
              className={`${
                productSkuSelected === variant?.sku ? 'active' : ''
              } buyTogetherModal__variants-skuButton`}
              onClick={(e: any) => handleskuSlected(e)}
              disabled={
                variant?.offers?.offers?.[0]?.availability !==
                'https://schema.org/InStock'
              }
            >
              {variant?.additionalProperty?.[0]?.value}
            </button>
          ))}
        </Slider>
      </div>

      <div className="buyTogetherModal__cost">
        <span className="buyTogetherModal__listPrice">
          {productSkuSelectedPrice?.offers?.[0]?.price &&
            productSkuSelectedPrice?.offers?.[0]?.listPrice &&
            productSkuSelectedPrice?.offers?.[0]?.price <
              productSkuSelectedPrice?.offers?.[0]?.listPrice && (
              <span
                data-fs-price="true"
                data-testid="price"
                data-variant="listing"
                className="price text-body"
              >
                {listPrice}
              </span>
            )}
        </span>
        <span className="buyTogetherModal__price">
          <span
            data-fs-price="true"
            data-testid="price"
            data-variant="spot"
            className="price text-body"
          >
            {price}
          </span>
        </span>
        {productSkuSelectedPrice?.offers?.[0]?.price && (
          <div className="buyTogetherModal__installments">
            <Installment
              formatter={useFormattedPrice}
              value={productSkuSelectedPrice?.offers?.[0]?.price}
              isKit={false}
            />
          </div>
        )}
      </div>
      <AddToCart
        productRender={productRender}
        setProductRender={setProductRender}
        setIsOpen={setIsOpen}
        addCartData={addCartData}
        products={products}
        buyTogetherHasVariant={buyTogetherHasVariant}
      />
    </>
  )
}

export default ContentModal
