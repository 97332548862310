import React, { useCallback, useMemo } from 'react'
import type { Specifications } from 'src/contexts/details-specification-context'

interface Props {
  productSpecifications: Specifications[]
  isPDPLife: boolean
}

const ProductDetailsExpandableContainer = ({
  productSpecifications,
  isPDPLife,
}: Props) => {
  const getCardDetails = useCallback(
    (specification: Specifications) => {
      const cardDetails = {
        urlImage: specification.url_image,
        altImage: specification.alt_image ?? '',
        description: specification.description ?? '',
      }

      if (
        isPDPLife &&
        specification?.url_image_life &&
        specification?.alt_image_life &&
        specification?.description_life
      ) {
        cardDetails.urlImage = specification.url_image_life
        cardDetails.altImage = specification.alt_image_life
        cardDetails.description = specification.description_life
      }

      return cardDetails
    },
    [isPDPLife]
  )

  const htmlDetailCards = useMemo(() => {
    return productSpecifications?.map((specification) => {
      const { urlImage, altImage, description } = getCardDetails(specification)

      return (
        <div
          className="detail productDetails-detail-subContainer"
          key={specification?.id}
        >
          {urlImage && (
            <img
              className="productDetails-detail-image"
              alt={`${altImage}-img`}
              src={urlImage}
            />
          )}
          <div className="productDetails-detail-rightCol">
            <h3 className="detail-title productDetails-detail-title">
              {specification.name}
            </h3>
            <p className="detail-content">{description}</p>
          </div>
        </div>
      )
    })
  }, [getCardDetails, productSpecifications])

  return (
    <>
      {htmlDetailCards?.length > 0 && (
        <div className="productDetails-detail-mainContainer">
          {htmlDetailCards}
        </div>
      )}
    </>
  )
}

export default ProductDetailsExpandableContainer
