import Spinner from 'src/components/common/Spinner'
import React from 'react'
import Button from 'src/components/ui/Button'
import InputMask from 'src/components/ui/InputMask'

type InventoryFormStepProps = {
  postalCode: string
  setPostalCode: React.Dispatch<React.SetStateAction<string>>
  getPickupPoints: () => void
  isLoading: boolean
}

export const InventoryFormStep = ({
  postalCode,
  setPostalCode,
  getPickupPoints,
  isLoading,
}: InventoryFormStepProps) => {
  return (
    <div className="inventory-modal__form-step">
      <span className="inventory-modal__form-step-subtitle">
        Informe seu CEP
      </span>
      <span className="inventory-modal__form-step-label">CEP</span>
      <InputMask
        mask="cep"
        type="text"
        placeholder="00000-000"
        className="inventory-modal__form-step-input"
        onChange={(event: {
          target: { value: React.SetStateAction<string> }
        }) => {
          if (!isLoading && event.target.value.length <= 9) {
            setPostalCode(event.target.value)
          }
        }}
        value={postalCode}
      />
      <a
        target="_blank"
        href="https://buscacepinter.correios.com.br/app/endereco/index.php"
        className="inventory-modal__form-step-link"
        rel="noopener noreferrer"
      >
        Não sei meu cep
      </a>
      <Button
        className="inventory-modal__button"
        type="button"
        onClick={() => getPickupPoints()}
        disabled={isLoading}
      >
        {isLoading ? <Spinner /> : 'BUSCAR LOJAS'}
      </Button>
    </div>
  )
}
