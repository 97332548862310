import React from 'react'

import SameDayLabel from './SameDayLabel'
import { ShippingInfoBeforeDate } from './ShippingInfoBeforeDate'
import ShippingPrice from './ShippingPrice'
import DockOrder from './dockOrder'
import { sortShippingEstimate } from './sortShippingEstimate'

type ShippingSimulationContainerProps = {
  slas: ShippingSimulation[]
  today: boolean
  pickupPointsApi: PickupPointsProps[] | undefined
  setIsToday: React.Dispatch<React.SetStateAction<boolean>>
}

const ShippingSimulationContainer = ({
  slas,
  today,
  pickupPointsApi,
  setIsToday,
}: ShippingSimulationContainerProps) => {
  return (
    <div className="shipping-simulator__response-container">
      {today && <SameDayLabel />}
      <ShippingInfoBeforeDate slas={slas} holidayDate="2023-12-24" />
      <>
        {slas.length ? (
          <>
            {sortShippingEstimate(slas).map(
              (sla: ISLAMinimumTypeTimeLeftForDeliveryToday, key) => {
                return (
                  <div
                    className="shipping-simulator__info-outContainer"
                    key={`${sla.id}-container`}
                  >
                    <ShippingPrice
                      key={sla.id}
                      keyProp={key}
                      sla={sla}
                      pickupPoints={pickupPointsApi}
                      setIsToday={setIsToday}
                      today={today}
                    />
                    {sla?.deliveryIds?.[0]?.dockId === 'ENCOMENDA' && (
                      <DockOrder />
                    )}
                  </div>
                )
              }
            )}
          </>
        ) : (
          <a
            target="_blank"
            href="https://buscacepinter.correios.com.br/app/endereco/index.php"
            className="shipping-simulator__link"
            rel="noopener noreferrer"
          >
            Não sei meu cep
          </a>
        )}
      </>
    </div>
  )
}

export default ShippingSimulationContainer
