import React from 'react'
import type { FC } from 'react'

interface MapPinProps {
  color?: string
}

const MapPinIcon: FC<MapPinProps> = ({ color }) => (
  <svg
    width="20"
    height="22"
    viewBox="0 0 20 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label="Map Pin Icon"
  >
    <path
      d="M17 9.43138C17 14.9216 10 19.6275 10 19.6275C10 19.6275 3 14.9216 3 9.43138C3 7.55927 3.7375 5.76383 5.05025 4.44004C6.36301 3.11625 8.14348 2.37256 10 2.37256C11.8565 2.37256 13.637 3.11625 14.9497 4.44004C16.2625 5.76383 17 7.55927 17 9.43138Z"
      stroke={color ?? '#000000'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99996 11.7843C11.2886 11.7843 12.3333 10.7308 12.3333 9.43131C12.3333 8.13182 11.2886 7.07837 9.99996 7.07837C8.7113 7.07837 7.66663 8.13182 7.66663 9.43131C7.66663 10.7308 8.7113 11.7843 9.99996 11.7843Z"
      stroke={color ?? '#000000'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default MapPinIcon
