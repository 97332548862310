import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import ProductShelfSkeleton from 'src/components/skeletons/ProductShelfSkeleton'
import sliderSettings from 'src/configs/slider-pdp-shelf'
import { List as UIList } from '@faststore/ui'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import ProductCard from '../../product/ProductCard'
import './product-shelf.scss'
import type { Node } from '../ProductDescription/utils/utils'

interface ProductShelfProps {
  products?: Node[]
}

function ProductShelfPDP({ products }: ProductShelfProps) {
  const sliderRef = useRef<Slider>(null)

  const { width } = useWindowDimensions()

  const [isMobile, setIsMobile] = useState<boolean>(false)
  const [mounted, setMounted] = useState(false)

  useEffect(() => {
    setMounted(true)
    setIsMobile(width <= 1024)
  }, [width])

  if (products) {
    if ((products.length <= 3 && !isMobile) || products.length === 1) {
      return (
        <div className={`card-collection not-slider-${products.length}`}>
          <ProductShelfSkeleton
            loading={products.length === 0 && mounted === false}
          >
            {products?.length > 0 && (
              <UIList className="card-collection--content">
                {products?.map((product, idx) => (
                  <li className="card-collection--list" key={idx}>
                    <ProductCard
                      product={product}
                      index={idx}
                      key={idx}
                      variant="pdp"
                    />
                  </li>
                ))}
              </UIList>
            )}
          </ProductShelfSkeleton>
        </div>
      )
    }

    return (
      <>
        <ProductShelfSkeleton
          loading={products.length === 0 && mounted === false}
        >
          {products?.length > 0 && (
            <Slider ref={sliderRef} {...sliderSettings}>
              {products.map((product, idx) => (
                <ProductCard
                  product={product}
                  index={idx}
                  key={idx}
                  variant="pdp"
                />
              ))}
            </Slider>
          )}
        </ProductShelfSkeleton>
      </>
    )
  }

  return <></>
}

export default ProductShelfPDP
