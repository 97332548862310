import React from 'react'
import type { Dispatch, SetStateAction } from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import InventoryFormModal from 'src/components/sections/InventoryCheck/InventoryFormModal'
import AddToWishlistModal from 'src/components/modal/AddToWishlistModal'
import SingleInputModal from 'src/components/modal/SingleInputModal'

export const DetailsSideBarAbove = ({
  isInventoryModalOpen,
  isSaveProductModalOpen,
  isCreateWishlistModalOpen,
  product,
  selectedSKU,
  setIsInventoryModalOpen,
  setIsSaveProductModalOpen,
  setWasList,
  setIsCreateWishlistModalOpen,
  createWishlist,
}: DetailsSideBarAboveProps) => {
  const productToWishlist = selectedSKU ? { ...selectedSKU } : product

  return (
    <>
      {isInventoryModalOpen && (
        <InventoryFormModal
          id={product.id}
          isInventoryModalOpen={isInventoryModalOpen}
          setIsInventoryModalOpen={setIsInventoryModalOpen}
        />
      )}
      {isSaveProductModalOpen && (
        <AddToWishlistModal
          products={[{ ...productToWishlist }]}
          isOpen={isSaveProductModalOpen}
          setIsOpen={setIsSaveProductModalOpen}
          openWishlistModal={() => setIsCreateWishlistModalOpen(true)}
          finalAction={() => setWasList('addProduct')}
        />
      )}
      {isCreateWishlistModalOpen && (
        <SingleInputModal
          title="Dê um nome para sua lista"
          btnText="Salvar"
          isOpen={isCreateWishlistModalOpen}
          setIsOpen={setIsCreateWishlistModalOpen}
          setIsSaveProductModalOpen={setIsSaveProductModalOpen}
          modalAction={createWishlist}
          onClose={() => setIsSaveProductModalOpen(true)}
        />
      )}
    </>
  )
}

type DetailsSideBarAboveProps = {
  isInventoryModalOpen: boolean
  isSaveProductModalOpen: boolean
  isCreateWishlistModalOpen: boolean
  product: BrowserProductQueryQuery['product']
  selectedSKU: BrowserProductQueryQuery['product']
  setIsInventoryModalOpen: Dispatch<SetStateAction<boolean>>
  setIsSaveProductModalOpen: Dispatch<SetStateAction<boolean>>
  setWasList: Dispatch<SetStateAction<string | undefined>>
  setIsCreateWishlistModalOpen: Dispatch<SetStateAction<boolean>>
  createWishlist: (
    name: string,
    isDefault?: boolean | undefined
  ) => Promise<boolean>
}
