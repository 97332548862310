import React, { useEffect, useState } from 'react'
import { Button } from '@faststore/ui'
import BaseModal from 'src/components/modal/BaseModal'
import './styles.scss'
import type { BrowserProductQueryQuery } from '@generated/graphql'

import ContentModal from './ContentModal'

interface BuyTogetherModalProps {
  products: Array<BrowserProductQueryQuery['product']>
  buyTogetherHasVariant: boolean
}

const BuyTogetherModal = ({
  products,
  buyTogetherHasVariant,
}: BuyTogetherModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const [productRender, setProductRender] = useState<number>(0)
  const onCloseButton = () => {
    setIsOpen(false)
  }

  const productCurrentVariants = products?.[0]?.isVariantOf?.hasVariant
  const productBuyTogetherVariants = products?.[1]?.isVariantOf?.hasVariant

  useEffect(() => {
    if (productCurrentVariants && productCurrentVariants.length > 1) {
      setProductRender(0)
    } else if (
      productBuyTogetherVariants &&
      productBuyTogetherVariants.length > 1
    ) {
      setProductRender(1)
    }
  }, [productCurrentVariants, productBuyTogetherVariants, isOpen])

  return (
    <>
      <div className="AddToCart">
        <Button onClick={() => setIsOpen(true)} id="btn-add-to-cart">
          adicionar os dois à sacola
        </Button>
      </div>

      <BaseModal
        isOpen={isOpen}
        onCloseButtonClick={onCloseButton}
        className="buyTogetherModal"
      >
        <div className="buyTogetherModal__content">
          <ContentModal
            products={products}
            productCurrentVariants={productCurrentVariants?.length > 1}
            productBuyTogetherVariants={productBuyTogetherVariants?.length > 1}
            productRender={productRender}
            setProductRender={setProductRender}
            setIsOpen={setIsOpen}
            buyTogetherHasVariant={buyTogetherHasVariant}
          />
        </div>
      </BaseModal>
    </>
  )
}

export default BuyTogetherModal
