import React from 'react'
import { Button } from '@faststore/ui'
import axios from 'axios'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import { useCart } from 'src/sdk/cart'
import { cartGenerate } from 'src/utils/generateCart'
import changeOpenTextField from 'src/utils/changeOpenTextField'
import { getCookie } from 'src/utils/cookie'

import storeConfig from '../../../../../../store.config'

import './one-click-buy.scss'

type ProductsList = BrowserProductQueryQuery['product'] & {
  quantity?: number
  selected?: boolean
  idUnique?: string
}

type OneClickBuyProps = {
  quantity?: number
  products: ProductsList[] | BrowserProductQueryQuery['product']
}

const OneClickBuy = ({ quantity, products }: OneClickBuyProps) => {
  const { id: cartId } = useCart()

  const newCartProduct = (
    productsForCart: ProductsList | BrowserProductQueryQuery['product'],
    productIndex: number,
    quantityProduct?: number
  ) => {
    const productSpotPrice = productsForCart?.offers?.lowPrice
    const productOffers = productsForCart?.offers?.offers
    const bestPriceIndex = productOffers?.findIndex(
      (offer) => offer?.price === productSpotPrice
    )

    const mainIndex = bestPriceIndex === -1 ? 0 : bestPriceIndex

    return {
      quantity: quantityProduct ?? 1,
      seller: productOffers?.[mainIndex]?.seller?.identifier,
      index: productIndex,
      price: productSpotPrice?.toFixed(2),
      id: productsForCart?.sku,
    }
  }

  const handleClick = async () => {
    try {
      let oldOrderForm = cartId

      if (oldOrderForm === '') {
        oldOrderForm = await cartGenerate({ reload: false })
      }

      const { data: oldOrderFormData } = await axios.post('/api/getOrderform', {
        orderformID: cartId,
      })

      const { data } = await axios.get('/api/getOrderFormId?forceNewCart=true')
      const newOrderFormId: string = data ?? ''

      if (oldOrderFormData?.openTextField?.value) {
        changeOpenTextField(newOrderFormId, {
          value: oldOrderFormData?.openTextField?.value,
        })
      }

      let cartProduct

      if (!Array.isArray(products)) {
        cartProduct = [newCartProduct(products, 0, quantity)]
      } else {
        cartProduct = products?.map((product, index) =>
          newCartProduct(product, index, product?.quantity)
        )
      }

      await Promise.all([
        axios.post('/api/addProductToOrderForm', {
          orderFormId: newOrderFormId,
          orderItems: cartProduct,
        }),
        axios.post('/api/addProductToOrderForm', {
          orderFormId: oldOrderForm,
          orderItems: cartProduct,
        }),
      ])

      const email = getCookie('emailOneClickBuy')

      email &&
        !oldOrderFormData?.clientProfileData?.email &&
        (await axios.post('/api/insertEmailOrderform', {
          orderFormId: newOrderFormId,
          email,
        }))

      const { secureAccount } = storeConfig

      window.location.assign(
        `https://${secureAccount}.com.br/checkout?orderFormId=${newOrderFormId}#/payment?oldOrderFormId=${oldOrderForm}`
      )
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <div className="product-details__oneClickBuy">
      <Button onClick={handleClick} id="btn-one-click-buy">
        Comprar Agora
      </Button>
    </div>
  )
}

export default OneClickBuy
