import React, { useState } from 'react'

import MeasureYourFinger from './MeasureYourFinger/MeasureYourFinger'
import MeasureYourRing from './MeasureYourRing/MeasureYourRing'
import HandIcon from '../../icons/Hand'
import RingIcon from '../../icons/Ring'
import './rings.scss'

interface RingsProps {
  isLife: boolean
}

const Rings = ({ isLife }: RingsProps) => {
  const [activeStep, setActiveStep] = useState(1)
  const isActiveStep = (step: number) => activeStep === step

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  return (
    <>
      <div className="measureGuide__steps">
        <button
          id="btn-measure-ring"
          className={`measureGuide__stepButtonOne ${
            isActiveStep(1) ? 'stepButtonActive' : ''
          }`}
          onClick={() => handleStepChange(1)}
        >
          <span>
            <RingIcon color={!isActiveStep(1) ? '#737373' : '#000'} />
            Como medir <br /> seu anel
          </span>
        </button>
        <button
          id="btn-measure-finger"
          className={`measureGuide__stepButtonTwo ${
            isActiveStep(2) ? 'stepButtonActive' : ''
          }`}
          onClick={() => handleStepChange(2)}
        >
          <span>
            <HandIcon color={!isActiveStep(2) ? '#737373' : '#000'} />
            Como medir <br /> seu dedo
          </span>
        </button>
      </div>
      <div className="measureGuide__stepContent">
        {isActiveStep(1) && (
          <div>
            <MeasureYourRing />
          </div>
        )}
        {isActiveStep(2) && <MeasureYourFinger isLife={isLife} />}
      </div>
    </>
  )
}

export default Rings
