import React, { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { Button } from '@faststore/ui'
import CheckCircleOrangeSmall from 'src/images/svg/check-circle-orange-small'
import { Image } from 'src/components/ui/Image'

import type { SelectedProductsAssemble } from '../Assembly/types'
import { getValidImages } from './ProductCardAdd'

interface ProductCardCheckedProps {
  productInfo: SelectedProductsAssemble
  shelfClass: string
  setIsAddButton: Dispatch<SetStateAction<boolean>>
  setIsSelected: Dispatch<SetStateAction<boolean>>
  onClickRemoveProduct: (index: number) => void
  canvaIndex: number
}

export const ProductCardChecked = ({
  productInfo,
  shelfClass,
  setIsAddButton,
  setIsSelected,
  onClickRemoveProduct,
  canvaIndex,
}: ProductCardCheckedProps) => {
  const timer = setTimeout(() => {
    setIsAddButton(false)
    setIsSelected(false)
  }, 2000)

  const firstValidImage =
    productInfo?.product?.image?.length > 1
      ? getValidImages(productInfo?.product?.image)
      : {
          url: productInfo?.product?.image[0]?.url ?? '',
          alternateName: productInfo?.product?.image[0]?.alternateName ?? '',
        }

  const [cardImage, setCardImage] = useState(firstValidImage)

  useEffect(() => {
    if (productInfo) {
      setCardImage(firstValidImage)
    }
  }, [productInfo, cardImage])

  return (
    <div className={`${shelfClass} selected-product`}>
      <Image
        baseUrl={cardImage?.url}
        alt={cardImage?.alternateName}
        aspectRatio={1}
      />
      <div className="assemble-checked-content">
        <div className="assemble-checked-icon">
          <CheckCircleOrangeSmall />
        </div>
        <div className="assemble-selected">
          <p>ADICIONADO!</p>
        </div>
      </div>

      <Button
        className="assemble-cancel"
        onClick={() => {
          setIsAddButton(false)
          setIsSelected(false)
          onClickRemoveProduct(canvaIndex)
          clearInterval(timer)
        }}
      >
        CANCELAR
      </Button>
    </div>
  )
}
