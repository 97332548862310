import { convertDay, convertMonth, convertYear } from './convertDate'

interface ExpandHolidaysArrayProps {
  id: string
  account: string
  startDate: string
  endDate: string
  name: string
}

function formatDateWithoutTime(date: Date) {
  const day = convertDay('DD', date)
  const month = convertMonth('MM', date)
  const year = convertYear('YYYY', date)

  return `${year}-${month}-${day}`
}

export function expandHolidaysArray(holidays: ExpandHolidaysArrayProps[]) {
  const expandedHolidays: string[] = []

  holidays.forEach((holiday) => {
    const startDate = new Date(holiday.startDate)
    const endDate = new Date(holiday.endDate)

    while (startDate <= endDate) {
      const currentDate = formatDateWithoutTime(startDate)

      if (!expandedHolidays.includes(currentDate)) {
        expandedHolidays.push(currentDate)
      }

      startDate.setDate(startDate.getDate() + 1)
    }
  })

  return expandedHolidays
}
