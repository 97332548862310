import { useCallback } from 'react'

type Props = {
  item_id: string
  item_name: string
  item_category: string
  item_subcategory: string
  item_collection: string | null
  item_type: string | null
  item_reference: string
  item_material: string | null
  item_brand: string
  price: number
  value: number
  currency: string
  item_size: string | null
  item_stone: string | null
  item_weight: string | null
  item_audience: string | null
}

export const useViewItem = (props: Props) => {
  window.dataLayer = window.dataLayer || []
  const { dataLayer } = window

  const sendUseViewItemEvent = useCallback(() => {
    dataLayer.push({
      event: 'view_item',
      currency: props.currency,
      value: props.value,
      items: [
        {
          item_id: props.item_id,
          item_name: props.item_name,
          item_category: props.item_category,
          item_subcategory: props.item_subcategory,
          item_collection: props.item_collection,
          item_type: props.item_type,
          item_reference: props.item_reference,
          item_material: props.item_material,
          item_brand: props.item_brand,
          price: props.price,
          item_size: props.item_size,
          item_stone: props.item_stone,
          item_weight: props.item_weight,
          item_audience: props.item_audience,
        },
      ],
    })
  }, [props])

  return { sendUseViewItemEvent }
}
