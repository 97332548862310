import React from 'react'
import stepOneBraceletImg from 'src/images/measure-guide/stepOneBraceletImg.jpg'
import stepTwoBraceletImg from 'src/images/measure-guide/stepTwoBraceletImg.jpg'

import './bracelet.scss'

const steps = [
  {
    title: 'passo 1',
    subtitle: `Envolva o barbante em seu braço logo abaixo do osso do pulso. Não deixe muito apertado, principalmente se você quiser adicionar pingentes à sua futura pulseira.`,
    img: stepOneBraceletImg,
    alt: 'Step One Measure Your Finger',
  },
  {
    title: 'passo 2',
    subtitle:
      'Marque o final do barbante com a caneta e depois é só medir o comprimento com uma régua.',
    img: stepTwoBraceletImg,
    alt: 'Step Two Measure Your Finger',
  },
]

const Bracelet = () => {
  return (
    <div className="measureYourFingerBracelet">
      <div className="measureYourFingerBracelet__content">
        <p className="measureYourFingerBracelet__title">
          Siga as instruções abaixo para determinar o tamanho do seu pulso para
          escolher o tamanho da pulseira ideal.
        </p>
        <h3>Você vai precisar de:</h3>
        <ul>
          <li>Pedaço de linha ou barbante;</li>
          <li>Caneta;</li>
          <li>Régua;</li>
        </ul>

        {steps.map((step) => (
          <div key={step.title} className="measureYourFingerBracelet__step">
            <div className="measureYourFingerBracelet__step-content">
              <h4>{step.title}</h4>
              <p>{step.subtitle}</p>
            </div>
            <img
              className="measureYourFingerBracelet__step-image"
              src={step.img}
              alt={step.alt}
            />
          </div>
        ))}
      </div>
      <p className="measureYourFingerBracelet__info">
        Os centímetros apontados na régua indicam em centímetros o tamanho ideal
        da pulseira.
      </p>
    </div>
  )
}

export default Bracelet
