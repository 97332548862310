import type { ProductSummary_ProductFragment } from '@generated/graphql'
import type { Dispatch, SetStateAction } from 'react'

import type { ClusterHighlights } from '../ProductCardCustomBracelet/ProductCardAssemble'
import { DEFAULT_MAX_ADDITIONAL_ITENS } from './Assembly'
import type { SelectedProductsAssemble } from './types'

interface RemoveCardProductProps {
  index: number
  setSelectedProductsAssembleBracelet: Dispatch<
    SetStateAction<Array<SelectedProductsAssemble | null>>
  >
  setItemsSelectedQtt: Dispatch<SetStateAction<number>>
  getNewItemsSelectedQtt: () => number
  removedCardsWhileEditing: Array<{
    product: SelectedProductsAssemble
    isChain: boolean
  }>
}

interface RemoveCardChainProductProps {
  setSelectedProductsAssembleBraceletChain: Dispatch<
    SetStateAction<SelectedProductsAssemble | null>
  >
  setItemsSelectedQtt: Dispatch<SetStateAction<number>>
  getNewItemsSelectedQtt: () => number
}

interface AddChainCardProductProps {
  product: SelectedProductsAssemble
  setSelectedProductsAssembleBraceletChain: Dispatch<
    SetStateAction<SelectedProductsAssemble | null>
  >
  setItemsSelectedQtt: Dispatch<SetStateAction<number>>
  getNewItemsSelectedQtt: () => number
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
  hasChainSelected: boolean
}

interface AddCardProductProps {
  product: SelectedProductsAssemble
  setSelectedProductsAssembleBracelet: Dispatch<
    SetStateAction<Array<SelectedProductsAssemble | null>>
  >
  setItemsSelectedQtt: Dispatch<SetStateAction<number>>
  getNewItemsSelectedQtt: () => number
}

interface CancelEditFuncProps {
  removedCardsWhileEditing: Array<{
    product: SelectedProductsAssemble
    isChain: boolean
  }>
  onClickAddChain: (product: SelectedProductsAssemble) => Promise<void>
  onClickAddCardProduct: (product: SelectedProductsAssemble) => Promise<number>
  finishEdit: () => void
}

interface OnClickRemoveProductProps {
  index: number
  sethasChainSelected: React.Dispatch<React.SetStateAction<boolean>>
  removedCardsWhileEditing: Array<{
    product: SelectedProductsAssemble
    isChain: boolean
  }>
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
  setSelectedProductsAssembleBraceletChain: React.Dispatch<
    React.SetStateAction<SelectedProductsAssemble | null>
  >
  setItemsSelectedQtt: React.Dispatch<React.SetStateAction<number>>
  getNewItemsSelectedQtt: () => number
  setSelectedProductsAssembleBracelet: React.Dispatch<
    React.SetStateAction<Array<SelectedProductsAssemble | null>>
  >
}

export async function removeCardProductChain({
  setSelectedProductsAssembleBraceletChain,
  setItemsSelectedQtt,
  getNewItemsSelectedQtt,
}: RemoveCardChainProductProps) {
  setSelectedProductsAssembleBraceletChain(null)
  setItemsSelectedQtt(getNewItemsSelectedQtt() - 1)
}

export async function addCardProductChain({
  product,
  setSelectedProductsAssembleBraceletChain,
  setItemsSelectedQtt,
  getNewItemsSelectedQtt,
  hasChainSelected,
}: AddChainCardProductProps) {
  setSelectedProductsAssembleBraceletChain(product)
  setItemsSelectedQtt(getNewItemsSelectedQtt() + (hasChainSelected ? 0 : 1))
}

export async function addCardProduct({
  product,
  setSelectedProductsAssembleBracelet,
  setItemsSelectedQtt,
  getNewItemsSelectedQtt,
}: AddCardProductProps): Promise<number> {
  let index = -2

  setSelectedProductsAssembleBracelet((prev) => {
    index = prev.findIndex((productSelected) => productSelected === null)

    if (index !== -1) {
      product.index = index
      prev[index] = product

      return [...prev]
    }

    if (index === -1 && prev.length < DEFAULT_MAX_ADDITIONAL_ITENS) {
      index = prev.length
      product.index = index
      prev.push(product)

      return [...prev]
    }

    return prev
  })

  index !== -2 && setItemsSelectedQtt(getNewItemsSelectedQtt())

  return index
}

export async function removeCardProduct({
  index,
  setSelectedProductsAssembleBracelet,
  setItemsSelectedQtt,
  getNewItemsSelectedQtt,
  removedCardsWhileEditing,
}: RemoveCardProductProps) {
  setSelectedProductsAssembleBracelet((prev) => {
    prev[index] !== null &&
      removedCardsWhileEditing.push({
        product: {
          ...(prev[index] as SelectedProductsAssemble),
        },
        isChain: false,
      })
    prev[index] = null

    return [...prev]
  })
  setItemsSelectedQtt(getNewItemsSelectedQtt())
}

export function isItemSelected(
  product: ProductSummary_ProductFragment & ClusterHighlights,
  selectedProductsAssembleBracelet: Array<SelectedProductsAssemble | null>,
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
) {
  if (
    selectedProductsAssembleBraceletChain &&
    product.id === selectedProductsAssembleBraceletChain.product.id
  ) {
    return true
  }

  const itemFound = selectedProductsAssembleBracelet.find((selectedItem) => {
    return selectedItem?.product?.id === product?.id
  })

  return !!itemFound
}

export function changeShowModal(
  setIsOpen: Dispatch<SetStateAction<boolean>>,
  action: 'close' | 'open'
) {
  setIsOpen(action === 'open')
  if (action === 'open') {
    document.body.classList.add('no-scroll')
  } else {
    document.body.classList.remove('no-scroll')
  }
}

export function cancelEditFunc({
  removedCardsWhileEditing,
  onClickAddChain,
  onClickAddCardProduct,
  finishEdit,
}: CancelEditFuncProps) {
  removedCardsWhileEditing.forEach(({ product, isChain }) => {
    if (isChain) {
      onClickAddChain(product)
    } else {
      onClickAddCardProduct(product)
    }
  })

  finishEdit()
}

export async function onClickRemoveProductFunc({
  index,
  sethasChainSelected,
  removedCardsWhileEditing,
  selectedProductsAssembleBraceletChain,
  setSelectedProductsAssembleBraceletChain,
  setItemsSelectedQtt,
  getNewItemsSelectedQtt,
  setSelectedProductsAssembleBracelet,
}: OnClickRemoveProductProps) {
  const chainDefaultIndex = DEFAULT_MAX_ADDITIONAL_ITENS + 1
  const isChain = chainDefaultIndex === index

  if (isChain) {
    sethasChainSelected(false)

    removedCardsWhileEditing.push({
      product: {
        ...(selectedProductsAssembleBraceletChain as SelectedProductsAssemble),
      },
      isChain,
    })

    removedCardsWhileEditing.push()

    return removeCardProductChain({
      setSelectedProductsAssembleBraceletChain,
      setItemsSelectedQtt,
      getNewItemsSelectedQtt,
    })
  }

  return removeCardProduct({
    index,
    setSelectedProductsAssembleBracelet,
    setItemsSelectedQtt,
    getNewItemsSelectedQtt,
    removedCardsWhileEditing,
  })
}
