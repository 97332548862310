import React, { useEffect } from 'react'
import type { PropsWithChildren } from 'react'
import { showOrHideModalInPdp } from 'src/utils/showOrHideModalInPdp'

import CloseIcon from '../../icons/Close'

import './measure-guide-modal.scss'

interface MeasureGuideModalProps {
  closeModal: () => void
  title: string
}

const MeasureGuideModal = ({
  closeModal,
  children,
  title,
}: PropsWithChildren<MeasureGuideModalProps>) => {
  useEffect(() => {
    if (children) {
      showOrHideModalInPdp()
    }

    return () => showOrHideModalInPdp(true)
  })

  return (
    <div className="measureGuide__modal">
      <div className="measureGuide__modal-container">
        <h2 className="measureGuide__title">{title}</h2>
        <p className="measureGuide__subtitle">Guia de medidas</p>
        <button onClick={closeModal} className="measureGuide__close">
          <CloseIcon width="20" height="20" color="#737373" />
        </button>
        <div className="measureGuide__content">{children}</div>
      </div>
    </div>
  )
}

export default MeasureGuideModal
