import type { ProductSpecifications } from 'src/pages/[slug]/p'

function getSpecificationValue(
  specificationName: string,
  productSpecifications?: ProductSpecifications
) {
  const specification = productSpecifications?.specifications?.find(
    (spec) => spec?.name === specificationName
  )

  const value =
    specification?.values?.[0] ||
    `sem ${specificationName
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '')}`

  return value.toUpperCase()
}

export default getSpecificationValue
