import React from 'react'

const Diamond = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="67"
    height="69"
    viewBox="0 0 67 69"
    fill="none"
  >
    <circle opacity="0.2" cx="33.5" cy="35.5" r="33.5" fill="#FFA687" />
    <path d="M55 22L65 22" stroke="#F08769" strokeWidth="2" />
    <path d="M51 14.3001L58 6.6001" stroke="#F08769" strokeWidth="2" />
    <path d="M43 11V0" stroke="#F08769" strokeWidth="2" />
    <path
      d="M16.875 32.6746L22.0098 25.1402C22.2874 24.8711 22.7037 24.6021 23.1201 24.6021H45.8799C46.2963 24.6021 46.7126 24.8711 46.9902 25.1402L52.125 32.6746"
      stroke="#F08769"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M51.9861 34.1548L35.3326 52.7216C34.9162 53.2597 34.0836 53.2597 33.6672 52.7216L17.0137 34.1548"
      stroke="#F08769"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M49.3494 33.4819H19.2343"
      stroke="#F08769"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.3612 52.9906L40.7451 33.6165L45.0472 26.7549"
      stroke="#F08769"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M34.3612 52.9906L28.1161 33.6165L23.5364 26.7549"
      stroke="#F08769"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M28.1161 33.3475L34.6387 24.7368L40.745 33.6166"
      stroke="#F08769"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
  </svg>
)

export default Diamond
