import React, { useState } from 'react'
import CloseIcon from 'src/components/icons/Close'
import { Link } from 'gatsby'

import './alert-with-message.scss'

interface IAlertWithMessage {
  text: string
  styles?: React.CSSProperties
  viewState?: React.Dispatch<React.SetStateAction<boolean>>
  link?: string
  measureTag?: string
}

const AlertWithMessage = ({
  text,
  styles,
  viewState,
  link,
  measureTag,
}: IAlertWithMessage) => {
  const baseClass = 'alertWithMessage'
  const [showAlertWithMessage, setShowAlertWithMessage] = useState(true)

  return (
    <>
      {showAlertWithMessage && (
        <div className={`a-${baseClass}`} style={{ ...styles }}>
          <div className={baseClass}>
            <div className={`${baseClass}-diamond`} />
            <div className={`${baseClass}-message`}>
              <p className={`${baseClass}-message__text`}>
                {text}{' '}
                {link && (
                  <Link to={link} className="link">
                    SAIBA MAIS
                  </Link>
                )}
              </p>
            </div>
            <CloseIcon
              className="closeAlert"
              onClick={() => {
                setShowAlertWithMessage(!showAlertWithMessage)
                viewState?.(!showAlertWithMessage)
                measureTag && localStorage.setItem(measureTag, 'false')
              }}
              color="#737373"
              strokeWidth="1.5"
            />
          </div>
        </div>
      )}
    </>
  )
}

export default AlertWithMessage
