import React, { useState } from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import IconHelpCircle from 'src/images/svg/Icon-help-circle'

import DetailsItemComparedProduct from './components/DetailsItemComparedProduct'
import ModalKnowMore from '../ModalKnowMore'
import type { IKnowMore } from '../DetailsComparedProduct/DetailsComparedProduct'

import './details-items-compared-product.scss'

interface IDetailsItemsComparedProduct {
  products: Array<BrowserProductQueryQuery['product']>
  title: string
  name: string
  titleExtra?: boolean
  hasImage?: boolean
  knowMore?: IKnowMore
}

function DetailsItemsComparedProduct({
  products,
  title,
  name,
  titleExtra = false,
  hasImage = false,
  knowMore,
}: IDetailsItemsComparedProduct) {
  const [showKnowMore, setShowKnowMore] = useState(false)
  const baseClass = `modalComparedProduct-details-container`

  const quantityProducts = products?.length
  const { isMobile, widthInner } = useWindowDimensions()
  const minMaxGrid = isMobile ? 'minmax(100px, 1fr)' : 'minmax(200px, 200px)'
  const columnGap = isMobile ? '21px' : '48px'

  return (
    <div className={`${baseClass} ${name}`} key={name}>
      <div className={`${baseClass}__title`}>
        <h3 className="title-items">{title}:</h3>
        {titleExtra && <p className="text-items">(Medida aproximada)</p>}
        {widthInner <= 1024 && knowMore && (
          <IconHelpCircle onClick={() => setShowKnowMore(true)} />
        )}
      </div>
      {name !== 'values' && (
        <div className={`${baseClass}-items`}>
          <ul
            style={
              widthInner < 768 || widthInner > 1024
                ? {
                    gridTemplateColumns:
                      quantityProducts <= 3
                        ? `repeat(${quantityProducts}, ${minMaxGrid})`
                        : '',
                    columnGap: quantityProducts <= 3 ? columnGap : '',
                  }
                : {}
            }
            className={`${baseClass}-items__list-items ${name}`}
          >
            {products?.map((product) => (
              <li
                key={product?.id}
                className={`${baseClass}-items__list-items-product`}
              >
                <DetailsItemComparedProduct
                  product={product}
                  name={name}
                  hasImage={hasImage}
                />
              </li>
            ))}
          </ul>
          {widthInner > 1024 && knowMore && (
            <div className={`${baseClass}-items-knowMore`}>
              <button
                id="knowMore-button"
                onClick={() => setShowKnowMore(true)}
                className={`${baseClass}-items-knowMore__button`}
              >
                Saiba mais <IconHelpCircle />
              </button>
            </div>
          )}
          {showKnowMore && (
            <ModalKnowMore
              setShowKnowMore={setShowKnowMore}
              knowMore={knowMore}
              classKnowMore={name}
            />
          )}
        </div>
      )}
    </div>
  )
}

export default DetailsItemsComparedProduct
