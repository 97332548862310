import type { BrowserProductQueryQuery } from '@generated/graphql'
import { normalize } from 'src/utils/similars'

export const getSpecificationProduct = (
  specificationGroups: BrowserProductQueryQuery['product']['specificationGroups'],
  specificationName: string
) => {
  const specificationAll = specificationGroups?.find(
    (item) => item?.name === 'allSpecifications'
  )

  const specificationMaterial = specificationAll?.specifications?.find(
    (item) => {
      if (
        specificationName === 'largura-aprox' ||
        specificationName === 'espessura-aprox'
      ) {
        const specName = item?.name
          ?.toString()
          .replace(' ', '-')
          .toLocaleLowerCase()

        return specName === specificationName
      }

      return item?.name && normalize(item?.name) === specificationName
    }
  )

  return specificationMaterial?.values
}

export const getSpecificationProductLarge = (
  specificationGroups: BrowserProductQueryQuery['product']['specificationGroups']
) => {
  const specificationAll = specificationGroups?.find(
    (item) => item?.name === 'allSpecifications'
  )

  const specificationLarge = specificationAll?.specifications?.find(
    (item) =>
      item?.name?.toString().replace(' ', '-').toLocaleLowerCase() ===
      'largura-aprox'
  )

  const valuesArray = specificationLarge?.values ?? []
  const value = valuesArray?.length > 0 ? valuesArray?.[0] : ''

  return value ? value?.toLowerCase()?.replace(/\s+/g, '-') : ''
}
