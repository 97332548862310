import React, { memo } from 'react'

import './product-benefits.scss'

function ProductBenefits() {
  return (
    <div className="ProductproductBenefits">
      <h2 className="ProductproductBenefits__title">Benefícios Vivara</h2>
      <ul className="ProductproductBenefits__list">
        <li className="ProductproductBenefits__listItem">
          <a
            href="https://www.vivara.com.br/institucional/politica-garantia"
            target="_blank"
            rel="noopener noreferrer"
          >
            Consulte Garantia
          </a>
        </li>
        <li className="ProductproductBenefits__listItem">
          Troca gratuita em até 30 dias após o recebimento.
        </li>
      </ul>
    </div>
  )
}

export default memo(ProductBenefits)
