import React from 'react'
import MapPinIcon from 'src/components/icons/MapPin'
import NotResultSearch from 'src/images/svg/icon-not-result-search'

import type { InventoryFormModalStep } from './InventoryFormModal'

type InventoryNotFoundStepProps = {
  setStep: React.Dispatch<React.SetStateAction<InventoryFormModalStep>>
  postalCode: string
}

export const InventoryNotFoundStep = ({
  setStep,
  postalCode,
}: InventoryNotFoundStepProps) => {
  return (
    <div className="inventory-modal__not-found-step">
      <div className="not-found-step__header">
        <MapPinIcon color="#F08769" />
        <span className="not-found-step__title">{`Próximo à ${postalCode}`}</span>
        <button
          className="not-found-step__back-button"
          onClick={() => setStep('form')}
        >
          alterar
        </button>
      </div>
      <NotResultSearch className="not-found-icon" />
      <p className="not-found-step__text">
        Infelizmente ainda não temos uma loja próxima a este endereço. Se
        possível, insira um novo CEP e busque novamente.
      </p>
      <button
        className="inventory-modal__button"
        onClick={() => setStep('form')}
      >
        realizar uma nova busca
      </button>
    </div>
  )
}
