export function calculateBusinessDays(date: Date, daysWork: number) {
  while (daysWork > 0) {
    date.setDate(date.getDate() + 1)
    const dayOfWeek = date.getDay()

    if (dayOfWeek === 0 || dayOfWeek === 6) {
      continue
    }

    daysWork--
  }

  return date.getTime()
}
