const settings = {
  arrows: true,
  dots: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  className: 'slider-buy-together',
  responsive: [
    {
      breakpoint: 1279,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
}

export default settings
