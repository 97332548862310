import React from 'react'
import NotResultSearch from 'src/images/svg/icon-not-result-search'
import type { BrowserProductQueryQuery } from '@generated/graphql'

import './not-result-compared-product.scss'

interface INotResultComparedProduct {
  redirectToPdp?: (slug: string, searchParams?: string) => void
  product: BrowserProductQueryQuery['product']
}

function NotResultComparedProduct({
  redirectToPdp,
  product,
}: INotResultComparedProduct) {
  const baseClass = 'modalComparedProduct-not-result'

  return (
    <div className={baseClass}>
      <NotResultSearch />
      <p className={`${baseClass}-text`}>
        Infelizmente, não encontramos resultados para a sua busca.
      </p>
      <div className={`${baseClass}-buttonContainer`}>
        <button
          className={`${baseClass}-buttonContainer-button`}
          onClick={() => redirectToPdp?.(product?.slug)}
        >
          Voltar para o produto
        </button>
      </div>
    </div>
  )
}

export default NotResultComparedProduct
