import React from 'react'

import './StepTutorialCard.scss'

export interface StepTutorialCardProps {
  step: number
  title: string
  imageUrl: string
  description: string
}

export const StepTutorialCard = ({
  step,
  title,
  imageUrl,
  description,
}: StepTutorialCardProps) => {
  return (
    <div className="step-tutorial-card__container">
      <div className="step-tutorial-card__info">
        <div className="step-tutorial-card__step">Passo {step}</div>
        <p className="step-tutorial-card__title">{title}</p>
      </div>
      <div className="step-tutorial-card__img">
        <img src={imageUrl} alt={title} loading="lazy" />
      </div>
      <p className="step-tutorial-card__description">{description}</p>
    </div>
  )
}
