import React, { lazy } from 'react'
import lazyRetry from 'src/utils/lazyRetry'

const ItemShippingPrice = lazy(() =>
  lazyRetry(() => import('./ItemShippingPrice'), 'ItemShippingPrice')
)

export default function ShippingPrice({
  sla,
  keyProp,
  pickupPoints,
  setIsToday,
  today,
}: ShippingPriceProps) {
  return (
    <>
      <div key={keyProp} className="shipping-simulator__info-container">
        <ItemShippingPrice
          sla={sla}
          pickupPoints={pickupPoints}
          setIsToday={setIsToday}
          today={today}
        />
        <span className="shipping-simulator__info-price">
          {Math.round(sla?.price / 100).toFixed(2) === '0.00'
            ? 'Grátis'
            : `R$ ${Math.round(sla?.price / 100).toFixed(2)}`}
        </span>
      </div>
    </>
  )
}
