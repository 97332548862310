export const setUrlProductIdentifier = (lastSelectedSku: string) => {
  const urlParams = new URLSearchParams(window?.location?.search)

  if (lastSelectedSku && lastSelectedSku !== '') {
    if (!urlParams?.has('skuId')) {
      urlParams?.append('skuId', lastSelectedSku)
    } else {
      urlParams?.set('skuId', lastSelectedSku)
    }
  }

  window?.history?.replaceState(
    {},
    '',
    `${window?.location?.origin}${
      window?.location?.pathname
    }?${urlParams?.toString()}`
  )
}
