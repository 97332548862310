import React, { useEffect, useState } from 'react'
import SustentabilityIcon from 'src/images/svg/SustentabilityIcon'

interface ItemListElement {
  item: string
  name: string
  position: number
}

interface BreadcrumbList {
  itemListElement: ItemListElement[]
}

interface Props {
  breadcrumbList: BreadcrumbList
}

const SustantabilityMessage = ({ breadcrumbList }: Props) => {
  const [isSustentabilityMessage, setIsSustentabilityMessage] =
    useState<boolean>(false)

  useEffect(() => {
    if (breadcrumbList?.itemListElement) {
      const validateRender = breadcrumbList?.itemListElement?.some(
        (breadcrumb) =>
          breadcrumb?.item?.indexOf('/life/acessorios') !== -1 ||
          breadcrumb?.item?.indexOf('/vivara/acessorios') !== -1 ||
          breadcrumb?.item?.indexOf('/vivara/relogios') !== -1
      )

      setIsSustentabilityMessage(!validateRender)
    }
  }, [])

  return (
    <>
      {isSustentabilityMessage && (
        <section className="sustentability-message">
          <div>
            <SustentabilityIcon />
          </div>
          <div>
            <p>
              Asseguramos a responsabilidade socioambiental desde a origem da
              matéria-prima das joias. Saiba mais sobre{' '}
              <a href="https://www.vivara.com.br/institucional/sustentabilidade-planeta">
                <b>nossas certificações aqui.</b>
              </a>
            </p>
          </div>
        </section>
      )}
    </>
  )
}

export default SustantabilityMessage
