import type { ProductWithDetails } from 'src/pages/[slug]/p'

import getTaxonomy from './getTaxonomy'
import getSpecificationValue from './getSpecificationValue'

function returnProductCustomDataInsider(product?: ProductWithDetails) {
  const specifications = [
    { name: 'Sugestão', value: 'SEM SUGESTAO' },
    { name: 'Material da Caixa', value: 'SEM MATERIAL DA CAIXA' },
    { name: 'Material da Pulseira', value: 'SEM MATERIAL DA PULSEIRA' },
    { name: 'Material', value: 'SEM MATERIAL' },
    { name: 'Pedras', value: 'SEM PEDRAS' },
    { name: 'Coleção', value: 'SEM COLEÇÃO' },
  ]

  const taxonomyItems = getTaxonomy(product)

  const productSpecifications = product?.specificationGroups?.find(
    (group) => group?.name === 'allSpecifications'
  )

  specifications.forEach((spec) => {
    const specification = productSpecifications?.specifications?.find(
      (item) => item?.name === spec.name
    )?.values?.[0]

    spec.value = specification || spec?.value
  })

  return {
    publico_sku: getSpecificationValue('Sugestão', productSpecifications),
    relogio_material_caixa: getSpecificationValue(
      'Material da Caixa',
      productSpecifications
    ),
    relogio_material_pulseira: getSpecificationValue(
      'Material da Pulseira',
      productSpecifications
    ),
    material_sku: getSpecificationValue('Material', productSpecifications),
    pedra_sku: getSpecificationValue('Pedras', productSpecifications),
    colecao_sku: getSpecificationValue('Coleção', productSpecifications),
    categoria_sku: taxonomyItems?.[0] || 'SEM CATEGORIA',
    grupo_categoria_sku: taxonomyItems?.[2] || 'SEM GRUPO SUBCATEGORIA SKU',
    marca: product?.brand?.name || 'SEM MARCA',
    subcategoria_sku: taxonomyItems?.[1] || 'SEM SUBCATEGORIA',
    departamento: product?.departmentName || 'SEM DEPARTAMENTO',
    sku_id: product?.sku,
  }
}

export default returnProductCustomDataInsider
