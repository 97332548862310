import { calculateBusinessDays } from './calculateBusinessDays'

export function addHolidaysToBusinessDays(
  initialBusinessDays: number,
  holidays: string[]
) {
  const endDate = new Date(
    calculateBusinessDays(new Date(), initialBusinessDays)
  )

  const holidaySet = new Set(
    holidays.map((dateString) =>
      new Date(`${dateString}T00:00-0800`).toDateString()
    )
  )

  let businessDays = initialBusinessDays

  const today = new Date()

  while (today <= endDate) {
    if (
      holidaySet.has(today.toDateString()) &&
      today.getDay() !== 0 &&
      today.getDay() !== 6
    ) {
      businessDays++
      endDate.setDate(endDate.getDate() + 1)
    }

    today.setDate(today.getDate() + 1)
  }

  return `${businessDays}bd`
}
