import React from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { Button } from '@faststore/ui'
import CardTrashIcon from 'src/images/svg/card-trash-icon'
import type { ProductSummary_ProductFragment } from '@generated/graphql'
import { Image } from 'src/components/ui/Image'
import CheckCircleOrangeSmall from 'src/images/svg/check-circle-orange-small'

import { imgOptions } from './ProductCardAssemble'
import type { ClusterHighlights } from './ProductCardAssemble'

interface ProductCardAssembleConfirmRemovalProps {
  shelfClass: string
  product: ProductSummary_ProductFragment & ClusterHighlights
  aspectRatio: number
  setShowConfirmRemoval: Dispatch<SetStateAction<boolean>>
  onClickRemoveProduct: (index: number) => void
  index: number
}

const getValidImages = (
  productImages: Array<{ url: string; alternateName: string }>
) => {
  const validImages = productImages?.filter(
    (image) => !image?.url?.includes('mon')
  )

  return {
    url: validImages?.[0]?.url ?? productImages?.[0]?.url ?? '',
    alternateName:
      validImages?.[0]?.alternateName ?? productImages?.[0]?.url ?? '',
  }
}

export const ProductCardAssembleConfirmRemoval = ({
  shelfClass,
  product,
  aspectRatio,
  setShowConfirmRemoval,
  onClickRemoveProduct,
  index,
}: ProductCardAssembleConfirmRemovalProps) => {
  const timerToDeleteProduct = setTimeout(() => {
    onClickRemoveProduct(index)
  }, 2000)

  const { name } = product

  const firstValidImage =
    product?.image?.length > 1
      ? getValidImages(product?.image)
      : {
          url: product?.image?.[0]?.url ?? '',
          alternateName: product?.image?.[0]?.alternateName ?? '',
        }

  return (
    <div className={`${shelfClass} remove-product`}>
      <div className="product-checked-icon">
        <CheckCircleOrangeSmall />
      </div>
      <Image
        baseUrl={firstValidImage?.url}
        alt={firstValidImage?.alternateName}
        aspectRatio={aspectRatio}
        {...imgOptions}
        imgStyle={{ objectFit: 'contain' }}
      />
      <div className={`${shelfClass}__content`}>
        <h3 className={`${shelfClass}__name-product`}>{name}</h3>
      </div>
      <div className={`${shelfClass}__content-bottom`}>
        <div className="remove-product-bottom">
          <div className="assemble-trash-icon">
            <CardTrashIcon />
          </div>

          <div className="assemble-status">
            <p>Excluído!</p>
          </div>
        </div>

        <Button
          id="assemble-cancel"
          className="assemble-cancel"
          onClick={() => {
            clearTimeout(timerToDeleteProduct)
            setShowConfirmRemoval(false)
          }}
        >
          CANCELAR
        </Button>
      </div>
    </div>
  )
}
