export const showOrHideModalInPdp = (hide = false) => {
  const body = document?.body
  const header = document?.querySelector('.fixed-header') as HTMLElement
  let rightSection = document?.querySelector(
    '.product-details__right-section'
  ) as HTMLElement

  if (!rightSection) {
    rightSection = document?.querySelector(
      '.productKitLook-details-right'
    ) as HTMLElement
  }

  setTimeout(() => window?.scrollTo(0, 0), 10)

  if (!(rightSection && body)) {
    return
  }

  body.style.cssText = `${!hide ? 'overflow: hidden;' : ''}`
  rightSection.style.cssText = `${!hide ? 'z-index: 2; overflow: hidden;' : ''}`

  if (header) {
    header.style.position = !hide ? 'relative' : 'static'
  }
}
