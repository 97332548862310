import React from 'react'
import Close from 'src/components/icons/Close'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import ImagesModalKnowMore from './components/ImagesModalKnowMore'
import SubSectionImagesModalKnowMore from './components/SubSectionImagesModalKnowMore'
import type { IKnowMore } from '../DetailsComparedProduct/DetailsComparedProduct'
import './modal-know-more.scss'

interface IModalKnowMore {
  setShowKnowMore: React.Dispatch<React.SetStateAction<boolean>>
  classKnowMore: string
  knowMore?: IKnowMore
}

function ModalKnowMore({
  setShowKnowMore,
  knowMore,
  classKnowMore,
}: IModalKnowMore) {
  const baseClass = 'modalComparedProduct-knowMore'
  const { isMobile } = useWindowDimensions()
  const buttonCloseSize = isMobile ? '10px' : '16px'
  const images = knowMore?.images

  return (
    <div className={`a-${baseClass}`}>
      <div className={`${baseClass} ${classKnowMore}`}>
        <div className={`${baseClass}-closeModal`}>
          <Close
            onClick={() => setShowKnowMore(false)}
            color="#000"
            strokeWidth="1.5"
            width={buttonCloseSize}
            height={buttonCloseSize}
          />
        </div>
        <div className={`${baseClass}-title ${classKnowMore}`}>
          <h2 className={`${baseClass}-title__text`}>{knowMore?.title}</h2>
        </div>
        <div
          className={`${baseClass}-images ${
            images && images?.length === 1 ? 'center' : ''
          }`}
        >
          {knowMore?.images?.map((item, index) => (
            <ImagesModalKnowMore
              componentName={item?.image}
              key={index}
              desktopSize={item?.desktop}
            />
          ))}
        </div>
        <div className={`${baseClass}-description ${classKnowMore}`}>
          {knowMore?.description && <p>{knowMore?.description}</p>}
          <p className={`${baseClass}-description__text`}>
            {knowMore?.descriptionSecond}
          </p>
        </div>

        {knowMore?.subsectionTitle && (
          <div className={`${baseClass}-subsection ${classKnowMore}`}>
            <h3 className={`${baseClass}-subsection__title`}>
              {knowMore?.subsectionTitle}
            </h3>
            <div className={`${baseClass}-subsection__images`}>
              {knowMore?.subsectionImages?.map((item, index) => (
                <SubSectionImagesModalKnowMore
                  title={item.title}
                  image={item.image}
                  key={index}
                />
              ))}
            </div>
          </div>
        )}

        <div className={`${baseClass}-button ${classKnowMore}`}>
          <button
            className={`${baseClass}-button__elementButton`}
            onClick={() => setShowKnowMore(false)}
          >
            Ok, entendi
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalKnowMore
