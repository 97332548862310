const settings = {
  arrows: true,
  dots: false,
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  className: 'slider-pdp-sku',
}

export default settings
