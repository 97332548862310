import React, { useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { IconAssemblyButton } from 'src/images/svg/Assembly/iconAssemblyButton'
import { Button } from '@faststore/ui'
import { get } from 'idb-keyval'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import type { InferredType } from 'src/components/sections/ProductDetails/useOffer'

import { RegionModalAssembleYourBracelet } from '../RegionModalAssembleYourBracelet/RegionModalAssembleYourBracelet'

import './AssemblyButton.scss'

interface AssemblyButtonProps {
  setIsAssemblePersonlize: Dispatch<SetStateAction<boolean>>
  selectedSKU: InferredType<
    BrowserProductQueryQuery['product']['isVariantOf']['hasVariant']
  >
  setSelectedSkuBracelet: Dispatch<
    SetStateAction<
      | InferredType<
          BrowserProductQueryQuery['product']['isVariantOf']['hasVariant']
        >
      | undefined
    >
  >
}

const AssemblyButton = ({
  setIsAssemblePersonlize,
  selectedSKU,
  setSelectedSkuBracelet,
}: AssemblyButtonProps) => {
  const [openRegionModal, setOpenRegionModal] = useState<boolean>(false)

  const validateUserRegionalization = async () => {
    const session = navigator?.cookieEnabled && (await get('fs::session'))

    if (session?.postalCode) {
      setIsAssemblePersonlize(true)
      setSelectedSkuBracelet(selectedSKU)
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    } else {
      setOpenRegionModal(true)
    }
  }

  const AssemblyClickButton = () => {
    validateUserRegionalization()
  }

  return (
    <>
      <Button
        onClick={() => {
          AssemblyClickButton()
        }}
        className="AssemblyButton"
      >
        <p>Monte sua pulseira</p>
        <div className="AssemblyButtonIcon">
          <IconAssemblyButton />
        </div>
      </Button>
      <RegionModalAssembleYourBracelet
        openModal={openRegionModal}
        setOpenModal={setOpenRegionModal}
      />
    </>
  )
}

export default AssemblyButton
