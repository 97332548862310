import React, { useEffect } from 'react'
import ReleaseTag from 'src/components/common/ReleaseTag'
import type { ITagsData } from 'src/components/product/ProductCard/tagsCollection'
import { findCollectionById } from 'src/components/product/ProductCard/tagsCollection'
import dataTagsCollection from 'src/components/product/ProductCard/data/dataTagsCollection.json'
import dataTagsCollectionBottom from 'src/components/product/ProductCard/data/dataTagsCollectionBottom.json'

interface Props {
  collections: CollectionsType
  collectionId?: string
  isNewRelease?: boolean
  isVivaraYou?: boolean
}

interface IProductTagsBottom {
  collections: CollectionsType
  isVivaraYou?: boolean
}

function hideVivaraYou(className: string, isVivaraYou = false) {
  const elementVivaraYou = document.querySelector<HTMLElement>(className)

  if (elementVivaraYou) {
    if (isVivaraYou) {
      elementVivaraYou.style.display = 'none'

      return true
    }

    elementVivaraYou.style.display = ''
  }

  return false
}

export default function ProductTags({
  collections,
  collectionId,
  isNewRelease,
  isVivaraYou,
}: Props) {
  const collectionInfo = findCollectionById(collections, collectionId ?? '')
  const tagsData = dataTagsCollection?.tagsData as ITagsData
  const { className, collectionBlock } =
    tagsData?.[collectionId ?? 'default'] ?? tagsData?.default

  return (
    <>
      {!isVivaraYou ? (
        <>
          <div className="tags-container__product-card">
            {collections?.length && collectionId && !collectionBlock && (
              <ReleaseTag
                variant={`pdp-${className}`}
                name={collectionInfo?.name ?? ''}
              />
            )}
          </div>
          {isNewRelease && (!collectionId || collectionBlock) && (
            <ReleaseTag variant="pdp" name="LANÇAMENTO" />
          )}
        </>
      ) : (
        <a href="/colecao/vivara-you/">
          <ReleaseTag
            variant="pdp-vivara-you"
            name={collectionInfo?.name ?? ''}
          />
        </a>
      )}
    </>
  )
}

export function ProductTagsBottom({
  collections,
  isVivaraYou,
}: IProductTagsBottom) {
  const { tags, tagsData } = dataTagsCollectionBottom
  const collectionDataSearch = collections?.find(
    (clusterItem) => tags?.filter((tagId) => clusterItem.id === tagId)?.length
  )

  const collectionIdSearch = collectionDataSearch?.id as keyof typeof tagsData
  const { className } = tagsData?.[collectionIdSearch] ?? {}

  useEffect(() => {
    if (isVivaraYou && collectionDataSearch) {
      hideVivaraYou('.releaseTag-container__pdp-vivara-you', isVivaraYou)
    }
  }, [collectionDataSearch, isVivaraYou])

  return (
    <div className="tags-container__pdp-bottom">
      {collections && collectionDataSearch && (
        <ReleaseTag
          variant={`pdp-${className}`}
          name={`${collectionDataSearch?.name}`}
        />
      )}
    </div>
  )
}
