import React, { useState, Suspense, lazy, useEffect } from 'react'
import { Button } from '@faststore/ui'
import type {
  Filter_FacetsFragment,
  IStoreSelectedFacet,
} from '@generated/graphql'
import BlackArrowUp from 'src/components/icons/BlackArrowUp'

import themeItems from './mocks/themeItems.json'
import './themeTable.scss'

const RenderIcon = lazy(() => import('../../common/MenuDesktop/RenderIcon'))

interface ThemeTableProps {
  themeFacets: Filter_FacetsFragment[]
  setSelectedFacets: React.Dispatch<React.SetStateAction<IStoreSelectedFacet[]>>
  applyFilters?: (newFacets: IStoreSelectedFacet[]) => void
  setIsOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

interface Items {
  items: ThemeItem[]
}
interface ThemeItem {
  label: string
  values: string[] | []
  icon: string
}

interface FacetsValues {
  label: string
  value: string
  selected: boolean
  quantity: number
}

const validateFacets = (values: string[], facets: FacetsValues[]) => {
  return facets.some((facet) => {
    if (values.includes(facet.value)) {
      return true
    }

    return false
  })
}

const ThemeTable = ({
  themeFacets,
  setSelectedFacets,
  applyFilters,
  setIsOpen,
}: ThemeTableProps) => {
  const [currentFacets, setCurrentFacets] = useState<Filter_FacetsFragment[]>()
  const [selectedButton, setSelectedButton] = useState<number>()
  const [showUpCanva, setShowUpCanva] = useState(true)

  const themes: Items = themeItems

  const colecaoFacet = currentFacets?.find((facet) => facet.key === 'colecao')

  const facets =
    colecaoFacet?.__typename === 'StoreFacetBoolean' ? colecaoFacet.values : []

  useEffect(() => {
    if (currentFacets && currentFacets.length > 0) {
      return
    }

    setCurrentFacets(themeFacets)
  }, [themeFacets, currentFacets])

  const handleSelectTheme = (index: number) => {
    setSelectedButton(index)
  }

  const toggleShowUpCanva = () => {
    setShowUpCanva(!showUpCanva)
  }

  const handleApplyTheme = (values: string[]) => {
    const arrayValues: IStoreSelectedFacet[] = [
      { key: 'monte-sua-pulseira-acessorios', value: 'pingente' },
      { key: 'monte-sua-pulseira-acessorios', value: 'corrente' },
    ]

    values.forEach((item: string) => {
      arrayValues.push({ key: 'colecao', value: item })
    })
    const facetsToApply = [...arrayValues]

    setSelectedFacets(facetsToApply)

    if (applyFilters) {
      applyFilters(facetsToApply)
    }

    if (setIsOpen) {
      setIsOpen(false)
    }
  }

  if (!facets?.length) {
    return <></>
  }

  const filteredThemes = themes.items.filter((item) => {
    return validateFacets(item.values, facets as FacetsValues[])
  })

  return (
    <>
      <div className="accordion-assembly theme-table">
        <Button
          onClick={toggleShowUpCanva}
          className={`accordion-assembly__toggle-button toggle-button-canva theme-table ${
            showUpCanva ? '' : 'rotate'
          }`}
        >
          Temas de moments
          <BlackArrowUp />
        </Button>
        <div
          className="accordion-assembly-themes__panel canva-panel"
          aria-expanded={showUpCanva}
        >
          <div className="themes-grid">
            <>
              {filteredThemes.map((item, key) => {
                return (
                  <Button
                    aria-label={item.label}
                    className={key === selectedButton ? 'theme-selected' : ''}
                    key={key}
                    onClick={() => {
                      handleSelectTheme(key)
                      handleApplyTheme(item.values)
                    }}
                  >
                    <Suspense fallback={null}>
                      <RenderIcon name={item.icon} />
                    </Suspense>
                    {item.label}
                  </Button>
                )
              })}
            </>
          </div>
        </div>
      </div>
    </>
  )
}

export default ThemeTable
