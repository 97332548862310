import React, { useMemo } from 'react'
import type { SKUPriceRange } from 'src/components/product/ProductCard/utils/getSKUPriceRange'
import { getSKUPriceRange } from 'src/components/product/ProductCard/utils/getSKUPriceRange'
import { useFormattedPrice } from 'src/sdk/product/useFormattedPrice'
import priceRangeConditions from 'src/components/product/ProductCard/utils/priceRangeConditions'

import type { ProductType } from './types/ProductType'
import type { HandleAvailableProductsType } from './types/HandleAvailableProductsType'

import './product-details.scss'

type ProductSKUPriceRangeProps = {
  breadcrumbs: HandleAvailableProductsType['breadcrumbs']
  product: ProductType
}

export const ProductSKUPriceRange = ({
  breadcrumbs,
  product,
}: ProductSKUPriceRangeProps) => {
  const priceRange: SKUPriceRange | null = useMemo(() => {
    const validatedConditionsPriceRange: boolean =
      priceRangeConditions(breadcrumbs)

    if (!validatedConditionsPriceRange) {
      return null
    }

    return getSKUPriceRange(product?.isVariantOf?.hasVariant)
  }, [breadcrumbs, product])

  const formattedLowestPrice = useFormattedPrice(priceRange?.lowestPrice ?? 0)
  const formattedHighestPrice = useFormattedPrice(priceRange?.highestPrice ?? 0)

  return (
    <>
      {priceRange && (
        <div className="product-details__sku-price-range">
          <span>{`${formattedLowestPrice} — ${formattedHighestPrice}`}</span>
        </div>
      )}
    </>
  )
}
