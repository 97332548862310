import React, { useEffect, useState } from 'react'
import './header-fixed-bar.scss'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import BuyButton from 'src/components/ui/BuyButton'
import { useBuyButton } from 'src/sdk/cart/useBuyButton'
import { useDetailsSpecificationsContext } from 'src/contexts/details-specification-context'

import { HandlePrice } from './DetailsSideBar'

interface HeaderFixedBarProps {
  product: BrowserProductQueryQuery['product']
  priceData: PriceData
  selectedSKU: BrowserProductQueryQuery['product']
  showProductBar?: boolean
}

const HeaderFixedBar = ({
  product,
  priceData,
  selectedSKU,
  showProductBar,
}: HeaderFixedBarProps) => {
  const { sku } = selectedSKU
  const { clusterHighlights } = product
  const { availability, listPrice, lowPrice, price } = priceData

  const isProductAvailable = availability === 'https://schema.org/InStock'

  const buyProps = useBuyButton({
    id: product.id,
    price: selectedSKU?.offers?.offers?.[0]?.price,
    listPrice: selectedSKU?.offers?.offers?.[0]?.listPrice,
    seller: selectedSKU?.offers?.offers?.[0]?.seller,
    quantity: 1,
    itemOffered: {
      sku: selectedSKU.sku,
      name: selectedSKU.name,
      gtin: product.gtin,
      image: product.image,
      brand: product.brand,
      isVariantOf: product.isVariantOf,
      additionalProperty: selectedSKU.additionalProperty,
    },
  })

  const priceFinal = price ?? 0

  const [aboutCollection, setAboutCollection] = useState(false)
  const [specificationDetails, setSpecificationDetails] = useState(false)

  const { detailsSpecifications } = useDetailsSpecificationsContext()

  useEffect(() => {
    const indexCollection = detailsSpecifications.findIndex(
      (item) => item.type === 'Inspiração'
    )

    if (indexCollection !== -1) {
      setAboutCollection(true)
    }

    const indexDetails = detailsSpecifications.filter(
      (el) =>
        el.type === 'Material' ||
        el.type === 'Pedra' ||
        el.type === 'Malha' ||
        el.type === 'Mecanismos' ||
        el.type === 'Resistência' ||
        el.type === 'Material do vidro' ||
        el.type === 'Material da pulseira'
    )

    if (indexDetails.length !== 0) {
      setSpecificationDetails(true)
    }
  }, [detailsSpecifications])

  return (
    <div className={`header-bar-container ${showProductBar ? '' : 'hidden'}`}>
      <div className="content">
        <div className="product-bar">
          <div className="product-bar-name-container">
            <h1 className="title-product-header">{product?.name}</h1>
          </div>
          <nav className="anchor-above-container-header">
            <a href="#complete-o-look" className="anchor-title-header">
              COMPLETE O LOOK
            </a>
            <a href="#description" className="anchor-title-header">
              DESCRIÇÃO
            </a>
            {specificationDetails && (
              <a href="#product-details" className="anchor-title-header">
                DETALHES
              </a>
            )}
            {aboutCollection && (
              <a href="#about-collection" className="anchor-title-header">
                SOBRE A COLEÇÃO
              </a>
            )}
            <a href="#relationships-products" className="anchor-title-header">
              PRODUTOS RELACIONADOS
            </a>
          </nav>
        </div>
        <div className="header-buyBotton">
          <div className="header-buyBotton-priceModule">
            {listPrice > 0 && priceFinal > 0 && (
              <HandlePrice
                lowPrice={lowPrice}
                listPrice={listPrice}
                productId={sku}
                clusterHighlights={clusterHighlights}
                shouldShowinstallment={false}
              />
            )}
          </div>
          <BuyButton
            className="buttonFixedBar"
            disabled={!isProductAvailable}
            {...buyProps}
          >
            {isProductAvailable ? 'ADICIONAR À SACOLA' : 'Indisponível'}
          </BuyButton>
        </div>
      </div>
    </div>
  )
}

export default HeaderFixedBar
