import React from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import { Image } from 'src/components/ui/Image'
import { OrderImagesByRegex } from 'src/utils/OrderImagesByRegex'

interface ListOfProductsToBuyProps {
  product: BrowserProductQueryQuery['product']
  baseClass: string
  redirectToPdp?: (slug: string, searchParams?: string) => void
  isBuy?: boolean
  index: number
}

const ListOfProductsToBuy = ({
  product,
  baseClass,
  redirectToPdp,
  isBuy,
  index,
}: ListOfProductsToBuyProps) => {
  return (
    <li
      className={`${
        index === 0 ? 'selected' : ''
      } ${baseClass}-listProducts-product`}
    >
      <div className={`${baseClass}__image`}>
        <Image
          baseUrl={
            OrderImagesByRegex(product?.image)?.[0]?.url ??
            product?.image?.[0]?.url
          }
          alt={product?.image?.[0]?.alternateName || product?.name}
          aspectRatio={1}
        />
        {isBuy && (
          <div
            id="redirect-buy-button"
            className={`${baseClass}__toBuy`}
            onClick={() => redirectToPdp?.(product?.slug, 'comparedProduct')}
            aria-hidden="true"
          >
            <p id="redirect-buy">Comprar</p>
          </div>
        )}
      </div>
      <div className={`${baseClass}__name ${isBuy ? 'isBuy' : ''}`}>
        <p className={`${baseClass}__name__text  ${isBuy ? 'isBuy' : ''}`}>
          {product?.name}
        </p>
      </div>
    </li>
  )
}

export default ListOfProductsToBuy
