import React from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import { deepObjectCopy } from 'src/utils'

import DetailsItemsComparedProduct from '../DetailsItemsComparedProduct'
import listDetailsJson from '../../data/listDetails.json'

interface IDetailsComparedProduct {
  products: Array<BrowserProductQueryQuery['product']>
  categoryComparedProduct: string
}

export interface IKnowMore {
  images: Array<{
    image: string
    desktop: {
      width: string
      height: string
    }
  }>
  title: string
  description: string
  descriptionSecond?: string
  subsectionTitle?: string
  subsectionImages?: Array<{
    image: string
    title: string
  }>
}

export interface IListDetailsItemJson {
  title: string
  name: string
  titleExtra: boolean
  hasImage: boolean
  knowMore?: IKnowMore
}

function DetailsComparedProduct({
  products,
  categoryComparedProduct,
}: IDetailsComparedProduct) {
  const listDetails = deepObjectCopy(listDetailsJson)

  return (
    <>
      {listDetails?.items?.[categoryComparedProduct]?.map(
        (item: IListDetailsItemJson) => (
          <DetailsItemsComparedProduct
            products={products}
            title={item?.title}
            name={item?.name}
            titleExtra={item?.titleExtra}
            hasImage={item?.hasImage}
            knowMore={item?.knowMore}
            key={item?.name}
          />
        )
      )}
    </>
  )
}

export default DetailsComparedProduct
