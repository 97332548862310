import React, { useEffect, useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import CheckCircleOrangeSmall from 'src/images/svg/check-circle-orange-small'
import { Button } from '@faststore/ui'
import { Image } from 'src/components/ui/Image'
import type { ProductSummary_ProductFragment } from '@generated/graphql'

import type { ClusterHighlights } from './ProductCardAssemble'
import { imgOptions } from './ProductCardAssemble'
import { getValidImages } from './ProductCardAdd'

interface ProductCardAssembleRemoveProps {
  product: ProductSummary_ProductFragment & ClusterHighlights
  shelfClass: string
  aspectRatio: number
  setShowConfirmRemoval: Dispatch<SetStateAction<boolean>>
}

export const ProductCardAssembleRemove = ({
  product,
  shelfClass,
  aspectRatio,
  setShowConfirmRemoval,
}: ProductCardAssembleRemoveProps) => {
  const { name, image: productImages } = product

  const firstValidImage =
    productImages?.length > 1
      ? getValidImages(product?.image)
      : {
          url: product?.image?.[0]?.url ?? '',
          alternateName: product?.image?.[0]?.alternateName ?? '',
        }

  const [cardImage, setCardImage] = useState(firstValidImage)

  useEffect(() => {
    if (product) {
      setCardImage(firstValidImage)
    }
  }, [product])

  return (
    <div className={`${shelfClass}`}>
      <div className="product-checked-icon">
        <CheckCircleOrangeSmall />
      </div>
      <Image
        baseUrl={cardImage?.url}
        alt={cardImage?.alternateName}
        aspectRatio={aspectRatio}
        {...imgOptions}
        imgStyle={{ objectFit: 'contain' }}
      />
      <div className={`${shelfClass}__content`}>
        <h3 className={`${shelfClass}__name-product`}>{name}</h3>
      </div>
      <Button
        id="button-remove-assemble"
        className="btn-assemble-product-remove"
        onClick={() => {
          setShowConfirmRemoval(true)
        }}
      >
        Excluir
      </Button>
    </div>
  )
}
