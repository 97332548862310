import React, { useState, Suspense } from 'react'
import Expandable from 'src/components/ui/Expandable'
import RenderCMS from 'src/components/RenderCMS'

import './styles.scss'

const LazyRenderCMS = React.lazy(() => import('src/components/RenderCMS'))

interface PdpCmsProps {
  sections: Array<{
    name: string
    data: any
  }>
}

function PdpCms({ sections }: Readonly<PdpCmsProps>) {
  const [viewMore, setViewMore] = useState(false)

  const baseClass = 'cms-departament-components-pdp'

  const clickViewMore = () => {
    setViewMore(!viewMore)
  }

  return (
    <>
      {sections?.length > 0 && (
        <section className={`page__section ${baseClass}`}>
          <div className={`${baseClass}-container`}>
            <Expandable title="CONTEÚDO ESPECIAL">
              <RenderCMS sections={sections} fold="above" quantityRender={1} />
              {viewMore && (
                <Suspense fallback={<div>Loading...</div>}>
                  {viewMore && (
                    <LazyRenderCMS
                      sections={sections}
                      fold="below"
                      quantityRender={1}
                    />
                  )}
                </Suspense>
              )}
              {sections?.length > 2 && (
                <div className={`${baseClass}-viewMore`}>
                  <button onClick={clickViewMore}>
                    Ver {!viewMore ? 'mais +' : 'menos -'}
                  </button>
                </div>
              )}
            </Expandable>
          </div>
          {sections?.length > 2 && !viewMore && (
            <div className={`${baseClass}-background gradient`} />
          )}
        </section>
      )}
    </>
  )
}

export default PdpCms
