import React, { useState } from 'react'
import './compared-product.scss'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import Help from 'src/images/svg/icon-help'
import SpinnerLoading from 'src/components/common/SpinnerLoading'

import { verifyCategoryProduct } from './utils/verifyCategoryProduct'
import { getComparedProduct } from './services/getComparedProduct'
import ModalComparedProduct from './components/ModalComparedProduct'

interface ComparedProductProps {
  product: BrowserProductQueryQuery['product']
  setShowProductBar: React.Dispatch<React.SetStateAction<boolean>>
}

export interface ProductsListComparedProps {
  [key: string]: Array<BrowserProductQueryQuery['product']>
}

function ComparedProduct({ product, setShowProductBar }: ComparedProductProps) {
  const [searchingProducts, setSearchingProducts] = useState(false)
  const [productsListCompared, setProductsListCompared] = useState<
    ProductsListComparedProps | false
  >()

  const baseClass = 'comparedProduct'
  const categoryComparedProduct = verifyCategoryProduct(
    product?.breadcrumbList?.itemListElement
  ) as string | undefined

  const handleSeeComparison = async () => {
    if (categoryComparedProduct) {
      setSearchingProducts(true)
      const productsApi = await getComparedProduct(
        categoryComparedProduct,
        product
      )

      setProductsListCompared(productsApi)
      setSearchingProducts(false)

      return true
    }

    return false
  }

  return (
    <>
      {categoryComparedProduct && !productsListCompared && (
        <section id="compared-product">
          <div className={`${baseClass} ${baseClass}-container`}>
            <div className={`${baseClass}-left`}>
              <Help />
            </div>
            <div className={`${baseClass}-right`}>
              <div className={`${baseClass}-title`}>
                <div className={`${baseClass}-title__text`}>
                  <h2>Precisa de ajuda para escolher?</h2>
                </div>
              </div>
              <div className={`${baseClass}-subTitle`}>
                <p className={`${baseClass}-subTitle__text`}>
                  Veja nosso comparativo de itens semelhantes disponíveis
                </p>
              </div>
              <div className={`${baseClass}-seeComparison`}>
                <button
                  id="see-compared-product"
                  className={`${baseClass}-seeComparison-button`}
                  onClick={handleSeeComparison}
                >
                  Ver comparativo
                </button>
                {searchingProducts && <SpinnerLoading />}
              </div>
            </div>
          </div>
        </section>
      )}
      {productsListCompared && categoryComparedProduct && (
        <ModalComparedProduct
          productsListCompared={productsListCompared}
          setProductsListCompared={setProductsListCompared}
          productCurrent={product}
          categoryComparedProduct={categoryComparedProduct}
          setShowProductBar={setShowProductBar}
        />
      )}
    </>
  )
}

export default ComparedProduct
