import React, { useState } from 'react'

import './measure-your-ring.scss'
import FirstStepText from './FirstStep/FirstStepText'
import CardMeasure from './FirstStep/CardMeasure'
import SecondStepText from './SecondStep/SecondStepText'
import Rings from './SecondStep/Rings'

const MeasureYourRing = () => {
  const [isSecondStep, setIsSecondStep] = useState(false)
  const [ppiValue, setPpiValue] = useState(0)

  return (
    <div className="measure-ring">
      {!isSecondStep ? (
        <>
          <FirstStepText />
          <CardMeasure
            setPpiValue={setPpiValue}
            setIsSecondStep={setIsSecondStep}
          />
        </>
      ) : (
        <>
          <button
            className="back-button"
            onClick={() => {
              setIsSecondStep(false)
            }}
          >
            VOLTAR
          </button>
          <SecondStepText />
          <Rings ppiValue={ppiValue} />
        </>
      )}
    </div>
  )
}

export default MeasureYourRing
