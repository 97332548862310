import React, { useCallback, useMemo } from 'react'
import { navigate } from 'gatsby'
import Expandable from 'src/components/ui/Expandable/Expandable'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { Skeleton } from '@faststore/ui'
import { useDetailsSpecificationsContext } from 'src/contexts/details-specification-context'
import { normalizeText } from 'src/utils/normalizeText'

import type { AnchorOffset } from './AnchorMenu'

interface AboutCollectionProps {
  anchorOffset: AnchorOffset | undefined
  isPDPLife: boolean
}

const AboutCollection = ({ anchorOffset, isPDPLife }: AboutCollectionProps) => {
  const { width } = useWindowDimensions()
  const { detailsSpecifications } = useDetailsSpecificationsContext()
  const collections = useMemo(() => {
    return detailsSpecifications
      ?.filter((item) => item?.type === 'Inspiração')
      ?.map((collection) => {
        const {
          name,
          description,
          url_image: urlImage,
          url_image_life: urlImageLife,
          id,
          description_life: descriptionLife,
          alt_image: altImage,
          alt_image_life: altImageLife,
        } = collection

        return {
          name,
          description: isPDPLife ? descriptionLife ?? description : description,
          urlImage: isPDPLife ? urlImageLife ?? urlImage : urlImage,
          altImage: isPDPLife ? altImageLife ?? altImage : altImage,
          id,
        }
      })
  }, [detailsSpecifications, isPDPLife])

  const expansibleLine =
    width < 1024 ? <></> : <div className="expandable-line" />

  const handleGoToCollection = useCallback(
    (collectionName: string) => {
      const collection = normalizeText(collectionName)
        .replace(/ /g, '-')
        .toLowerCase()

      const link = `/colecao/${isPDPLife ? 'life-' : ''}${collection}`

      navigate(link)
    },
    [isPDPLife]
  )

  return (
    <>
      <span
        id="about-collection"
        className="fake-anchor"
        style={anchorOffset}
      />
      <div>
        {expansibleLine}
        {collections?.length !== 0 ? (
          <Expandable title="Sobre a Coleção" lineAfter>
            {collections?.map((item) => (
              <div key={item.id}>
                {expansibleLine}
                <div className="aboutCollection">
                  {item.urlImage && (
                    <img
                      src={item.urlImage}
                      className="aboutCollection-image"
                      alt={item.altImage ?? 'Imagem sobre a coleção'}
                    />
                  )}
                  <div className="aboutCollection-text">
                    <h2 className="aboutCollection-text-title">{item.name}</h2>
                    <div className="aboutCollection-text-description">
                      {item.description}
                    </div>
                    <button
                      className="aboutCollection-text-goToCollection"
                      onClick={() => handleGoToCollection(item.name ?? '')}
                    >
                      Ver toda a coleção
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </Expandable>
        ) : (
          <Skeleton />
        )}
      </div>
    </>
  )
}

export default AboutCollection
