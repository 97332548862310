import type { BrowserProductQueryQuery } from '@generated/graphql'
import React from 'react'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { deepObjectCopy } from 'src/utils'

import DetailsItemComparedProduct from '../DetailsItemsComparedProduct/components'
import listDetailsJson from '../../data/listDetails.json'
import type { IListDetailsItemJson } from '../DetailsComparedProduct/DetailsComparedProduct'

import './values-compared-products.scss'

interface ValuesComparedProductsType {
  products: Array<BrowserProductQueryQuery['product']>
  categoryComparedProduct: string
}

const ValuesComparedProducts = ({
  products,
  categoryComparedProduct,
}: ValuesComparedProductsType) => {
  const baseClass = `modalComparedProduct-details-container`
  const { isMobile, widthInner } = useWindowDimensions()
  const minMaxGrid = isMobile ? 'minmax(100px, 1fr)' : 'minmax(200px, 200px)'
  const quantityProducts = products?.length
  const columnGap = isMobile ? '21px' : '48px'
  const listDetails = deepObjectCopy(listDetailsJson)

  const filteredList = listDetails?.items?.[categoryComparedProduct]?.filter(
    (item: IListDetailsItemJson) => item?.name === 'values'
  )

  return filteredList?.map((item: IListDetailsItemJson) => (
    <div
      className={`${baseClass}-items`}
      key={`${item?.title}-${item?.name}-${item?.titleExtra}`}
    >
      <ul
        style={
          widthInner < 768 || widthInner > 1024
            ? {
                gridTemplateColumns:
                  quantityProducts <= 3
                    ? `repeat(${quantityProducts}, ${minMaxGrid})`
                    : '',
                columnGap: quantityProducts <= 3 ? columnGap : '',
              }
            : {}
        }
        className={`${baseClass}-items__list-items ${item?.name}`}
      >
        {products?.map((product) => (
          <li
            key={product?.id}
            className={`${baseClass}-items__list-items-product`}
          >
            <DetailsItemComparedProduct
              product={product}
              name={item?.name}
              hasImage={item?.hasImage}
            />
          </li>
        ))}
      </ul>
    </div>
  ))
}

export default ValuesComparedProducts
