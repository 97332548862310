import React, { useEffect, useState } from 'react'

import { DEFAULT_RINGS } from './Mock/DefaultRings'

interface RingsProps {
  ppiValue: number
}

interface RingsType {
  oldDiameter: number
  number: number
  diameter: number
}

const Rings = ({ ppiValue }: RingsProps) => {
  const [actualRing, setActualRing] = useState({} as RingsType)
  const [convertedRings, setConvertedRings] = useState([] as RingsType[])

  function filterActualRing(ringNumber: number) {
    const filteredRing = convertedRings?.filter(
      (ring) => ring?.number === ringNumber
    )

    setActualRing(filteredRing[0])
  }

  function changeSizeRing(control: 'decrease' | 'increase') {
    if (control === 'increase' && actualRing?.number < 30) {
      filterActualRing(actualRing?.number + 1)
    }

    if (control === 'decrease' && actualRing?.number > 5) {
      filterActualRing(actualRing?.number - 1)
    }
  }

  useEffect(() => {
    const pixelRings = DEFAULT_RINGS?.map((e) => {
      return {
        ...e,
        diameter: ((e?.diameter / 10) * ppiValue) / 2.54,
        oldDiameter: e?.diameter,
      }
    })

    setActualRing(pixelRings[8])
    setConvertedRings(pixelRings)
  }, [ppiValue])

  return (
    <div className="ring-selector">
      <div className="ring-controller">
        <div className="background-ring">
          <div
            className="ring"
            style={{
              width: `${actualRing?.diameter}px`,
              height: `${actualRing?.diameter}px`,
            }}
          >
            <div className="ring-diameter">
              <p>
                <span>&nbsp; Aro {actualRing?.number}&nbsp;</span>
              </p>
            </div>
          </div>
        </div>

        <div className="control-size">
          <button
            className="decrease-size"
            onClick={() => changeSizeRing('decrease')}
          >
            -
          </button>
          <input
            className="range-control"
            type="range"
            min="5"
            max="30"
            step="1"
            value={actualRing?.number}
            onChange={(e) => filterActualRing(parseFloat(e.target.value))}
            style={{
              background: `linear-gradient(to right, #F08769 0%, #F08769 ${
                100 * ((actualRing?.number - 5) / 25)
              }%, #D2D2D2 ${
                100 * ((actualRing?.number - 5) / 25)
              }%, #D2D2D2 100%)`,
            }}
          />
          <button
            className="decrease-size"
            onClick={() => changeSizeRing('increase')}
          >
            +
          </button>
        </div>
      </div>
    </div>
  )
}

export default Rings
