import React from 'react'
import OutOfStock from 'src/components/product/OutOfStock'

import { HandlePrice } from './DetailsSideBar'

interface OutOfStockSectionProps {
  price: number
  listPrice: number
  sku: string
  productId: string
}

export const OutOfStockSection = ({
  price,
  listPrice,
  sku,
  productId,
}: OutOfStockSectionProps) => {
  return (
    <>
      {price && listPrice ? (
        <section className="product-details__values">
          <HandlePrice
            lowPrice={price}
            listPrice={listPrice}
            productId={productId}
            clusterHighlights={null}
          />
        </section>
      ) : (
        <></>
      )}
      <OutOfStock
        title=""
        notificationMsg="Informe seu e-mail que avisaremos quando o produto estiver disponível!"
        buttonTxt="Avise-me"
        icon={<></>}
        skuId={sku}
      />
    </>
  )
}
