import React from 'react'
import IconButton from 'src/components/ui/VivaraIconButton'
import CloseIcon from 'src/components/icons/Close'
import { deepObjectCopy } from 'src/utils'

import notifications from './data/notifications.json'

import './wishlist-notification.scss'

interface IWishlistNotification {
  typeNotification: string
  setWasList: React.Dispatch<React.SetStateAction<string | undefined>>
  pageWishlist?: boolean
}

function WishlistNotification({
  typeNotification,
  setWasList,
  pageWishlist,
}: IWishlistNotification) {
  const baseClass = 'wishlistNotification'
  const newNotifications = deepObjectCopy(notifications)
  const message = newNotifications?.[typeNotification]

  return (
    <>
      {message && (
        <div
          className={`${baseClass}-list__container ${
            pageWishlist ? 'pageWishlist' : ''
          }`}
        >
          <IconButton
            icon={<CloseIcon />}
            aria-label={message?.ariaLabel}
            onClick={() => setWasList(undefined)}
            className={`${baseClass}-list__container-iconButton notification`}
          />
          <p className={`${baseClass}-list__text`}>{message?.label}</p>
        </div>
      )}
    </>
  )
}

export default WishlistNotification
