import React, { useState, useEffect } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import { Button } from '@faststore/ui'
import { Image } from 'src/components/ui/Image'

import type { SelectedProductsAssemble } from '../Assembly/types'
import { AssembleModalAlreadyHaveChain } from '../AssembleModals/AssembleModalsChain'

export function getValidImages(
  productImages: Array<{ url: string; alternateName: string }>
) {
  const validImages = productImages.filter(
    (image) => !image?.url?.includes('mon')
  )

  return {
    url: validImages?.[0]?.url ?? productImages?.[0]?.url ?? '',
    alternateName:
      validImages?.[0]?.alternateName ?? productImages?.[0]?.url ?? '',
  }
}

interface MouseOverProps {
  productInfo: SelectedProductsAssemble
  aspectRatio: number
  setIsSelected: Dispatch<SetStateAction<boolean>>
  setIsAddButton: Dispatch<SetStateAction<boolean>>
  setShowNotificationModal: Dispatch<SetStateAction<boolean>>
  onClickAddCardProduct: (product: SelectedProductsAssemble) => Promise<number>
  onClickAddChain: (product: SelectedProductsAssemble) => Promise<void>
  setCanvaIndex: React.Dispatch<React.SetStateAction<number>>
  isChain: boolean
  hasChainSelected: boolean
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
}

export const HandleMouseOver = ({
  productInfo,
  aspectRatio,
  setIsSelected,
  setIsAddButton,
  setCanvaIndex,
  onClickAddCardProduct,
  setShowNotificationModal,
  isChain,
  onClickAddChain,
  hasChainSelected,
  selectedProductsAssembleBraceletChain,
}: MouseOverProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const AddProduct = async () => {
    await onClickAddChain(productInfo)

    setIsSelected(true)
    setIsAddButton(false)
  }

  const CancelProduct = () => {
    setIsAddButton(false)
  }

  const { image: productImages } = productInfo.product

  const [img] = productImages
  const [cardImage, setCardImage] = useState({ url: '', alternateName: '' })

  useEffect(() => {
    if (productInfo) {
      const validImage = getValidImages(productInfo?.product?.image)

      setCardImage(validImage)
    }
  }, [productInfo, cardImage])

  return (
    <>
      {selectedProductsAssembleBraceletChain && hasChainSelected && isChain && (
        <AssembleModalAlreadyHaveChain
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          AddProduct={AddProduct}
          CancelProduct={CancelProduct}
        />
      )}
      <div className="asssemble-product--addContent">
        <Image
          baseUrl={cardImage.url ?? img.url}
          alt={cardImage.alternateName}
          aspectRatio={aspectRatio}
        />
        <Button
          className="assemble-product--add"
          onClick={async () => {
            if (!isChain) {
              const newIndex = await onClickAddCardProduct(productInfo)

              if (newIndex !== -1) {
                setCanvaIndex(newIndex)
                setIsSelected(true)
              } else {
                setShowNotificationModal(true)
                setIsAddButton(false)
              }
            }

            if (isChain && !hasChainSelected) {
              AddProduct()
            }

            if (isChain && hasChainSelected) {
              setIsOpen(true)
            }
          }}
        >
          ADICIONAR
        </Button>
        <Button
          className="assemble-product--cancel"
          onClick={() => {
            CancelProduct()
          }}
        >
          CANCELAR
        </Button>
      </div>
    </>
  )
}
