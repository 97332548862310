import React from 'react'

export const IconAssemblyButton = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="16"
    viewBox="0 0 18 16"
    fill="none"
  >
    <path
      d="M7.92572 2.66675H3.00313C2.63012 2.66675 2.27238 2.80722 2.00862 3.05727C1.74486 3.30732 1.59668 3.64646 1.59668 4.00008V13.3334C1.59668 13.687 1.74486 14.0262 2.00862 14.2762C2.27238 14.5263 2.63012 14.6667 3.00313 14.6667H12.8483C13.2213 14.6667 13.5791 14.5263 13.8428 14.2762C14.1066 14.0262 14.2548 13.687 14.2548 13.3334V8.66675"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.2003 1.66665C13.48 1.40144 13.8595 1.25244 14.2551 1.25244C14.6508 1.25244 15.0302 1.40144 15.31 1.66665C15.5897 1.93187 15.7469 2.29158 15.7469 2.66665C15.7469 3.04173 15.5897 3.40144 15.31 3.66665L8.62931 9.99999L5.81641 10.6667L6.51963 7.99999L13.2003 1.66665Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
