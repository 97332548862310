import React from 'react'
import type { ProductType } from 'src/components/sections/ProductDetails/types/ProductType'
import ProgressiveDiscountBar from 'src/components/sections/ProgressiveDiscountBar'
import PromotionBar from 'src/components/sections/PromotionBar'

interface Props {
  product: ProductType
  componentType: string
}

function PromotionBarContainer({ product, componentType }: Props) {
  return (
    <>
      <PromotionBar product={product} componentType={componentType} />
      <ProgressiveDiscountBar product={product} componentType={componentType} />
    </>
  )
}

export default PromotionBarContainer
