import type { StepTutorialCardProps } from '../StepTutorialCard/StepTutorialCard'
import Step1 from '../../../images/assemble-your-bracelet/onboarding/step-1.gif'
import Step2 from '../../../images/assemble-your-bracelet/onboarding/step-2.gif'
import Step3 from '../../../images/assemble-your-bracelet/onboarding/step-3.gif'
import Step4 from '../../../images/assemble-your-bracelet/onboarding/step-4.png'
import Step5 from '../../../images/assemble-your-bracelet/onboarding/step-5.png'
import Step6 from '../../../images/assemble-your-bracelet/onboarding/step-6.png'

export const cardContent: StepTutorialCardProps[] = [
  {
    step: 1,
    title: 'escolha os pingentes',
    imageUrl: Step1,
    description:
      'Selecione seus pingentes através dos filtros. Para cada pulseira, você poderá adicionar 11 pingentes e uma corrente de segurança.',
  },
  {
    step: 2,
    title: 'visualize a montagem',
    imageUrl: Step2,
    description:
      'Pingentes, separadores e clipers: cada pingente adicionado aparecerá na sua pulseira, começando pelo centro.',
  },
  {
    step: 3,
    title: 'Edite sua pulseira',
    imageUrl: Step3,
    description:
      'Para excluir algum pingente, clique em “Editar” para visualizar os itens escolhidos. Depois é só ir em “Concluir” para ver o resultado.',
  },
  {
    step: 4,
    title: 'complete com uma corrente de segurança',
    imageUrl: Step4,
    description:
      'Se o modelo escolhido não incluir uma corrente de segurança, cogite adicionar uma para manter seus pingentes travados e seguros.',
  },
  {
    step: 5,
    title: 'Revise sua pulseira',
    imageUrl: Step5,
    description:
      'Para finalizar, revise sua pulseira e confirme o valor total. Evite sair na montagem para não perder a sua personalização.',
  },
  {
    step: 6,
    title: 'adicione á sacola',
    imageUrl: Step6,
    description:
      'Tudo pronto! Você pode adicionar à sacola e seguir para o pagamento ou começar uma nova pulseira!',
  },
]
