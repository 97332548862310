import React, { useRef } from 'react'
import './styles.scss'
import type { IOProduct } from 'src/components/sections/PlpSellerStore/types'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import Slider from 'react-slick'
import settings from 'src/configs/slider-buy-together'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import BuyTogetherItem from './BuyTogetherItem/BuyTogetherItem'

interface Props {
  product: BrowserProductQueryQuery['product']
  buyTogetherProducts: IOProduct[]
}

const BuyTogether = ({ product, buyTogetherProducts }: Props) => {
  const { isWindowBiggerThanTablet } = useWindowDimensions()
  const sliderRef = useRef<Slider>(null)

  const uniqueBuyTogetherProducts = buyTogetherProducts.filter(
    (buyTogetherProduct, index, self) =>
      index ===
      self.findIndex((p) => p.productId === buyTogetherProduct.productId)
  )

  if (uniqueBuyTogetherProducts?.length === 0) {
    return <></>
  }

  const hasMoreThanTwoBuyTogetherProduct = uniqueBuyTogetherProducts.length > 2

  return (
    <div
      className={`buyTogether ${
        !hasMoreThanTwoBuyTogetherProduct && 'twoProduct'
      }`}
    >
      <h3>Compre junto</h3>
      <Slider
        ref={sliderRef}
        infinite={isWindowBiggerThanTablet && hasMoreThanTwoBuyTogetherProduct}
        {...settings}
      >
        {uniqueBuyTogetherProducts?.map((buyTogetherProduct, index) => {
          const { productId } = buyTogetherProduct

          return (
            <BuyTogetherItem
              productCurrent={product}
              productBuyTogether={buyTogetherProduct}
              key={productId ?? index}
            />
          )
        })}
      </Slider>
    </div>
  )
}

export function filterBuyTogetherAvailableProducts(
  buyTogetherProducts: IOProduct[]
) {
  return buyTogetherProducts?.filter((buyTogetherProduct) =>
    buyTogetherProduct?.items?.some((item) =>
      item?.sellers?.some(
        (seller) => seller?.commertialOffer?.AvailableQuantity > 0
      )
    )
  )
}

export default BuyTogether
