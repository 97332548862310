export function convertDate(format: string, time: number) {
  if (format) {
    const date = new Date()

    let strDay
    let strMonth
    let strYear

    if (time) {
      date.setTime(time)
    }

    if (format.indexOf('/') >= 1) {
      const formatSplit = format.split('/')

      strDay = formatSplit?.[0]
      strMonth = formatSplit?.[1]
      strYear = formatSplit?.[2]
    }

    const day = convertDay(strDay, date)
    const month = convertMonth(strMonth, date)
    const year = convertYear(strYear, date)

    if (strDay && strMonth && strYear) {
      return `${day}/${month}/${year}`
    }
  }

  return Date.now()
}

export function convertDay(strDay: string | undefined, date: Date) {
  let day

  if (strDay === 'dd' || strDay === 'DD') {
    day = date.getDate()

    if (strDay === 'DD' && date.getDate() <= 9) {
      day = `0${day}`
    }
  }

  return day
}

export function convertMonth(strMonth: string | undefined, date: Date) {
  let month

  if (strMonth === 'mm' || strMonth === 'MM') {
    month = date.getMonth() + 1

    if (strMonth === 'MM' && date.getMonth() < 9) {
      month = `0${month}`
    }
  }

  return month
}

export function convertYear(strYear: string | undefined, date: Date) {
  let year

  if (strYear === 'YYYY') {
    year = date.getFullYear()
  }

  return year
}
