import React, { useRef, useState, lazy, Suspense } from 'react'
import type {
  KeyboardEvent,
  ChangeEvent,
  MouseEvent,
  Dispatch,
  SetStateAction,
} from 'react'
import axios from 'axios'
import { useSession } from 'src/sdk/session'
import BaseModal from 'src/components/modal/BaseModal'
import Spinner from 'src/components/common/Spinner'
import { Button } from '@faststore/ui'
import { BraceletIcon } from 'src/images/assemble-your-bracelet'
import { regionalizeUser } from 'src/utils/regionalizeUser'
import lazyRetry from 'src/utils/lazyRetry'

import InputMask from '../../ui/InputMask'
import './RegionModalAssembleYourBracelet.scss'

const UseLocationButton = lazy(() =>
  lazyRetry(
    () => import('src/components/modal/RegionModal/UseLocationButton'),
    'UseLocationButton'
  )
)

interface RegionModalAssembleYourBraceletProps {
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
}

export const RegionModalAssembleYourBracelet = ({
  openModal,
  setOpenModal,
}: RegionModalAssembleYourBraceletProps) => {
  const [cep, setCep] = useState('')

  const [isLoading, setIsLoading] = useState(false)
  const [inputState, setInputState] = useState('blank')

  const { isValidating, ...partialSession } = useSession()
  const inputEl = useRef<HTMLButtonElement>(null)

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputState('blank')
    const inputValue = event.target.value

    setCep(inputValue)
  }

  const handleSubmit = async (event?: MouseEvent<HTMLButtonElement>) => {
    event?.preventDefault()
    setIsLoading(true)

    if ((!cep.match(/^[0-9]{5}-[0-9]{3}$/) || cep === '') && event) {
      setInputState('invalid')
      setIsLoading(false)

      return
    }

    const { data: postalCodeData } = await axios.post(
      '/api/getPostalCodeData',
      {
        postalCode: cep,
      }
    )

    if (postalCodeData) {
      const { state, city } = postalCodeData

      if (state || city) {
        await regionalizeUser({
          cep,
          postalCodeData,
          partialSession,
        })
      } else {
        setInputState('invalid')
        setIsLoading(false)
      }
    }
  }

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      if (inputEl.current) {
        inputEl.current.click()
      }
    }
  }

  return (
    <BaseModal
      className="region-modal-assemble-your-bracelet"
      isOpen={openModal}
      onCloseButtonClick={() => setOpenModal(false)}
    >
      <form className="region-modal-assemble-your-bracelet__form">
        <BraceletIcon />
        <p className="region-modal-assemble-your-bracelet__description">
          Para garantir que sua pulseira completa chegue até você, informe sua
          localização, por favor.
        </p>
        <div className="region-modal-assemble-your-bracelet__input-wrapper">
          <div
            className={`region-modal-assemble-your-bracelet__input-container ${inputState}`}
          >
            <InputMask
              mask="cep"
              type="tel"
              placeholder="Digite seu CEP"
              className={`region-modal-assemble-your-bracelet__input ${inputState}`}
              onChange={handleInputChange}
              inputMode="numeric"
              onKeyDown={(event: KeyboardEvent) => handleKeyDown(event)}
            />
            {inputState === 'invalid' ? (
              <div className="region-modal-assemble-your-bracelet__cep-invalid">
                CEP Inválido. Digite novamente!
              </div>
            ) : null}
          </div>
          <Button
            className="region-modal-assemble-your-bracelet__btn"
            type="submit"
            ref={inputEl}
            onClick={(e) => {
              handleSubmit(e)
            }}
          >
            {isLoading ? <Spinner /> : 'OK'}
          </Button>
        </div>
        <Suspense fallback={null}>
          <UseLocationButton
            handleSubmit={handleSubmit}
            setCep={setCep}
            cep={cep}
          />
        </Suspense>
      </form>
    </BaseModal>
  )
}
