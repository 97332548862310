import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { navigate } from 'gatsby'
import CloseIcon from 'src/components/icons/Close'
import { Image } from 'src/components/ui/Image'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import { HandlePrice } from 'src/components/sections/ProductDetails/DetailsSideBar'
import useOffer from 'src/components/sections/ProductDetails/useOffer'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { useSelectedSKU } from 'src/contexts/ProductContext/useProductContext'
import { HeaderSizeContext } from 'src/Layout'
import { OrderImagesByRegex } from 'src/utils/OrderImagesByRegex'

import type { ProductsListComparedProps } from '../../ComparedProduct'
import './modal-compared-product.scss'
import DetailsComparedProduct from '../DetailsComparedProduct'
import MenuComparedProduct from '../MenuComparedProduct'
import ProductsToBuy from '../ProductsToBuy'
import NotResultComparedProduct from '../NotResultComparedProduct'
import ValuesComparedProducts from '../ValuesComparedProducts/ValuesComparedProducts'

interface ModalComparedProductProps {
  productsListCompared: ProductsListComparedProps
  setProductsListCompared: React.Dispatch<
    React.SetStateAction<false | ProductsListComparedProps | undefined>
  >
  productCurrent: BrowserProductQueryQuery['product']
  categoryComparedProduct: string
  setShowProductBar: React.Dispatch<React.SetStateAction<boolean>>
}

function ModalComparedProduct({
  productsListCompared,
  setProductsListCompared,
  productCurrent,
  categoryComparedProduct,
  setShowProductBar,
}: ModalComparedProductProps) {
  const baseClass = 'modalComparedProduct'
  const headerSizeContext = useContext(HeaderSizeContext)
  const [productIdCurrent, setProductIdCurrent] = useState<string>(
    productCurrent?.id
  )

  const [menuSelected, setMenuSelected] = useState<string>('')
  const selectedSKU = useSelectedSKU(
    productCurrent
  ) as BrowserProductQueryQuery['product']

  const { listPrice, lowPrice } = useOffer(productCurrent, selectedSKU)
  const { isMobile } = useWindowDimensions()
  const sizeSvg = isMobile ? '12' : '15'
  const imageOrdened = OrderImagesByRegex(productCurrent?.image)

  const hideScrollAndSetScrollComparedProduct = (hide = false) => {
    const body = document?.body
    const header = document?.querySelector('.fixed-header') as HTMLElement
    const rightSection = document?.querySelector(
      '.product-details__right-section'
    ) as HTMLElement

    setShowProductBar(false)
    setTimeout(() => window?.scrollTo(0, 0), 10)

    if (!(rightSection && body)) {
      return
    }

    body.style.cssText = `${!hide ? 'overflow: hidden;' : ''}`
    rightSection.style.cssText = `${
      !hide ? 'z-index: 2; overflow: hidden;' : ''
    }`

    if (header) {
      header.style.position = !hide ? 'relative' : 'static'
    }
  }

  const closeModal = () => {
    window?.scrollTo(0, 0)

    hideScrollAndSetScrollComparedProduct(true)
    setProductsListCompared(undefined)
  }

  const redirectToPdp = (slug: string, searchParams?: string) => {
    let urlNavigate = `/${slug}/p`

    if (searchParams) {
      urlNavigate += `?${searchParams}`
    }

    navigate(urlNavigate)
    closeModal()
  }

  const updatePositionModalRelativeMenu = () => {
    const modalElement = document.querySelector(
      '#modal-compared-product'
    ) as HTMLDivElement

    const headerHeight =
      document.querySelector('.fixed-header')?.scrollHeight ?? 1

    if (modalElement) {
      modalElement.style.cssText = `top: ${headerHeight}px; height: calc(100% - ${headerHeight}px);`
    }
  }

  const closeModalChangeProduct = useCallback(
    (currentProductId: string, newProductId: string) => {
      if (currentProductId && currentProductId !== newProductId) {
        hideScrollAndSetScrollComparedProduct(true)

        setProductIdCurrent(newProductId)
        setProductsListCompared(undefined)

        return true
      }

      return false
    },
    [setProductsListCompared]
  )

  useEffect(() => {
    if (productsListCompared) {
      hideScrollAndSetScrollComparedProduct()
    }
  })

  useEffect(() => {
    closeModalChangeProduct(productIdCurrent, productCurrent?.id)

    return () => {
      if (document?.body) {
        document.body.style.cssText = ''
      }
    }
  }, [
    closeModalChangeProduct,
    productCurrent?.id,
    productIdCurrent,
    productsListCompared,
  ])

  useEffect(() => {
    if (!isMobile) {
      updatePositionModalRelativeMenu()
    }
  }, [headerSizeContext, isMobile])

  return (
    <section id="modal-compared-product">
      <div className={`${baseClass} ${baseClass}-container`}>
        <div className={`${baseClass}-title`}>
          <h2 className={`${baseClass}-title__text`}>Comparador de Produtos</h2>
          <CloseIcon
            color="#000"
            strokeWidth="1.5"
            width={sizeSvg}
            height={sizeSvg}
            onClick={closeModal}
          />
        </div>
        <div className={`${baseClass}-productInfo`}>
          <div className={`${baseClass}-productInfo__image`}>
            <Image
              baseUrl={
                imageOrdened?.[0]?.url ?? productCurrent?.image?.[0]?.url
              }
              alt={imageOrdened?.[0]?.alternateName || productCurrent?.name}
              aspectRatio={1}
            />
          </div>
          <div
            className={`${baseClass}-productInfo__name ${
              !isMobile ? 'handlePrice' : ''
            }`}
          >
            <h3
              className={`${baseClass}-productInfo__name__title-text ${
                !isMobile ? 'productInfo-name__title-text' : ''
              }`}
            >
              {productCurrent?.name}
            </h3>
            {!isMobile && lowPrice > 0 && (
              <HandlePrice
                lowPrice={lowPrice}
                listPrice={listPrice}
                productId={productCurrent?.sku}
                clusterHighlights={productCurrent?.clusterHighlights}
                shouldShowinstallment
              />
            )}
          </div>
        </div>
        <div className={`${baseClass}-details`}>
          <MenuComparedProduct
            menuSelected={menuSelected}
            setMenuSelected={setMenuSelected}
            productsListCompared={productsListCompared}
          />
          <div className={`${baseClass}-details-main`}>
            {productsListCompared?.[menuSelected]?.length > 0 ? (
              <>
                <ProductsToBuy
                  products={productsListCompared?.[menuSelected]}
                  redirectToPdp={redirectToPdp}
                  setLink
                />
                <DetailsComparedProduct
                  products={productsListCompared?.[menuSelected]}
                  categoryComparedProduct={categoryComparedProduct}
                />
                <ProductsToBuy
                  products={productsListCompared?.[menuSelected]}
                  isBuy
                  buttonBack={closeModal}
                  redirectToPdp={redirectToPdp}
                />
                <ValuesComparedProducts
                  products={productsListCompared?.[menuSelected]}
                  categoryComparedProduct={categoryComparedProduct}
                />
              </>
            ) : (
              <NotResultComparedProduct
                redirectToPdp={redirectToPdp}
                product={productCurrent}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default memo(ModalComparedProduct)
