import { Button, RadioGroup, RadioOption } from '@faststore/ui'
import type {
  BrowserProductQueryQuery,
  SkuFragmentFragment,
} from '@generated/graphql'
import type { FC } from 'react'
import React, { useState, useEffect, useRef } from 'react'
import SelectArrowIcon from 'src/components/icons/SelectArrow'
import useProductCategory from 'src/sdk/product/useProductCategory'
import { clearUrlProductIdentifer } from 'src/utils/product/clearUrlProductIdentifier'

import skuSizeRing from './skuSizeRing'

import './sku-selector.scss'

export interface SkuSelectorProps {
  defaultSku: string
  variants: SkuFragmentFragment[]
  categoryTree: Array<{
    item: string
    name: string
    position: number
  }>
  setSelectedSKU: (
    val: number
  ) => void | React.Dispatch<React.SetStateAction<number>>
  selectedSKU?: SkuFragmentFragment
  selectedKitLookSKU?: number
  kitLookState?: {
    selectedSKU: BrowserProductQueryQuery['product']
    setSelectedSKU: (newProduct: BrowserProductQueryQuery['product']) => void
  }
  setAlertProductByRegion?: React.Dispatch<React.SetStateAction<boolean>>
}

const skuMeasureName = (measureName: string) => {
  const hasMeasure = ['Ring']

  return hasMeasure.includes(measureName)
}

const SkuSelector: FC<SkuSelectorProps> = ({
  categoryTree,
  variants,
  defaultSku,
  setSelectedSKU,
  selectedSKU,
  selectedKitLookSKU,
  kitLookState,
  setAlertProductByRegion,
}) => {
  const [radioSelectedSku, setRadioSelectedSku] = useState<string>(defaultSku)
  const [isSkuSelectorActive, setIsSkuSelectorActive] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  let isGift = false

  variants.some((item) => {
    const name = item.name.toLowerCase()
    const includesGiftCard =
      name.includes('cartão presente') ||
      name.includes('cartao presente') ||
      name.includes('vale presente')

    if (includesGiftCard) {
      isGift = true
    }

    return isGift
  })

  const closeSkuSelector = () => {
    setIsSkuSelectorActive(false)
  }

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        closeSkuSelector()
      }
    }

    if (isSkuSelectorActive) {
      document.addEventListener('mousedown', handleClickOutside)
    } else {
      document.removeEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSkuSelectorActive])

  const updateSku = (newSku: string) => {
    if (newSku === kitLookState?.selectedSKU.sku) {
      return
    }

    variants.forEach((element) => {
      if (element.sku === newSku && kitLookState) {
        const newProduct = {
          ...kitLookState.selectedSKU,
          additionalProperty: element.additionalProperty,
          description: element.description,
          name: element.name,
          offers: element.offers,
          sku: element.sku,
          id: element.sku,
          slug: `${kitLookState.selectedSKU.slug.split('--')[0]}--${
            element.sku
          }`,
        }

        kitLookState.setSelectedSKU(newProduct)
      }
    })
  }

  const skuFindVariants = variants?.findIndex(
    (variant) => variant?.sku === radioSelectedSku
  )

  if (
    (!selectedKitLookSKU || skuFindVariants !== selectedKitLookSKU) &&
    skuFindVariants > -1
  ) {
    setSelectedSKU(skuFindVariants)
  }

  const { measureType, measureName } = useProductCategory({
    categoryTree,
  })

  const SelectAndClose = (skuId: string) => {
    const sku = variants?.findIndex((variant) => variant?.sku === skuId)
    const urlParams = new URLSearchParams(window?.location?.search)

    if (sku === -1) {
      return
    }

    kitLookState && updateSku(skuId)
    clearUrlProductIdentifer(urlParams)
    setAlertProductByRegion?.(false)
    setSelectedSKU(sku)
    setIsSkuSelectorActive(false)
  }

  return (
    <>
      {!kitLookState && (
        <span className="sku-measures-span">
          {isGift ? 'Valor' : selectedSKU?.additionalProperty?.[0]?.name}
        </span>
      )}
      <div className="sku-selector_container" ref={containerRef}>
        <Button
          className="sku-selector-open-button"
          data-productid={selectedSKU?.sku}
          onClick={() => {
            setIsSkuSelectorActive(!isSkuSelectorActive)
          }}
        >
          <p>
            {kitLookState &&
              (kitLookState?.selectedSKU ?? selectedSKU)
                ?.additionalProperty?.[0]?.name}{' '}
            {(kitLookState?.selectedSKU?.sku || selectedSKU?.sku) &&
              ((kitLookState?.selectedSKU ?? selectedSKU)
                ?.additionalProperty?.[0]?.value ||
                measureType)}{' '}
            {measureName === 'Necklace' && <em>mm</em>}
          </p>
          <SelectArrowIcon />
        </Button>
        <div
          className={
            isSkuSelectorActive
              ? 'sku-selector__section_selector'
              : 'sku-selector__section_selector__disabled'
          }
        >
          <div className="radio-group">
            <RadioGroup
              name="sku-radio-group"
              selectedValue={radioSelectedSku}
              onChange={(v) => {
                setRadioSelectedSku(v?.currentTarget?.value)
                SelectAndClose(v?.currentTarget?.value)
              }}
            >
              {variants?.map((variant) => {
                const variantValue =
                  variant?.additionalProperty?.[0]?.value?.replace(',', '.')

                if (!variantValue) {
                  return <></>
                }

                const skuAvailability =
                  variant?.offers?.offers?.length > 0
                    ? variant?.offers?.offers?.[0]?.availability
                    : 'https://schema.org/InStock'

                const isNotAvailable =
                  skuAvailability !== 'https://schema.org/InStock'

                return (
                  <RadioOption
                    label={variantValue}
                    value={variant?.sku}
                    key={variant?.sku}
                    data-fs-radio-disabled={isNotAvailable}
                    disabled={isNotAvailable}
                  >
                    <div className="radio-group__divider" />
                    <span data-fs-radio-disabled={isNotAvailable}>
                      {variantValue}
                      {measureName === 'Necklace' ? <em>mm</em> : ''}
                    </span>
                    {skuMeasureName(measureName) && (
                      <span
                        className="option-size"
                        style={{ display: isGift ? 'none' : 'block' }}
                      >
                        {skuSizeRing?.[variantValue]?.size} cm
                      </span>
                    )}
                  </RadioOption>
                )
              })}
            </RadioGroup>
          </div>
        </div>
      </div>
    </>
  )
}

export default SkuSelector
