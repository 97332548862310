import type { ProductWithDetails } from 'src/pages/[slug]/p'

function getTaxonomy(product?: ProductWithDetails) {
  const breadcrumbName = product?.breadcrumbList.itemListElement?.map(
    (breadcrumb) => breadcrumb.name
  )

  const taxonomy = breadcrumbName?.filter((name) => name !== product?.name)

  taxonomy?.shift()

  return taxonomy
}

export default getTaxonomy
