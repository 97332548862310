import type { ShippingSla } from 'src/components/product/ProductKitLook/types'

const daysInNumber = (days: string) => {
  if (!days) {
    return false
  }

  if (days.includes('h')) {
    return Number(days?.replace('h', '')) / 24 // convert hour to days
  }

  return Number(days?.replace('bd', ''))
}

export const cheaperSla = (slas: ShippingSla[]) => {
  const slaFinal: ShippingSla[] = []

  for (const sla of slas) {
    if (slaFinal.length === 0) {
      slaFinal.unshift(sla)
    } else if (sla?.price < slaFinal[0]?.price) {
      slaFinal.unshift(sla)
    } else if (sla?.price === slaFinal[0]?.price) {
      daysInNumber(sla.shippingEstimate) <
        daysInNumber(slaFinal[0]?.shippingEstimate) && slaFinal.unshift(sla)
    }
  }

  return slaFinal
}

export const fasterSla = (slas: ShippingSla[]) => {
  let shippingSla: ShippingSla | undefined

  for (const sla of slas) {
    if (!shippingSla) {
      shippingSla = sla
      continue
    }

    if (
      daysInNumber(sla.shippingEstimate) <
      daysInNumber(shippingSla.shippingEstimate)
    ) {
      shippingSla = sla
    } else if (
      daysInNumber(sla.shippingEstimate) ===
      daysInNumber(shippingSla.shippingEstimate)
    ) {
      shippingSla = sla?.price < shippingSla?.price ? sla : shippingSla
    }
  }

  return shippingSla
}

export function canBeDeliveredBeforeTheHoliday(
  sla: SLAMinimumType,
  holidayDate: string // yyyy-mm-dd
): boolean {
  if (!sla || !sla?.shippingEstimate) {
    return false
  }

  const now = new Date()
  const holiday = new Date(holidayDate)

  now.setDate(now.getDate() + +sla.shippingEstimate.replace(/\D/g, ''))

  return now < holiday
}
