import React, { useEffect, useState } from 'react'
import './styles.scss'
import axios from 'axios'

type MasterDataGivex = {
  textHowUse: string
  textWhereUse: string
  imageHowUse: string
  imageWhereUse: string
}

function DetailsOfProductGivex() {
  const [responseData, setResponseData] = useState<MasterDataGivex[] | null>()

  useEffect(() => {
    const fetchData = async () => {
      if (responseData) {
        return
      }

      try {
        const response = await axios.get('/api/getProductGivexDetails')

        setResponseData(response.data)
      } catch (error) {
        setResponseData(null)
        console.error('Erro ao buscar os dados:', error)
      }
    }

    fetchData()
  }, [responseData])

  if (!responseData || !responseData?.length) {
    return <></>
  }

  const [textWhereUse, textHowUse, imageHowUse, imageWhereUse] = [
    responseData[0].textWhereUse,
    responseData[0].textHowUse,
    responseData[0].imageHowUse,
    responseData[0].imageWhereUse,
  ]

  return (
    <section className="details-below-product">
      <>
        {textWhereUse && textWhereUse !== '' && (
          <div className="details-line">
            <div className="details-column">
              <div className="details-background">
                <img src={imageHowUse} alt="Group 2607" />
              </div>
            </div>
            <div
              className={`details-column ${textHowUse !== '' ? 'right' : ''}`}
            >
              <h4>onde usar</h4>
              <p>{textWhereUse}</p>
            </div>
          </div>
        )}
      </>
      <>
        {textHowUse && textHowUse !== '' && (
          <div className="details-line">
            <div className="details-column">
              <div className="details-background">
                <img src={imageWhereUse} alt="Group 2607" />
              </div>
            </div>
            <div
              className={`details-column ${textWhereUse !== '' ? 'right' : ''}`}
            >
              <h4>como usar</h4>
              <p>{textHowUse}</p>
            </div>
          </div>
        )}
      </>
    </section>
  )
}

export default DetailsOfProductGivex
