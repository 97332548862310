import React from 'react'
import type { FC } from 'react'

interface RingIconnProps {
  color?: string
}

const RingIcon: FC<RingIconnProps> = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="41"
    height="48"
    viewBox="0 0 41 48"
    fill="none"
    aria-label="Ring Icon"
  >
    <mask
      id="mask0_10058_31280"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="41"
      height="42"
    >
      <path d="M0 6.86646e-05H40.3478V41.4688H0V6.86646e-05Z" fill="white" />
    </mask>
    <g mask="url(#mask0_10058_31280)">
      <path
        d="M15.4456 7.9165L12.8057 4.6361L15.6201 0.809808H24.7277L27.5421 4.6361L24.9021 7.9165H15.4456Z"
        stroke={color ?? 'black'}
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M27.5421 4.63599H12.8057"
      stroke={color ?? 'black'}
      strokeWidth="1.5"
      strokeMiterlimit="22.926"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1738 4.63602V7.9165"
      stroke={color ?? 'black'}
      strokeWidth="1.5"
      strokeMiterlimit="22.926"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="mask1_10058_31280"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="41"
      height="42"
    >
      <path d="M0 6.86646e-05H40.3478V41.4688H0V6.86646e-05Z" fill="white" />
    </mask>
    <g mask="url(#mask1_10058_31280)">
      <path
        d="M15.6201 0.809852L20.1739 4.63599L24.7277 0.809852"
        stroke={color ?? 'black'}
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M15.4456 10.9575H24.9021V7.91654H15.4456V10.9575Z"
      stroke={color ?? 'black'}
      strokeWidth="1.5"
      strokeMiterlimit="22.926"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="mask2_10058_31280"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="41"
      height="42"
    >
      <path d="M0 6.86646e-05H40.3478V41.4688H0V6.86646e-05Z" fill="white" />
    </mask>
    <g mask="url(#mask2_10058_31280)">
      <path
        d="M12.0629 12.646C13.1066 11.9441 14.2416 11.3735 15.4457 10.9576M24.9022 10.9576C30.7765 12.9865 35.0082 18.6916 35.0082 25.4126C35.0082 33.833 28.3667 40.6589 20.1741 40.6589C11.9813 40.6589 5.33984 33.833 5.33984 25.4126C5.33984 22.4142 6.18258 19.6184 7.63707 17.2607"
        stroke={color ?? 'black'}
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.9003 35.8794C24.2082 36.8596 22.2547 37.4193 20.174 37.4193C13.7222 37.4193 8.49194 32.0438 8.49194 25.4128C8.49194 18.7817 13.7222 13.4062 20.174 13.4062C26.6258 13.4062 31.8559 18.7817 31.8559 25.4128C31.8559 27.5511 31.3114 29.5585 30.358 31.2976"
        stroke={color ?? 'black'}
        strokeWidth="1.5"
        strokeMiterlimit="22.926"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.61225 14.7071L9.61108 14.7083"
        stroke={color ?? 'black'}
        strokeWidth="1.5"
        strokeMiterlimit="2.613"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M28.4346 33.9023L28.4357 33.9012"
        stroke={color ?? 'black'}
        strokeWidth="1.5"
        strokeMiterlimit="2.613"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M35.4967 48C35.9476 48 36.313 47.6399 36.313 47.1954V44.6578C36.313 44.2133 35.9476 43.8531 35.4967 43.8531C35.0458 43.8531 34.6804 44.2133 34.6804 44.6578V46.3907H21.624V44.6578C21.624 44.2133 21.2586 43.8531 20.8077 43.8531C20.3568 43.8531 19.9914 44.2133 19.9914 44.6578V46.3907H6.67608V44.6578C6.67608 44.2133 6.3107 43.8531 5.85977 43.8531C5.40884 43.8531 5.04346 44.2133 5.04346 44.6578V47.1954C5.04346 47.6399 5.40884 48 5.85977 48H35.4967Z"
      fill={color ?? 'black'}
    />
  </svg>
)

export default RingIcon
