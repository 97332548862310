import axios from 'axios'
import { cheaperSla, fasterSla } from 'src/utils/shipment'
import type { Maybe, ShippingData, ShippingSla } from '@generated/graphql'
import type { ShippingSla as ShippingSlaKit } from 'src/components/product/ProductKitLook/types'
import { calculateBusinessDays } from 'src/utils/calculateBusinessDays'
import { addHolidaysToBusinessDays } from 'src/utils/addHolidaysToBusinessDays'
import { expandHolidaysArray } from 'src/utils/expandHolidaysArray'

import handleShippingData from './handleShippingData'
import { getTimeLeftToDeliveryToday } from './getTimeLeftToDeliveryToday'

interface HandleDeliverySlasProps {
  items?: Item[]
  postalCode?: string
  data?: ShippingDataProps
  productKit?: boolean
}

interface ShippingDataProps extends ShippingData {
  pickupPoints?: PickupPointsProps[]
}

export interface IShippingSlaKitTimeLeftForDeliveryToday
  extends ShippingSlaKit {
  timeLeftForDeliveryToday?: TimeLeftForDeliveryToday
}

const getOrderItemPerBigger = (dataShipping: ShippingDataProps) => {
  dataShipping?.logisticsInfo?.sort((a, b) => {
    const aFilter = a?.slas?.find((sla) => sla?.id === 'Normal')
    const bFilter = b?.slas?.find((sla) => sla?.id === 'Normal')

    let aFilterDays
    let bFilterDays

    if (aFilter?.shippingEstimate?.includes('bd')) {
      const days = Number(aFilter?.shippingEstimate?.replace('bd', ''))

      aFilterDays = calculateBusinessDays(new Date(), days)
    } else {
      aFilterDays = Number(aFilter?.shippingEstimate?.replace('d', ''))
    }

    if (bFilter?.shippingEstimate?.includes('bd')) {
      const days = Number(bFilter?.shippingEstimate?.replace('bd', ''))

      bFilterDays = calculateBusinessDays(new Date(), days)
    } else {
      bFilterDays = Number(bFilter?.shippingEstimate?.replace('d', ''))
    }

    return Number(bFilterDays) - Number(aFilterDays)
  })

  return dataShipping
}

export const handleDeliverySlas = async ({
  items,
  postalCode,
  data,
  productKit = false,
}: HandleDeliverySlasProps) => {
  if (!data && items && postalCode) {
    data = await handleShippingData(items, postalCode)
  }

  if (productKit && data) {
    data = getOrderItemPerBigger(data)
  }

  const deliverySlas = data?.logisticsInfo?.[0]
    ?.slas as IShippingSlaKitTimeLeftForDeliveryToday[]

  let deliverySlasWithHolidays: IShippingSlaKitTimeLeftForDeliveryToday[] = []

  const { data: holidays } = await axios.post('/api/getHolidayList')

  const expandedHolidays = expandHolidaysArray(holidays)

  getDeliverySlasWithHolidays(
    deliverySlasWithHolidays,
    deliverySlas,
    expandedHolidays
  )

  const scheduledSla = deliverySlasWithHolidays?.find(
    (sla: IShippingSlaKitTimeLeftForDeliveryToday) => sla?.id === 'Agendada'
  ) as IShippingSlaKitTimeLeftForDeliveryToday

  const pickupSlas = deliverySlasWithHolidays.filter(
    (sla: Maybe<ShippingSla>) => sla?.deliveryChannel === 'pickup-in-point'
  )

  deliverySlasWithHolidays = deliverySlasWithHolidays?.filter(
    (sla: Maybe<ShippingSla>) =>
      sla?.id !== scheduledSla?.id && sla?.deliveryChannel !== 'pickup-in-point'
  )

  pickupSlas.sort(compareShippingEstimate)

  const pickupSlasHours = pickupSlas?.find((store) =>
    store?.shippingEstimate?.includes('h')
  )

  const pickupSlaFinal = (
    pickupSlasHours
      ? { ...pickupSlasHours, name: 'Retirada em loja' }
      : pickupSlas?.length > 0
      ? { ...pickupSlas[0], name: 'Retirada em loja' }
      : false
  ) as IShippingSlaKitTimeLeftForDeliveryToday

  const cheaperSlas = cheaperSla(deliverySlasWithHolidays)
  const filterDeliverySlas = deliverySlasWithHolidays?.filter(
    (sla: IShippingSlaKitTimeLeftForDeliveryToday) =>
      sla.id !== cheaperSlas[0].id
  )

  const slaDeliveryToday = deliverySlasWithHolidays.find((item) => {
    return item.shippingEstimate.includes('h')
  })

  if (slaDeliveryToday) {
    const timeLeftForDeliveryToday = await getTimeLeftToDeliveryToday(
      slaDeliveryToday?.deliveryIds?.[0]?.courierId
    )

    if (timeLeftForDeliveryToday) {
      slaDeliveryToday.timeLeftForDeliveryToday = timeLeftForDeliveryToday
    }
  }

  const fasterSlaDelivery = fasterSla(filterDeliverySlas)

  const slasFinal: IShippingSlaKitTimeLeftForDeliveryToday[] = [
    scheduledSla,
    cheaperSlas[0],
    pickupSlaFinal,
  ]

  if (fasterSlaDelivery) {
    slasFinal.unshift(fasterSlaDelivery)
  }

  return { slasFinal, pickupPoints: data?.pickupPoints }
}

function getDeliverySlasWithHolidays(
  deliverySlasWithHolidays: IShippingSlaKitTimeLeftForDeliveryToday[],
  deliverySlas: IShippingSlaKitTimeLeftForDeliveryToday[],
  expandedHolidays: string[]
) {
  for (const deliverySla of deliverySlas) {
    const { shippingEstimate, ...properties } = deliverySla

    if (shippingEstimate.includes('bd')) {
      const businessDays = parseInt(shippingEstimate, 10) || 0

      const updatedShippingEstimate = addHolidaysToBusinessDays(
        businessDays,
        expandedHolidays
      )

      const updatedDeliverySla = {
        shippingEstimate: updatedShippingEstimate,
        ...properties,
      }

      deliverySlasWithHolidays.push(updatedDeliverySla)
    } else {
      deliverySlasWithHolidays.push(deliverySla)
    }
  }
}

interface TimeUnitOrder {
  h: number
  d: number
  bd: number
}

function convertBusinessDaysToCalendarDays(businessDays: number) {
  let calendarDays = 0
  const currentDate = new Date()

  currentDate.setDate(currentDate.getDate() + 1)
  while (businessDays > 0) {
    const dayOfWeek = currentDate.getDay()

    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      businessDays--
    }

    calendarDays++
    currentDate.setDate(currentDate.getDate() + 1)
  }

  return calendarDays
}

function compareShippingEstimate(
  slaA: IShippingSlaKitTimeLeftForDeliveryToday,
  slaB: IShippingSlaKitTimeLeftForDeliveryToday
) {
  const timeUnitOrder: TimeUnitOrder = { h: 0, d: 1, bd: 2 }

  const extractValueAndUnit = (estimate: string | undefined) =>
    estimate?.match(/(\d+)([a-z]+)/)?.slice(1) ?? []

  const [aValue, aUnit] = extractValueAndUnit(slaA?.shippingEstimate)
  const [bValue, bUnit] = extractValueAndUnit(slaB?.shippingEstimate)

  if (!aValue || !bValue) {
    return !aValue ? 2 : -2
  }

  const convertToNumeric = (value: string, unit: string) =>
    unit === 'bd'
      ? convertBusinessDaysToCalendarDays(parseInt(value, 10))
      : parseInt(value, 10)

  const aValueNumeric = convertToNumeric(aValue, aUnit)
  const bValueNumeric = convertToNumeric(bValue, bUnit)

  const compareTimeUnits =
    timeUnitOrder[aUnit as keyof TimeUnitOrder] -
    timeUnitOrder[bUnit as keyof TimeUnitOrder]

  const areEstimateNumberEqual = aValueNumeric === bValueNumeric

  if (
    compareTimeUnits !== 0 &&
    (aUnit === 'h' || bUnit === 'h' || areEstimateNumberEqual)
  ) {
    return compareTimeUnits
  }

  if (!areEstimateNumberEqual) {
    return aValueNumeric - bValueNumeric
  }

  return 0
}
