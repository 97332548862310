type SelectedFacet = Array<{
  key: string
  value: string
}>

type AdditionalProperty = Array<{
  propertyID: string
  name: string
  value: number
  valueReference: string
}>

export type Node = {
  slug: string
  sku: string
  name: string
  releaseDate: string
  gtin: string
  description: string
  id: string
  brand: { name: string; brandName: string }
  clusterHighlights: Array<{
    id: string | null
    name: string | null
  }> | null
  isVariantOf: {
    name: string
    productGroupID: string
    additionalProperty: AdditionalProperty
    hasVariant: Array<{
      sku: string
      name: string
      description: string
      offers: {
        lowPrice: number
        offers: Array<{
          availability: string
          price: number
          listPrice: number
          seller: { identifier: string }
        }>
      }
      additionalProperty: AdditionalProperty
    }>
  }
  additionalProperty: AdditionalProperty
  image: Array<{ url: string; alternateName: string }>
  offers: {
    lowPrice: number
    offers: Array<{
      availability: string
      price: number
      listPrice: number
      priceValidUntil: string
      quantity: number
      seller: { identifier: string }
    }>
  }
}

type Products = {
  pageInfo: { totalCount: number }
  edges: Array<{
    node: Node
  }>
}

export const filterSpecification = (
  additionalProperty: AdditionalProperty,
  query: string
) => {
  return additionalProperty.filter(
    (el: { name: string }) =>
      el.name.toLowerCase().indexOf(query.toLowerCase()) > -1
  )
}

export const filterProductList = (list?: Node[], id?: string) => {
  return list?.filter((item) => item.id !== id)
}

export const completeLookFacets = (
  completeLook?: string,
  checkColecao?: string
) => {
  return completeLook
    ? [{ key: `productclusterids`, value: `${completeLook}` }]
    : [{ key: `colecao`, value: `${checkColecao}` }]
}

export const hasRelatedProducts = (
  relatedProducts?: string,
  selectedFacets?: SelectedFacet
) => {
  return relatedProducts
    ? [{ key: `productclusterids`, value: `${relatedProducts}` }]
    : selectedFacets
}

export const getFilteredProductList = (productList?: Products, id?: string) => {
  const fullProductList = productList?.edges?.map((edge) => edge.node)

  return filterProductList(fullProductList, id)
}
