import React from 'react'

import './spinner-loading.scss'

const SpinnerLoading = () => {
  return (
    <svg
      className="spinnerLoading"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11 2C15.9706 2 20 6.02944 20 11C20 15.9706 15.9706 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2Z"
        stroke="black"
        strokeOpacity="0.3"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M11 2C15.9706 2 20 6.02944 20 11"
        stroke="black"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default SpinnerLoading
