import React, { useEffect, useState } from 'react'
import BaseModal from 'src/components/modal/BaseModal'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { Button } from '@faststore/ui'
import ClientOnly from 'src/utils/ClientOnly'

import { CarouselAssembleYourBracelet } from '../CarouselTutorial/CarouselAssembleYourBracelet'
import BraceletImg from '../../../images/assemble-your-bracelet/banner-mobile.png'
import BraceletImgDesktop from '../../../images/assemble-your-bracelet/banner-desktop.png'

import './ModalTutorial.scss'

interface ModalTutorialProps {
  isOpen: boolean
}

export const ModalTutorial = ({ isOpen }: ModalTutorialProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [background, setBackground] = useState<boolean>(false)
  const [showStepsTutorial, setShowStepsTutorial] = useState<boolean>(false)
  const { width, height } = useWindowDimensions()

  const newHeight = width < 678 ? (height ? height - 80 : 530) : 530

  const style = {
    height: `${newHeight}px`,
  }

  const closeModal = () => {
    setShowModal(false)
    document.body.classList.remove('no-scroll')
  }

  const showTutorialSteps = () => {
    setShowStepsTutorial(true)
    setBackground(true)
  }

  useEffect(() => {
    if (isOpen) {
      setShowModal(isOpen)
      document.body.classList.add('no-scroll')
    }
  }, [isOpen])

  return (
    <ClientOnly>
      <BaseModal
        isOpen={showModal}
        onCloseButtonClick={closeModal}
        className={`assemby-your-bracelet-modal ${
          background ? 'background-light' : ''
        }`}
        style={style}
      >
        <div className="modal-tutorial__container">
          {showStepsTutorial ? (
            <CarouselAssembleYourBracelet closeModal={closeModal} />
          ) : (
            <div className="modal-tutorial__content">
              <img
                src={width < 678 ? BraceletImg : BraceletImgDesktop}
                alt="assemble-your-bracelet-banner"
                loading="lazy"
              />

              <Button
                className="modal-tutorial__content-button"
                onClick={showTutorialSteps}
              >
                comece a montar
              </Button>
            </div>
          )}
        </div>
      </BaseModal>
    </ClientOnly>
  )
}
