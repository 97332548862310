import React from 'react'

export default function SameDayLabel() {
  return (
    <>
      <div className="shipping-simulator__sameday">
        <p>
          Pode ser entregue <span>Hoje!</span> consulte abaixo:
        </p>
      </div>
    </>
  )
}
