import React from 'react'
import type { FC } from 'react'

const LineIcon: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="2"
    viewBox="0 0 24 2"
    fill="none"
    aria-label="Line Icon"
  >
    <path d="M0 1H24" stroke="black" />
  </svg>
)

export default LineIcon
