import type { BrowserProductQueryQuery } from '@generated/graphql'

export type InferredType<T> = T extends Array<infer U> ? U : T

const useOffer = (
  product: BrowserProductQueryQuery['product'],
  selectedSKU: InferredType<
    BrowserProductQueryQuery['product']['isVariantOf']['hasVariant']
  >
) => {
  const {
    offers: {
      offers: [
        {
          availability: productAvailability,
          price: productPrice,
          listPrice: productListPrice,
          seller: productSeller,
        },
      ],
      lowPrice: productLowPrice,
    },
  } = product

  const {
    offers: { offers: skuOffers, lowPrice: skuLowPrice },
  } = selectedSKU

  const skuAvailability = selectedSKU?.offers?.offers?.[0]?.availability

  const skuPrice = selectedSKU?.offers?.offers?.[0]?.price

  const skuListPrice = selectedSKU?.offers?.offers?.[0]?.listPrice

  const skuSeller = selectedSKU?.offers?.offers?.[0]?.seller

  const availability =
    skuOffers.length > 0 ? skuAvailability : productAvailability

  const price = skuOffers.length > 0 ? skuPrice : productPrice

  const listPrice = skuOffers.length > 0 ? skuListPrice : productListPrice

  const seller = skuOffers.length > 0 ? skuSeller : productSeller

  const lowPrice = skuOffers.length > 0 ? skuLowPrice : productLowPrice

  return availability === 'https://schema.org/InStock'
    ? {
        availability,
        price,
        listPrice,
        seller,
        lowPrice,
      }
    : {
        availability,
        rice: skuOffers?.[0]?.price,
        listPrice: skuOffers?.[0]?.listPrice,
        seller,
        lowPrice,
      }
}

export default useOffer
