import { gql } from '@faststore/graphql-utils'
import { graphql } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import { mark } from 'src/sdk/tests/mark'
import type { PageProps, GetServerDataProps } from 'gatsby'
import type {
  ProductPageQueryQuery,
  ServerProductPageQueryQuery,
  ProductPageQueryQueryVariables,
  BrowserProductQueryQuery,
} from '@generated/graphql'
import ProductView from 'src/views/product'
import { usePageViewEvent } from 'src/sdk/analytics/hooks/usePageViewEvent'
import { isNotFoundError } from '@faststore/api'
import { execute } from 'src/server'
import { useSession } from 'src/sdk/session'
import { useCampaignContext } from 'src/contexts/campaign-context'
import { SkuProvider } from 'src/contexts/ProductContext'
import type { Specifications } from 'src/contexts/details-specification-context'
import { DetailsSpecificationsProvider } from 'src/contexts/details-specification-context'
import axios from 'axios'
import type { DataSimilars } from 'src/components/product/SimilarProducts/SimilarProducts'
import { getCookieFromHeaders } from 'src/utils/cookie'
import { logAzureError } from 'src/services/AzureLogs'
import returnSpecificationProductInsider from 'src/utils/returnSpecificationProductInsider'
import getTaxonomy from 'src/utils/getTaxonomy'
import { getProductDetails } from 'src/api/productSpecification/getProductDetails'
import { getShowTogether } from 'src/api/getShowTogether'

import { getSimilars } from '../../services/ProductSimilars/ProductSimilar'
import storeConfig from '../../../store.config'

export type ProductWithDetails = ServerProductPageQueryQuery['product'] & {
  departmentName: string
}

interface ProductCustomData {
  product: ProductWithDetails
}

export interface ProductSpecifications {
  originalName: string | null
  name: string | null
  specifications: Array<{
    originalName: string | null
    name: string | null
    values: Array<string | null> | null
  } | null> | null
}

export type PDPServerData = ProductCustomData & { similars: DataSimilars } & {
  productDetailsSpecifications: Specifications[]
} & { showTogether: Array<{ productId: string }> }

export type PDPProps = PageProps<
  ProductPageQueryQuery,
  ProductPageQueryQueryVariables,
  unknown,
  PDPServerData | null
> & { slug: string }

function Page(props: PDPProps) {
  const { setCampaignPage } = useCampaignContext()
  const pathname = typeof window !== 'undefined' && window?.location?.href
  const { person } = useSession()
  const [didMount, setDidMount] = useState(false)
  const { sendPageViewEvent } = usePageViewEvent('PDP', person)
  const timerRef = useRef<NodeJS.Timeout | undefined>()

  setCampaignPage(false)

  useEffect(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
    }

    timerRef.current = setTimeout(() => {
      if (!didMount) {
        sendPageViewEvent()
        setDidMount(true)
      }
    }, 500)
  }, [person])

  useEffect(() => {
    window.insider_object = window.insider_object || {}
    const product = props.serverData?.product
    const customData = returnSpecificationProductInsider(product)
    const taxonomyItems = getTaxonomy(product)

    window.insider_object.product = {
      product_id: product?.productId,
      name: product?.name,
      taxonomy: taxonomyItems || [],
      currency: product?.offers.offers[0]?.priceCurrency,
      unit_price: product?.offers.offers[0]?.listPrice?.toFixed(2),
      unit_sale_price: product?.offers.offers[0]?.price?.toFixed(2),
      url: `${window?.location?.origin}/${product?.slug}`,
      product_image_url: product?.image[0].url,
      size: product?.additionalProperty?.[0]?.value || '',
      custom: customData,
    }
    window.insider_object.page = {
      type: 'Product',
      originalType: 'Product',
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  if (!props?.serverData) {
    return null
  }

  return (
    props?.serverData && (
      <SkuProvider>
        <DetailsSpecificationsProvider>
          <ProductView {...props} />
        </DetailsSpecificationsProvider>
      </SkuProvider>
    )
  )
}

export const querySSG = graphql`
  query ProductPageQuery {
    site {
      siteMetadata {
        title
        description
        titleTemplate
        siteUrl
      }
    }
    cmsGlobalComponents {
      sections {
        name
        data
      }
    }
  }
`

export const querySSR = gql`
  query ServerProductPageQuery($slug: String!, $regionId: String!) {
    product(
      locator: [
        { key: "slug", value: $slug }
        { key: "regionId", value: $regionId }
      ]
    ) {
      id: productID
      sku
      releaseDate
      name
      gtin
      description
      slug
      clusterHighlights {
        id
        name
      }
      isVariantOf {
        name
        productGroupID
        additionalProperty {
          propertyID
          name
          value
          valueReference
        }
        hasVariant {
          ...skuFragment
        }
      }
      additionalProperty {
        propertyID
        name
        value
        valueReference
      }
      image {
        url
        alternateName
      }
      brand {
        name
      }
      offers {
        lowPrice
        highPrice
        priceCurrency
        offers {
          availability
          price
          listPrice
          priceValidUntil
          priceCurrency
          itemCondition
          seller {
            identifier
          }
        }
      }
      breadcrumbList {
        itemListElement {
          item
          name
          position
        }
      }
      specificationGroups {
        originalName
        name
        specifications {
          originalName
          name
          values
        }
      }
      brandId
      categoriesIds
      productId
      productClusters {
        id
        name
      }
      seo {
        title
        description
      }
    }
  }
`

interface IGetPdpData {
  fieldQuery: string | number
  filterField: string
  product: ServerProductPageQueryQuery['product']
}

const getPdpData = async ({
  fieldQuery,
  filterField,
  product,
}: IGetPdpData) => {
  const { account } = storeConfig

  try {
    const response = await axios.get(
      `https://${account}.myvtex.com/_v/cms/api/faststore/productPage/?filters[config.productConfig.${filterField}]=${fieldQuery}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }
    )

    return response?.data
  } catch (_error) {
    const error = new Error('Special Content error')
    const properties = {
      product: {
        productName: product?.description,
        productId: product?.id,
        productReference: product?.gtin,
        correctSlug: product?.slug,
        wrongSlug: product?.slug,
      },
      similarFamily: 'special-content',
      files: 'p.tsx',
      tag: 'Special Content error',
    }

    logAzureError({ error, properties })

    return null
  }
}

const getFieldAndFilter = async (
  product: ServerProductPageQueryQuery['product']
) => {
  const allSpecifications = product.specificationGroups?.find(
    (specification) => specification?.name === 'allSpecifications'
  )

  const pageSpecification = allSpecifications?.specifications?.find(
    (specification) => specification?.name === 'PageCustomRule'
  )

  const pageSpecificationCollection = allSpecifications?.specifications?.find(
    (specification) => specification?.name === 'PageCustomRuleCollection'
  )

  const productPageSpeficication = [
    pageSpecification,
    pageSpecificationCollection,
  ]

  let fieldQuery
  let filterField

  switch (productPageSpeficication?.[0]?.values?.[0]) {
    case 'productID':
      fieldQuery = product?.productId
      filterField = 'productGroupID'
      break

    case 'brandID':
      fieldQuery = product?.brandId
      filterField = 'brandID'
      break

    case 'subcategoryID':
      fieldQuery = product?.categoriesIds?.[0]?.split('/')?.[3]
      filterField = 'subcategoryID'
      break

    case 'categoryID':
      fieldQuery = product?.categoriesIds?.[0]?.split('/')?.[2]
      filterField = 'categoryID'
      break

    case 'collectionID':
      if (productPageSpeficication?.[1]?.values?.[0]) {
        fieldQuery = productPageSpeficication?.[1]?.values?.[0]
        filterField = 'collectionID'
      }

      break

    default:
      break
  }

  return { fieldQuery, filterField }
}

function getProductData(product: BrowserProductQueryQuery['product']) {
  let isSpecification: Array<Specifications | null> | undefined

  if (product?.specificationGroups) {
    product?.specificationGroups?.forEach((item) => {
      isSpecification = item?.specifications?.filter(
        (el) =>
          el?.name === 'Material' ||
          el?.name === 'Pedras' ||
          el?.name === 'Malha' ||
          el?.name === 'Mecanismos' ||
          el?.name === 'Resistência' ||
          el?.name === 'Material do vidro' ||
          el?.name === 'Material da pulseira' ||
          el?.name === 'Coleção'
      )
    })
  }

  return isSpecification
}

async function getDetailsData(product: BrowserProductQueryQuery['product']) {
  const arrayTypes: string[] | string = []
  const arrayNames: Array<string | null> = []

  getProductData(product)?.map((productData) => {
    if (productData?.name && productData?.values) {
      for (const value of productData.values) {
        arrayNames.push(value)

        switch (productData.name) {
          case 'Coleção':
            arrayTypes.push('Inspiração')
            break

          case 'Pedras':
            arrayTypes.push('Pedra')
            break

          default:
            arrayTypes.push(productData.name)
        }
      }
    }

    return [arrayNames, arrayTypes]
  })

  return getProductDetails(arrayNames, arrayTypes)
}

type ServerDataProps = GetServerDataProps & { params: { slug: string } }

export const getServerData = async ({
  headers,
  params: { slug },
}: ServerDataProps) => {
  const THIRTY_MINUTES_CACHE = `s-maxage=1800, stale-while-revalidate`

  const cookieString = decodeURIComponent(headers.get('cookie') as string)
  const cookies = cookieString.split(';')

  const regionId = await getCookieFromHeaders('fs_region_id', cookies)

  const { data, errors = [] } = await execute({
    operationName: querySSR,
    variables: { slug, regionId },
  })

  if (data === null || data === 'null') {
    const params = new URLSearchParams({
      from: encodeURIComponent(`/${slug}/p`),
    })

    const urlRedirect = `/404/?${params.toString()}}`

    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': THIRTY_MINUTES_CACHE,
        location: urlRedirect,
      },
    }
  }

  const notFound = errors.find(isNotFoundError)

  if (
    notFound ||
    slug.toLowerCase().includes('brinde') ||
    slug.toLowerCase().includes('brindes')
  ) {
    const params = new URLSearchParams({
      from: encodeURIComponent(`/${slug}/p`),
    })

    const urlRedirect = notFound ? `/404/?${params.toString()}}` : `/`

    return {
      status: 301,
      props: null,
      headers: {
        'cache-control': THIRTY_MINUTES_CACHE,
        location: urlRedirect,
      },
    }
  }

  if (errors?.length > 0) {
    throw errors[0]
  }

  const productDetailsSpecifications = await getDetailsData(data?.product)

  const showTogether = await getShowTogether(data?.product?.productId)

  const similars = await getSimilars({
    product: data?.product ?? {},
    mainSlug: `/${slug}/p`,
    regionId: '',
    hideNoStockProducts: false,
  })

  const departmentName =
    data?.product?.categoriesIds?.[0]?.split('/')?.[1] === '1'
      ? 'Vivara'
      : 'Life'

  const updatedPdpData = {
    product: {
      ...(data?.product ?? {}),
      departmentName,
    },
    similars,
    productDetailsSpecifications,
    showTogether,
  }

  const { fieldQuery, filterField } = await getFieldAndFilter(data?.product)

  const dataPdp =
    fieldQuery && filterField
      ? await getPdpData({ fieldQuery, filterField, product: data?.product })
      : null

  if (dataPdp) {
    return {
      status: 200,
      props: { ...updatedPdpData, ...{ dataPdp } },
      headers: {
        'cache-control': THIRTY_MINUTES_CACHE,
      },
    }
  }

  return {
    status: 200,
    props: updatedPdpData,
    headers: {
      'cache-control': THIRTY_MINUTES_CACHE,
    },
  }
}

Page.displayName = 'Page'

export default mark(Page)
