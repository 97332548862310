import type {
  BrowserProductQueryQuery,
  IStoreSelectedFacet,
} from '@generated/graphql'
import axios from 'axios'
import { get } from 'idb-keyval'

import {
  getSpecificationProduct,
  getSpecificationProductLarge,
} from '../utils/getSpecificationProduct'
import type { ProductsListComparedProps } from '../ComparedProduct'

interface BrowserProductQueryQueryWithEdge {
  node: BrowserProductQueryQuery['product']
}

function formatMeasurement(value: number): string {
  const roundedValue = Number(value?.toFixed(1))

  const stringValue =
    roundedValue % 1 === 0 ? roundedValue?.toString() : roundedValue?.toFixed(1)

  return stringValue?.replace('.', '')
}

function generateMeasurements(num: number) {
  const measurements = new Set<string>()
  let currentUp = num
  let currentDown = num
  const MAX_LARGE = 10
  const MIN_LARGE = 1
  const step = 0.1

  while (currentUp <= MAX_LARGE || currentDown >= MIN_LARGE) {
    if (currentUp <= MAX_LARGE) {
      const measurement = formatMeasurement(currentUp)

      if (measurement !== formatMeasurement(num)) {
        measurements?.add(`aproximadamente-${measurement}-mm`)
      }

      currentUp += step
    }

    if (currentDown >= MIN_LARGE) {
      const measurement = formatMeasurement(currentDown)

      if (measurement !== formatMeasurement(num)) {
        measurements?.add(`aproximadamente-${measurement}-mm`)
      }

      currentDown -= step
    }
  }

  return Array.from(measurements)?.map((value) => ({
    key: 'largura-aprox',
    value,
  }))
}

const getLargeValue = (text: string) => {
  const numberReg = /\d+(\.\d+)?/
  const match = numberReg?.exec(text)

  return match ? generateMeasurements(parseFloat(match?.[0])) : undefined
}

const getComparedProductByVisual = (
  categoryThree: string,
  specificationGroups: BrowserProductQueryQuery['product']['specificationGroups']
) => {
  const priceMin = 1
  const priceMax = 2000000

  const valueLarge = getSpecificationProductLarge(specificationGroups)

  const largeFilter = getLargeValue(valueLarge)

  return [
    { key: 'category-3', value: categoryThree },
    {
      key: 'material',
      value: getSpecificationProduct(
        specificationGroups,
        'material'
      )?.[0] as string,
    },
    { key: 'largura-aprox', value: valueLarge.replace(',', '') },
    ...(Array.isArray(largeFilter) ? largeFilter : []),
    {
      key: 'price',
      value: `${priceMin}-to-${priceMax}`,
    },
  ]
}

const getComparedProductByPrice = (
  categoryThree: string,
  offers: BrowserProductQueryQuery['product']['offers']
) => {
  const offersPrices = offers?.offers?.[0]
  const price =
    offersPrices?.availability === 'https://schema.org/InStock'
      ? offers?.lowPrice
      : offersPrices?.price

  const calculationPerc = price * 0.2

  return [
    { key: 'category-3', value: categoryThree },
    {
      key: 'price',
      value: `${price - calculationPerc}-to-${price + calculationPerc}`,
    },
  ]
}

const getApiComparedProduct = async (selectedFacets: IStoreSelectedFacet[]) => {
  const session = await get('fs::session')

  return axios
    .post('/api/getProductSearch', {
      selectedFacets,
      channel: session?.channel,
    })
    .catch((error) => {
      console.error(error)
    })
}

export const getComparedProduct = async (
  categoryThree: string,
  product: BrowserProductQueryQuery['product']
) => {
  const selectedFacetsByVisual = getComparedProductByVisual(
    categoryThree,
    product?.specificationGroups
  )

  const selectedFacetsByPrice = getComparedProductByPrice(
    categoryThree,
    product?.offers
  )

  const dataPromise = await Promise.all([
    getApiComparedProduct(selectedFacetsByVisual),
    getApiComparedProduct(selectedFacetsByPrice),
  ])

  const productsList = dataPromise?.map((item) => {
    const edges = item?.data?.search?.products?.edges

    const edgesMap = edges?.map(
      (edge: BrowserProductQueryQueryWithEdge) => edge?.node
    ) as Array<BrowserProductQueryQuery['product']>

    const filterProducts = edgesMap?.filter(
      (productItem, index) => productItem?.id !== product?.id && index < 3
    )

    if (filterProducts?.length) {
      filterProducts?.unshift(product)
    }

    return filterProducts
  })

  const result: ProductsListComparedProps = {
    byVisual: productsList?.[0],
    byPrice: productsList?.[1],
  }

  return result
}
