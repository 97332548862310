import { Button } from '@faststore/ui'
import React, { useState } from 'react'
import type { Dispatch, SetStateAction } from 'react'
import BaseModal from 'src/components/modal/BaseModal'
import { BraceletIcon } from 'src/images/assemble-your-bracelet'

import './AssembleModals.scss'

interface AssembleModalProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  setIsFirstClick: Dispatch<SetStateAction<boolean>>
  applyChainFilter: () => void
}

interface AssembleModalAlreadyHaveChainProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  AddProduct: () => Promise<void>
  CancelProduct: () => void
}

export const AssembleModalChain = ({
  isOpen,
  setIsOpen,
  setIsFirstClick,
  applyChainFilter,
}: AssembleModalProps) => {
  const [content, setContent] = useState<number>(1)

  const onCloseButton = () => {
    setIsOpen(false)
    setContent(1)
    setIsFirstClick(false)
  }

  const CloseAndReturnStep = () => {
    applyChainFilter()
    setIsFirstClick(false)
    setIsOpen(false)
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onCloseButtonClick={onCloseButton}
      className="assemble-modal"
    >
      {content === 1 && (
        <div className="assemble-modal__container">
          <div className="assemble-modal__icon">
            <BraceletIcon />
          </div>
          <div className="assemble-modal__message">
            Notamos que você não adicionou uma corrente de segurança na sua
            montagem.
          </div>
          <Button
            className="assemble-modal__button-primary"
            onClick={CloseAndReturnStep}
          >
            Quero Adicionar uma
          </Button>
          <Button
            onClick={() => {
              setContent(2)
            }}
            className="assemble-modal__button-secondary"
          >
            POR QUE TER UMA CORRENTE DE SEGURANÇA?
          </Button>
        </div>
      )}

      {content === 2 && (
        <div className="assemble-modal__container">
          <div className="assemble-modal__icon">
            <BraceletIcon />
          </div>
          <div className="assemble-modal__message">
            Assim como o nome, a corrente garante que, caso sua pulseira se abra
            repentinamente, os seus pingentes ficarão travados e seguros. Além
            de ser mais um charme com várias opções de acabamento e design.
          </div>
          <Button
            className="assemble-modal__button-primary"
            onClick={CloseAndReturnStep}
          >
            amei, já quero
          </Button>
          <Button
            onClick={onCloseButton}
            className="assemble-modal__button-secondary"
          >
            DESTA VEZ, NÃO
          </Button>
        </div>
      )}
    </BaseModal>
  )
}

export const AssembleModalAlreadyHaveChain = ({
  isOpen,
  setIsOpen,
  AddProduct,
  CancelProduct,
}: AssembleModalAlreadyHaveChainProps) => {
  const onCloseButton = () => {
    setIsOpen(false)
    CancelProduct()
  }

  const ChangeChain = () => {
    setIsOpen(false)
    AddProduct()
  }

  return (
    <BaseModal
      isOpen={isOpen}
      onCloseButtonClick={onCloseButton}
      className="assemble-modal"
    >
      <div className="assemble-modal__container">
        <div className="assemble-modal__icon">
          <BraceletIcon />
        </div>
        <div className="assemble-modal__message">
          Você só pode adicionar uma corrente de segurança por pulseira. Ao
          escolher outro modelo, ele substituirá o que foi escolhido
          anteriormente.
        </div>
        <Button
          className="assemble-modal__button-primary"
          onClick={ChangeChain}
        >
          ok, substituir
        </Button>
        <Button
          onClick={onCloseButton}
          className="assemble-modal__button-secondary"
        >
          CANCELAR
        </Button>
      </div>
    </BaseModal>
  )
}
