import React, { useEffect, useState } from 'react'
import { Button } from '@faststore/ui'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import useOffer from 'src/components/sections/ProductDetails/useOffer'
import type { InferredType } from 'src/components/sections/ProductDetails/useOffer'
import { useSession } from 'src/sdk/session'
import { useCart } from 'src/sdk/cart'
import { handleOrderKpiData } from 'src/utils/kpiToCart/handleOrderKpiData'

import * as storeConfig from '../../../../store.config'
import { sumTotalPrice } from './AssembleResume'
import { handleAddToCart, prepareProductsToSend } from './HandleMinicart'
import { AssemblyFinishModal } from '../AssembleModals/AssemblyFinishModal'
import { AssembleModalChain } from '../AssembleModals/AssembleModalsChain'
import type { SelectedProductsAssemble } from '../Assembly/types'
import './assemble-resume.scss'

interface AssembleButtonAddToCartProps {
  selectedProductsAssembleBracelet: Array<SelectedProductsAssemble | null>
  productMain: BrowserProductQueryQuery['product']
  selectedSkuBracelet: InferredType<
    BrowserProductQueryQuery['product']['isVariantOf']['hasVariant']
  >
  selectedProductsAssembleBraceletChain: SelectedProductsAssemble | null
  hasChoiceChain: boolean
  isFirstClick: boolean
  setIsFirstClick: React.Dispatch<React.SetStateAction<boolean>>
  applyChainFilter: () => void
}

const { checkoutUrl } = storeConfig

export const AssembleButtonAddToCart = ({
  selectedProductsAssembleBracelet,
  productMain,
  selectedSkuBracelet,
  selectedProductsAssembleBraceletChain,
  hasChoiceChain,
  isFirstClick,
  setIsFirstClick,
  applyChainFilter,
}: AssembleButtonAddToCartProps) => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [allowRedirectionToCheckout, setAllowRedirectionToCheckout] =
    useState<boolean>(false)

  const [showChainModal, setShowChainModal] = useState<boolean>(false)

  const {
    currency: { code },
  } = useSession()

  const { id: cartId, items: itemsCart, isValidating } = useCart()

  const { name, image, gtin, brand, additionalProperty, isVariantOf } =
    productMain

  const { listPrice, lowPrice, price, seller } = useOffer(
    productMain,
    selectedSkuBracelet
  )

  const bracelet = {
    id: selectedSkuBracelet.sku,
    price: price ?? 0,
    listPrice,
    seller,
    quantity: 1,
    itemOffered: {
      sku: selectedSkuBracelet.sku,
      name,
      gtin,
      image,
      brand,
      isVariantOf,
      additionalProperty,
    },
  }

  const selecteds = selectedProductsAssembleBracelet.filter(
    (selected) => selected !== null
  )

  const totalPrice = sumTotalPrice(
    selectedProductsAssembleBracelet,
    lowPrice,
    'lowPrice'
  )

  const handleClick = (): void => {
    if (
      !selectedProductsAssembleBraceletChain &&
      hasChoiceChain &&
      isFirstClick
    ) {
      return setShowChainModal(true)
    }

    if (selectedProductsAssembleBraceletChain || selecteds.length) {
      setShowModal(true)
    }
  }

  const securityChain = selectedProductsAssembleBraceletChain?.product

  const sendToCart = (redirectTo: 'checkout' | 'life-bracelets'): void => {
    const newItemsForCart = prepareProductsToSend(
      bracelet,
      selecteds,
      securityChain
    )

    handleAddToCart({
      products: newItemsForCart,
      code,
      totalPrice,
      cartId,
      itemsCart,
    }).then(async () => {
      await handleOrderKpiData({
        cartId,
        products: newItemsForCart,
        appName: 'assemble-bracelet-data',
        marketingTag: 'assemble-bracelet',
      })

      if (redirectTo === 'checkout') {
        setAllowRedirectionToCheckout(true)
      } else {
        window.location.pathname = '/life/joias/pulseiras/maleaveis'
      }
    })
  }

  useEffect(() => {
    if (!isValidating && cartId && allowRedirectionToCheckout) {
      setAllowRedirectionToCheckout(false)
      window.location.href = `${checkoutUrl}?orderFormId=${cartId}`
    }
  }, [cartId, isValidating, allowRedirectionToCheckout])

  return (
    <>
      {showModal && (
        <AssemblyFinishModal
          isOpen={showModal}
          setShowModal={setShowModal}
          sendToCart={sendToCart}
        />
      )}
      {hasChoiceChain &&
        !selectedProductsAssembleBraceletChain &&
        isFirstClick && (
          <AssembleModalChain
            setIsOpen={setShowChainModal}
            isOpen={showChainModal}
            setIsFirstClick={setIsFirstClick}
            applyChainFilter={applyChainFilter}
          />
        )}
      <Button
        onClick={
          selecteds.length || selectedProductsAssembleBraceletChain
            ? handleClick
            : undefined
        }
        id={
          selecteds.length || selectedProductsAssembleBraceletChain
            ? 'btn-add-to-cart'
            : 'btn-add-to-cart-disabled'
        }
      >
        ADICIONAR À SACOLA
      </Button>
    </>
  )
}
