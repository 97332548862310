export const DEFAULT_RINGS = [
  {
    number: 5,
    diameter: 14.27,
  },
  {
    number: 6,
    diameter: 14.68,
  },
  {
    number: 7,
    diameter: 14.88,
  },
  {
    number: 8,
    diameter: 15.29,
  },
  {
    number: 9,
    diameter: 15.7,
  },
  {
    number: 10,
    diameter: 16.1,
  },
  {
    number: 11,
    diameter: 16.51,
  },
  {
    number: 12,
    diameter: 16.71,
  },
  {
    number: 13,
    diameter: 16.92,
  },
  {
    number: 14,
    diameter: 17.32,
  },
  {
    number: 15,
    diameter: 17.73,
  },
  {
    number: 16,
    diameter: 18.14,
  },
  {
    number: 17,
    diameter: 18.54,
  },
  {
    number: 18,
    diameter: 18.95,
  },
  {
    number: 19,
    diameter: 19.35,
  },
  {
    number: 20,
    diameter: 19.76,
  },
  {
    number: 21,
    diameter: 19.96,
  },
  {
    number: 22,
    diameter: 20.17,
  },
  {
    number: 23,
    diameter: 20.57,
  },
  {
    number: 24,
    diameter: 20.98,
  },
  {
    number: 25,
    diameter: 21.39,
  },
  {
    number: 26,
    diameter: 21.79,
  },
  {
    number: 27,
    diameter: 22.2,
  },
  {
    number: 28,
    diameter: 22.61,
  },
  {
    number: 29,
    diameter: 23.01,
  },
  {
    number: 30,
    diameter: 23.42,
  },
]
