import { Button } from '@faststore/ui'
import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import IconArrowLeftAssembly from 'src/images/svg/icon-arrow-left-assemby'
import IconArrowRightAssembly from 'src/images/svg/icon-arrow-right-assembly'

import { cardContent } from '../Mocks/card-steps-list'
import { StepTutorialCard } from '../StepTutorialCard/StepTutorialCard'

import './CarouselAssembleYourBracelet.scss'

interface CarouselAssembleYourBraceletProps {
  closeModal: () => void
}

export const CarouselAssembleYourBracelet = ({
  closeModal,
}: CarouselAssembleYourBraceletProps) => {
  const INITIAL_SLIDE = 0
  const LAST_SLIDE = cardContent.length - 1

  const [showStartButton, setShowStartButton] = useState(false)
  const [sliderSeleted, setsliderSeleted] = useState(INITIAL_SLIDE)
  const sliderCollectionsRef = useRef<Slider>(null)

  const settings = {
    arrows: true,
    dots: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow:
      sliderSeleted !== LAST_SLIDE ? (
        <IconArrowRightAssembly
          onClick={() => sliderCollectionsRef.current?.slickNext()}
        />
      ) : (
        <></>
      ),
    prevArrow:
      sliderSeleted !== INITIAL_SLIDE ? (
        <IconArrowLeftAssembly
          onClick={() => sliderCollectionsRef.current?.slickPrev()}
        />
      ) : (
        <></>
      ),
    className: '',
    initialSlide: 0,
  }

  const beforeChange = (_: number, nextSlide: number) => {
    if (sliderCollectionsRef.current) {
      setsliderSeleted(nextSlide)
    }
  }

  const skipTutorial = () => {
    if (sliderCollectionsRef.current) {
      setsliderSeleted(LAST_SLIDE)
      sliderCollectionsRef.current.slickGoTo(LAST_SLIDE)
    }
  }

  useEffect(() => {
    sliderSeleted === LAST_SLIDE
      ? setShowStartButton(true)
      : setShowStartButton(false)
  }, [sliderSeleted, LAST_SLIDE])

  return (
    <div className="carousel-assemble-your-bracelet">
      {cardContent?.length > 0 && (
        <Slider
          ref={sliderCollectionsRef}
          {...settings}
          beforeChange={beforeChange}
        >
          {cardContent.map((card, index) => (
            <StepTutorialCard
              key={index}
              step={card.step}
              title={card.title}
              imageUrl={card.imageUrl}
              description={card.description}
            />
          ))}
        </Slider>
      )}
      <div className="carousel-assemble-your-bracelet__actions">
        {showStartButton ? (
          <Button className="start-button" onClick={closeModal}>
            começar
          </Button>
        ) : (
          <Button className="skip-button" onClick={skipTutorial}>
            pular
          </Button>
        )}
      </div>
    </div>
  )
}
