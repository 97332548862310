import React from 'react'

const DoubleArrowUp = () => {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-label="Double Arrow Up Icon"
    >
      <path
        d="M1.25 7.45825L7.5 1.20825L13.75 7.45825M1.25 15.7916L7.5 9.54158L13.75 15.7916"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default DoubleArrowUp
