import React from 'react'

interface Props {
  onClick?: () => void
}

const IconHelpCircle = ({ onClick }: Props) => {
  return (
    <div onClick={onClick} role="button" aria-hidden>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 3.125C8.64026 3.125 7.31104 3.52821 6.18046 4.28365C5.04987 5.03908 4.16868 6.11281 3.64833 7.36905C3.12798 8.62529 2.99183 10.0076 3.2571 11.3412C3.52238 12.6749 4.17716 13.8999 5.13864 14.8614C6.10013 15.8228 7.32514 16.4776 8.65876 16.7429C9.99238 17.0082 11.3747 16.872 12.631 16.3517C13.8872 15.8313 14.9609 14.9501 15.7164 13.8195C16.4718 12.689 16.875 11.3597 16.875 10C16.875 8.17664 16.1507 6.42795 14.8614 5.13864C13.572 3.84933 11.8234 3.125 10 3.125Z"
          stroke="#737373"
          strokeMiterlimit="10"
        />
        <path
          d="M7.8125 7.90201C7.8125 7.90201 7.84531 7.21841 8.57695 6.62974C9.01094 6.28013 9.53125 6.17896 10 6.17193C10.427 6.16646 10.8082 6.23716 11.0363 6.34576C11.427 6.53169 12.1875 6.9856 12.1875 7.95083C12.1875 8.96646 11.5234 9.42779 10.7668 9.93521C10.0102 10.4426 9.80469 10.9934 9.80469 11.5626"
          stroke="#737373"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M9.76562 14.375C10.1971 14.375 10.5469 14.0252 10.5469 13.5938C10.5469 13.1623 10.1971 12.8125 9.76562 12.8125C9.33415 12.8125 8.98438 13.1623 8.98438 13.5938C8.98438 14.0252 9.33415 14.375 9.76562 14.375Z"
          fill="#737373"
        />
      </svg>
    </div>
  )
}

export default IconHelpCircle
