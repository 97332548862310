import React from 'react'
import type { ReactNode } from 'react'
import BaseModal from 'src/components/modal/BaseModal'
import { Button as ButtonSecondary } from '@faststore/ui'
import Button from 'src/components/ui/VivaraButton'
import { BraceletIcon } from 'src/images/assemble-your-bracelet'

import './NotificationModal.scss'

interface NotificationModalProps {
  icon?: ReactNode
  message: string
  isOpen: boolean
  primaryButtonText: string
  primaryButtonCallback: () => void
  secondaryButtonText?: string
  secondaryButtonCallback?: () => void
  closeModal: () => void
}

export const NotificationModal = ({
  icon,
  message,
  isOpen,
  primaryButtonText,
  primaryButtonCallback,
  closeModal,
  secondaryButtonText,
  secondaryButtonCallback,
}: NotificationModalProps) => {
  return (
    <BaseModal
      isOpen={isOpen}
      onCloseButtonClick={closeModal}
      className="notification-modal"
    >
      <div className="notification-modal__container">
        <div className="notification-modal__icon">
          {icon ?? <BraceletIcon />}
        </div>
        <div className="notification-modal__message">{message}</div>
        <Button onClick={primaryButtonCallback}>{primaryButtonText}</Button>
        {secondaryButtonText && secondaryButtonCallback && (
          <ButtonSecondary
            className="notification-modal__button-secondary"
            onClick={secondaryButtonCallback}
          >
            {secondaryButtonText}
          </ButtonSecondary>
        )}
      </div>
    </BaseModal>
  )
}
