import { useContext, useEffect } from 'react'
import { sortSkuVariations } from 'src/utils/product/sortSkuVariations'

import { SkuContext } from './index'
import type { BrowserProductQueryQuery } from '../../../@generated/graphql/index'

export const useSkuContext = () => {
  const context = useContext(SkuContext)

  if (context === undefined) {
    throw new Error('Product context provider missing from React tree')
  }

  return context
}

export const useSelectedSKU = (
  product: BrowserProductQueryQuery['product']
) => {
  const { selectedSKU, setSelectedSKU } = useSkuContext()

  const sortedVariants = sortSkuVariations(product?.isVariantOf?.hasVariant)

  useEffect(() => {
    if (sortedVariants.length > 1) {
      const availableIndex = sortedVariants?.findIndex(
        (variant) =>
          variant?.offers?.offers?.[0]?.availability ===
          'https://schema.org/InStock'
      )

      setSelectedSKU(availableIndex)
    }
  }, [product, setSelectedSKU])

  const index =
    0 < selectedSKU && selectedSKU < sortedVariants?.length ? selectedSKU : 0

  return sortedVariants?.[index]
}
