import React, { useEffect, useState } from 'react'
import axios from 'axios'

interface PropsDescription {
  description: string
  compositions: Composition[]
  idProduct: string
  setProductSpecification: React.Dispatch<
    React.SetStateAction<
      | Array<{
          Value: string[]
          Id: number
          Name: string
        }>
      | undefined
    >
  >
  categoriesIds: string[] | null
}

type ProductSpecification = {
  name: string
  value: string
  id: number
  isOnProductDetails: boolean
}

type Composition = {
  name: string
  value: string
  propertyID: string
  valueReference: string
}

type ResponseProductSpecification = {
  Id: number
  Name: string
}

const Description = ({
  description,
  compositions,
  idProduct,
  setProductSpecification,
  categoriesIds,
}: PropsDescription) => {
  const [specification, setSpecification] = useState<ProductSpecification[]>([])

  const productSpecification: ProductSpecification[] = []

  const getProductSpecification = async () => {
    const { data } = await axios.get(
      `/api/productSpecification/getProductSpecification?idProduct=${idProduct}`
    )

    return data
  }

  const formatSpecification = async () => {
    const responseGetProductSpecification = await getProductSpecification()

    setProductSpecification(responseGetProductSpecification)

    compositions?.forEach((composition: Composition) => {
      responseGetProductSpecification?.map(
        (item: ResponseProductSpecification) => {
          if (item?.Name === composition?.name) {
            return productSpecification.push({
              name: composition?.name,
              value: composition?.value,
              id: item?.Id,
              isOnProductDetails: false,
            })
          }

          return null
        }
      )
    })

    const categoryId = categoriesIds?.[0]
      ?.split('/')
      .filter((item) => item !== '')
      ?.pop()

    if (categoryId) {
      try {
        const { data } = await axios.post(
          '/api/productSpecification/getSpecificationsFields',
          { categoryId }
        )

        productSpecification?.forEach(
          async (productSpecificationItem: ProductSpecification, index) => {
            data?.forEach(
              (field: { fieldId: string; isOnProductDetails: boolean }) => {
                if (Number(field?.fieldId) === productSpecificationItem?.id) {
                  productSpecificationItem.isOnProductDetails =
                    field?.isOnProductDetails
                }
              }
            )

            if (index === productSpecification?.length - 1) {
              setSpecification(productSpecification)
            }
          }
        )
      } catch (error) {
        console.error('Um erro ocorreu: ', error)
      }
    }
  }

  useEffect(() => {
    setSpecification([])
    formatSpecification()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idProduct, categoriesIds])

  function removeAcentuation(text: string) {
    return text?.normalize('NFD')?.replace(/[\u0300-\u036f]/g, '')
  }

  function changePosition(
    arr: ProductSpecification[],
    from: number,
    to: number
  ) {
    arr?.splice(to, 0, arr?.splice(from, 1)[0])

    return arr
  }

  const indexObservacao =
    specification[0] !== undefined
      ? specification?.findIndex((item) => item.name === 'Observação')
      : 0

  const newspecification = changePosition(
    specification,
    indexObservacao,
    specification.length
  )

  if (newspecification?.length) {
    newspecification.sort((a: { name: string }, b: { name: string }) => {
      const nameA = removeAcentuation(a?.name?.toUpperCase())
      const nameB = removeAcentuation(b?.name?.toUpperCase())

      if (nameA === 'OBSERVACAO' || nameB === 'OBSERVACAO') {
        return 1
      }

      if (nameA < nameB) {
        return -1
      }

      if (nameA > nameB) {
        return 1
      }

      return 0
    })
  }

  const compositionNameFormatter = (name: string) => {
    if (name === 'Largura aprox' || name === 'Espessura aprox') {
      return `${name}.`
    }

    return name
  }

  return (
    <div id="description">
      <h2 className="description-title">DESCRIÇÃO E COMPOSIÇÃO</h2>
      <p className="description-text">{description}</p>
      {specification[0] !== undefined &&
        newspecification
          ?.filter(
            (element: ProductSpecification) =>
              element?.name.indexOf('Aro') === -1 &&
              element?.name !== 'Monte Sua Pulseira' &&
              element?.name !== 'Monte Sua Pulseira Acessórios'
          )
          ?.map((composition: ProductSpecification) => {
            return (
              <div key={`${composition?.id}`}>
                {composition?.isOnProductDetails === true && (
                  <ul className="description-composition">
                    <li className="description-composition__item">
                      {compositionNameFormatter(composition?.name)} :{' '}
                      {composition?.value}
                    </li>
                  </ul>
                )}
              </div>
            )
          })}
    </div>
  )
}

export default Description
