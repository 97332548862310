import { Skeleton } from '@acctglobal/skeleton'
import React from 'react'

export const SimilarProductSkeleton = () => {
  return (
    <Skeleton
      width={315}
      height={90}
      borderRadius={4}
      backgroundColor="#F4F4F4"
      padding={10}
    />
  )
}
