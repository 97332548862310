import React from 'react'
import type { BrowserProductQueryQuery } from '@generated/graphql'
import useWindowDimensions from 'src/hooks/useWindowDimensions'

import './products-to-buy.scss'
import ListOfProductsToBuy from './ListOfProductsToBuy'

interface IProductsToBuy {
  products: Array<BrowserProductQueryQuery['product']>
  isBuy?: boolean
  buttonBack?: () => void
  redirectToPdp?: (slug: string, searchParams?: string) => void
  setLink?: boolean
}

function ProductsToBuy({
  products,
  isBuy,
  buttonBack,
  redirectToPdp,
  setLink,
}: IProductsToBuy) {
  const baseClass = `modalComparedProduct-details-listProduct`
  const { isMobile, widthInner } = useWindowDimensions()
  const quantityProducts = products?.length
  const minMaxGrid = isMobile ? 'minmax(100px, 1fr)' : 'minmax(200px, 200px)'
  const columnGap = isMobile ? '21px' : '48px'

  return (
    <div className={baseClass}>
      {buttonBack && !isMobile && (
        <div className={`${baseClass}-back`}>
          <button
            id="modal-compared-product-back-button"
            className={`${baseClass}-back__button`}
            onClick={buttonBack}
          >
            Voltar
          </button>
        </div>
      )}
      <ul
        style={
          widthInner < 768 || widthInner > 1024
            ? {
                gridTemplateColumns:
                  quantityProducts <= 3
                    ? `repeat(${quantityProducts}, ${minMaxGrid})`
                    : '',
                columnGap: quantityProducts <= 3 ? columnGap : '',
              }
            : {}
        }
        className={`${baseClass}-listProducts ${isBuy ? 'isBuy' : ''}`}
      >
        {products?.map((product, index) =>
          setLink ? (
            <div
              id="redirect-buy-button-link"
              className={`${baseClass}__link`}
              onClick={() => {
                redirectToPdp?.(product?.slug, 'comparedProduct')
              }}
              aria-hidden="true"
              key={product?.id}
            >
              <ListOfProductsToBuy
                product={product}
                baseClass={baseClass}
                redirectToPdp={redirectToPdp}
                isBuy={isBuy}
                index={index}
              />
            </div>
          ) : (
            <ListOfProductsToBuy
              key={product?.id}
              product={product}
              baseClass={baseClass}
              redirectToPdp={redirectToPdp}
              isBuy={isBuy}
              index={index}
            />
          )
        )}
      </ul>
    </div>
  )
}

export default ProductsToBuy
