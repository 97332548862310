import React, { useState, useEffect } from 'react'
import { canBeDeliveredBeforeTheHoliday } from 'src/utils/shipment'

type ShippingInfoBeforeDateProps = {
  holidayDate: string // yyyy-mm-dd
  slas: SLAMinimumType[]
}

export const ShippingInfoBeforeDate = ({
  holidayDate,
  slas,
}: ShippingInfoBeforeDateProps) => {
  const [shouldMessageBeRend, setShouldMessageBeRend] = useState(false)

  useEffect(() => {
    if (!slas) {
      return
    }

    anyDeliveryMethodsBeforeTheHoliday(
      slas,
      holidayDate,
      setShouldMessageBeRend
    )
  }, [slas, holidayDate])

  return (
    <>
      {shouldMessageBeRend && (
        <span
          key="before-holiday"
          className="shipping-simulator__info-before-date"
        >
          Pode ser entregue até {formatDate(holidayDate)}, consulte abaixo:
        </span>
      )}
    </>
  )
}

function anyDeliveryMethodsBeforeTheHoliday(
  slas: SLAMinimumType[],
  holidayDate: string,
  setShouldMessageBeRend: React.Dispatch<React.SetStateAction<boolean>>
): void {
  const foundItem = slas.find((sla) => {
    return canBeDeliveredBeforeTheHoliday(sla, holidayDate)
  })

  setShouldMessageBeRend(!!foundItem)
}

function formatDate(date: string): string {
  const [, month, day] = date.split('-')

  return `${day}/${month}`
}
