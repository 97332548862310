import React, { useState } from 'react'
import stepOneNecklaceImg from 'src/images/measure-guide/stepOneNecklaceImg.jpg'
import stepTwoNecklaceImg from 'src/images/measure-guide/stepTwoNecklaceImg.jpg'
import useWindowDimensions from 'src/hooks/useWindowDimensions'
import { Skeleton } from '@acctglobal/skeleton'

import './necklace.scss'

const Necklace = () => {
  const { width } = useWindowDimensions()
  const [loadImage, setLoadImage] = useState(false)

  return (
    <div className="measureYourFingerNecklace">
      {width <= 1024 ? (
        <div className="measureYourFingerNecklace__content">
          <p className="measureYourFingerNecklace__textInfo">
            Confira abaixo os exemplos de caimentos dos diferentes comprimentos
            de colares e correntes.
          </p>
          {!loadImage && (
            <Skeleton width={312} height={313} backgroundColor="#F4F4F4" />
          )}
          <img src={stepOneNecklaceImg} alt="step One Necklace Img" />

          <p className="measureYourFingerNecklace__textInfo">
            Para modelos tipo &ldquo;gravata&rdquo;, o comprimento considerado
            vai até a ponta da joia.
          </p>
          {!loadImage && (
            <Skeleton width={312} height={313} backgroundColor="#F4F4F4" />
          )}
          <img
            src={stepTwoNecklaceImg}
            alt="step Two Necklace Img"
            onLoad={() => setLoadImage(true)}
          />
        </div>
      ) : (
        <div className="measureYourFingerNecklace__content">
          <p className="measureYourFingerNecklace__textInfo">
            Confira abaixo os exemplos de caimentos dos diferentes comprimentos
            de colares e correntes.
          </p>
          <div className="measureYourFingerNecklace__containerImgs">
            {!loadImage && (
              <Skeleton width={664} height={312} backgroundColor="#F4F4F4" />
            )}
            <img src={stepOneNecklaceImg} alt="step One Necklace Img" />
            <img
              src={stepTwoNecklaceImg}
              alt="step Two Necklace Img"
              onLoad={() => setLoadImage(true)}
            />
          </div>
          <p className="measureYourFingerNecklace__textInfo">
            Para modelos tipo &ldquo;gravata&rdquo;, o comprimento considerado
            vai até a ponta da joia.
          </p>
        </div>
      )}
    </div>
  )
}

export default Necklace
